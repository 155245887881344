@import "../../styles/mixins";

.dropdown {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;
  padding-right: 17px;

  &.disabled {
    opacity: .4;
    cursor: default;
  }

  //&.empty {
  //  color: #C0C2C8;
  //}

  &.opened {
    z-index: 100;
    .dropdown_list_wrapper {
      display: block;
    }
  }

  .dropdown_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
    left: 0;
    top: 0;
  }

  .dropdown_opener {
    position: relative;
    display: block;
    width: 100%;
    z-index: 5;
    text-align: left;

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 100%;
      top: 0;
      height: 100%;
      width: 17px;
    }

    svg {
      vertical-align: middle;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    span[data-value=akcziya] {
      color: #C33333;
    }
  }

  .dropdown_arrow {
    @include middle;
    right: 0;
    z-index: 0;
  }

  .clear {
    @include middle;
    right: 0;
    svg {
      width: 10px;
      height: 10px;
    }
    z-index: 10;
  }

  .dropdown_list_wrapper {
    @include center;
    z-index: 2;
    top: 100%;
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
    border-radius: 8px;
    color: #040E26;
    display: none;

    .tool_tip {
      @include center;
      bottom: 100%;
    }

    ul {
      overflow: auto;
      max-height: 300px;

      li {
        white-space: nowrap;
        padding: 18px 50px 18px 16px;
        border-top: 1px solid #E6E7EA;

        &.selected {
          display: none;
        }

        &:first-of-type {
          border-top: 0 solid;

          & + :not([style*='display: none']) {
            border-top: 0 solid;
          }
        }

        &[data-value=akcziya] {
          color: #C33333;
        }

        a {
          color: unset;
          text-decoration: none;
        }
      }
    }
  }

  .simple_list_wrapper {
    overflow: auto;
    max-height: 300px;

    a {
      text-decoration: none;
      color: unset;
    }

    a, span {
      display: block;
      white-space: nowrap;
      padding: 18px 50px 18px 16px;
      border-bottom: 1px solid #E6E7EA;

      &:last-child {
        border-bottom: 0 solid;
      }
    }
  }
}