@import "../../styles/mixins";

.header_user_section {
  @include middle;
  right: 30px;
  font-size: 0;

  .auth_links {
    white-space: nowrap;

    a {
      display: inline-block;
      vertical-align: top;
      margin-left: 60px;
      font-size: 13px;
      line-height: 13px;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      color: #040E26;
    }
  }

  .user_authorized {
    position: relative;
    white-space: nowrap;

    .ua_username {
      white-space: normal;
      position: relative;
      display: inline-block;
      vertical-align: top;
      font-size: 10px;
      line-height: 24px;
      text-transform: uppercase;
      padding: 0 20px 0 8px;
      letter-spacing: 0.05em;
      margin-top: 8px;
      cursor: pointer;

      &.opened .ua_dropdown {
        display: block;
      }

      .ua_username_text {
        color: #040E26;

        .header.active & {
          color: #040E26 !important;
        }
      }

      .ua_userpic {
        @include middle;
        right: 100%;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #F2F0F0;

        .avatar {
          width: 100%;
          height: 100%;
          @include center-middle;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
          }
        }

        span {
          @include center-middle;
          font-size: 14px;
          line-height: 14px;
          text-transform: uppercase;
          font-weight: bold;
          margin-top: 1px;
          color: #040E26;
          letter-spacing: 0;
        }
      }

      .dropdown_arrow {
        @include middle;
        right: 0;
        margin-top: -1px;

        path {
          fill: #040E26;

          .header.active & {
            fill: #040E26 !important;
          }
        }
      }

      .ua_dropdown {
        @include center;
        z-index: 2;
        top: 100%;
        margin-top: 18px;
        background-color: #fff;
        box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
        border-radius: 8px;
        min-width: 150px;
        display: none;

        .tool_tip {
          @include center;
          bottom: 100%;
        }

        a {
          display: block;
          text-decoration: none;
          white-space: nowrap;
          font-size: 16px;
          line-height: 20px;
          text-transform: none;
          padding: 18px 16px;
          border-bottom: 1px solid #E6E7EA;
          color: #040E26;

          &.ua_logout,
          &.red {
            text-align: center;
            color: #C43333;
          }

          &:last-of-type {
            border-bottom: 0 solid;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    display: none;
  }
}