@import "../../styles/mixins";

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: 0;
  text-align: left;
  padding-left: 22px;

  &.disabled {
    opacity: .4;

    label, input {
      cursor: default;
    }
  }

  label {
    cursor: pointer;
    display: block;
  }

  &.checked {
    ins {
      background-color: #040E26;

      svg {
        display: block;
      }
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 0;
    height: 0;
  }

  ins {
    position: absolute;
    left: 0;
    top: 6px;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    border: 1px solid #040E26;
    box-sizing: border-box;
    border-radius: 2px;

    svg {
      @include center-middle;
      display: none;
    }
  }

  span {
    vertical-align: middle;
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    white-space: normal;

    a {
      color: #040E26;
    }
  }

}