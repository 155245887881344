@import "../styles/mixins";

.subscription_plans_wrapper {
  padding-top: 128px;
  text-align: center;

  .spw_inner {
    font-size: 0;
    white-space: nowrap;
    overflow: auto;
    padding-top: 40px;
  }

  .sp_item {
    display: inline-block;
    vertical-align: top;
    width: 320px;
    margin: 0 8px;
    white-space: normal;
    text-align: left;

    .sp_item_inner {
      padding: 46px 23px 46px 46px;
    }

    .spi_price {
      color: #040E26;
      margin-bottom: 16px;

      .spi_price_old,
      .spi_price_value {
        font-weight: 200;
        position: relative;
        display: inline-block;
      }

      .spi_price_old {
        font-size: 48px;
        line-height: 48px;

        &.spi_empty {
          opacity: 0;
        }

        &:after {
          content: '';
          @include center-middle;
          width: 120%;
          height: 2px;
          background-color: #C33333;
        }
      }

      .spi_price_value {
        font-size: 72px;
        line-height: 72px;

        .spi_price_term {
          position: absolute;
          top: 0;
          left: 100%;
          margin-left: 8px;
          font-size: 16px;
          line-height: 24px;
          opacity: 0.5;
        }
      }
    }

    .spi_title {
      color: #040E26;
      width: 100%;
      padding-right: 23px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #000000;
      font-size: 24px;
      line-height: 32px;

    }

    .spi_list {
      li {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
        color: #434A5C;
        position: relative;

        svg {
          position: absolute;
          left: -32px;
          top: 3px;
        }
      }
    }
  }

  .price_disclamer {
    max-width: 640px;
    padding-top: 48px;
    margin: 0 auto 48px;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
  }
}