@import "../../styles/mixins";

.blog_post_wrapper {
  position: relative;

  .blog_post_pattern {
    height: 160px;
  }

  .blog_post_inner {
    padding-top: 16px;
    text-align: left;
    max-width: 1170px;
    margin: 0 auto;

    .post_edit_button {
      position: absolute;
      top: 180px;
    }

    h1 {
      font-size: 30px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 25px;
      text-align: center;
    }

    .post_image {
      text-align: center;
      margin-bottom: 24px;
      padding-top: 80px;

      img {
        margin: 0 auto;
        max-width: 600px;
      }
    }

    .blog_post_inner {

      h2 {
        font-size: 23px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 25px;
      }

      h3 {
        font-size: 20px;
        line-height: 27px;
        font-weight: 500;
        margin-bottom: 25px;
      }

      h1, h2, h3 {

        span {
          font-size: unset !important;
          color: unset !important;
          font-family: unset !important;
          background-color: unset !important;
        }

      }

      p, ul, ol, blockquote {
        margin-bottom: 25px;
        font-size: 17px;
        line-height: 25px;

        span {
          font-size: unset !important;
          color: unset !important;
          font-family: unset !important;
          background-color: unset !important;
        }
      }

      blockquote {
        padding: 15px 45px;
        font-style: italic;
        background-color: #f5f5f5;
      }

      p {
        & + p {
          margin-top: -18px;
        }
      }

      ul, ol {

        li {
          position: relative;
          padding-left: 30px;
          margin-bottom: 25px;

          &:last-of-type {
            margin-bottom: 0;
          }

          &:before {
            position: absolute;
            left: 5px;
          }
        }
      }

      ul {
        li {
          &:before {
            content: '';
            top: 10px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #2450FF;
          }
        }
      }

      ol {
        counter-reset: line-number;
        li {
          counter-increment: line-number;

          &:before {
            content: counter(line-number)".";
          }
        }
      }
    }
  }
}