@import "../../../../../styles/mixins";

.cl_header {
  .header_bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;

    .cover_wrapper {
      background-color: #000;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        @include center-middle;
        width: 100%;
        opacity: .6;
      }
    }

    @include adaptive($adaptive_course_mid) {
      height: 630px;
      top: 0;

      .cover_wrapper {
        img {
          height: 100%;
          width: auto;
        }
      }
    }

    @include adaptive($adaptive_course_mid) {
      height: 500px;
      top: 0;
    }
  }
}