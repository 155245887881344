@import "../../../../styles/mixins";

.cl_perks {
  background-color: #F5F5F5;
  padding: 68px 0 72px;

  h2 {
    margin-bottom: 52px;
  }

  .perks_carousel {
    text-align: center;
    position: relative;

    .feed_holder {
      overflow: hidden;
      margin-bottom: 35px;
      text-align: left;

      .feed {
        display: flex;
        flex-wrap: nowrap;

        @include transition(margin-left linear 400ms);

        .feed_item {
          width: calc(570px - 120px);
          flex-shrink: 0;
          margin-right: 30px;
          padding: 52px 60px 68px;
          background-color: #fff;
          border-radius: 10px;

          dt {
            font-size: 22px;
            line-height: 30px;
            font-weight: 500;
            margin-bottom: 32px;
          }

          dd {
            font-size: 17px;
            line-height: 25px;
          }
        }
      }
    }

    .arr {
      @include middle;
      width: 10px;
      height: 20px;
      overflow: hidden;
      cursor: pointer;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &:before {
        content: '';
        position: absolute;
        top: -6px;
        width: 30px;
        height: 30px;
        border: 1px solid #040E26;
        @include rotate(45deg);
      }

      &.arr_left {
        right: 100%;
        margin-right: 30px;

        &:before {
          left: 7px;
        }
      }

      &.arr_right {
        left: 100%;
        margin-left: 30px;

        &:before {
          right: 7px;
        }
      }
    }

    .bullits {
      display: inline-block;
      vertical-align: top;
      white-space: nowrap;

      li {
        display: inline-block;
        vertical-align: top;
        margin: 0 5px;
        width: 5px;
        height: 5px;
        border-radius: 3px;
        opacity: .5;
        background-color: #040E26;
        cursor: pointer;
        @include transition(linear 150ms);

        &:hover {
          opacity: 1;
        }

        &.active {
          width: 20px;
          opacity: 1;
          background-color: #2450FF;
        }
      }
    }
  }

  @include adaptive($adaptive_course_mid) {
    .perks_carousel {
      .feed_holder {
        .feed {
          .feed_item {
            width: calc(50vw - 50px - 100px - 15px);
            padding: 52px 50px 62px;
          }
        }
      }

      .arr {
        &.arr_left {
          margin-right: 23px;
        }

        &.arr_right {
          margin-left: 23px;
        }
      }
    }
  }

  @include adaptive(900px) {
    .perks_carousel {
      .feed_holder {
        .feed {
          .feed_item {
            width: calc(100vw - 200px);
          }
        }
      }

      .arr {
        display: none;
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0 50px;
    .cl_centrer {
      margin: 0;


      h2 {
        margin: 0 20px 35px;
      }
    }

    .perks_carousel {
      .feed_holder {
        .feed {
          @include transition(margin-left linear 250ms);

          .feed_item {
            width: calc(100vw - 40px - 40px - 20px - 20px);
            padding: 36px 20px 37px;
            margin-right: 20px;

            &:first-of-type {
              margin-left: 20px;
            }

            dt {
              font-size: 17px;
              line-height: 25px;
              margin-bottom: 25px;
            }

            dd {
              font-size: 15px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}