@import "../styles/mixins";

.subscription_plan_wrapper {
  text-align: center;

  h1 {
    margin-bottom: 32px;
  }

  .sp_discr {
    width: 720px;
    margin: 0 auto 32px;
    text-align: left;

    font-size: 14px;
    line-height: 21px;

    a {
      text-decoration: none;
      color: #040E26;
      border-bottom: 1px solid rgba(38, 4, 4, .5);
    }

    p {
      margin-bottom: 21px;

      & + ul {
        margin-top: -21px;
      }
    }

    ul, li {
      list-style-type: circle;
    }

    ul {
      margin-left: 14px;
    }
  }

  .btn_unsubscribe {
    margin-bottom: 48px;
  }

  .sp_list_wrapper {
    background-color: #F2F0F0;
    padding-top: 32px;
    padding-bottom: 48px;
    margin-bottom: 48px;

    h2 {
      margin-bottom: 32px;
    }

    .plans_list {
      display: flex;
      justify-content: center;
      align-items: stretch;
      white-space: normal;
      margin-bottom: 32px;

      .radio_wrapper {
        flex-shrink: 0;
        width: 330px;
        margin: 0 16px;

        &.simple {
          width: auto;

          label {
            padding-left: 60px;

            .point {
              left: 28px;
            }
          }

          input:checked + label {
            background-color: #fff;
          }

          &:hover {
            label {
              background-color: #fff;
            }
          }
        }
      }
    }

    .radio_wrapper {
      position: relative;

      label {
        position: relative;
        display: block;
        padding: 48px 30px 40px 80px;
        text-align: left;
        cursor: pointer;
        height: calc(100% - 98px);

        &:hover {
          background-color: #F2F0F0;

          box-shadow: inset 0 0 0 1px #040E26;

          p {
            color: #434A5C;
          }
        }

        .point {
          position: absolute;
          left: 48px;
          top: 58px;
          background-color: #fff;
          border: 1px solid #C0C2C8;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }

        h4 {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 24px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px;
          color: #C0C2C8;
        }

        .plan_price {
          b {
            display: inline-block;

            &.regular_price {
              margin-right: 8px;
              text-decoration: line-through;
            }

            &.sale_price {
              font-size: 24px;
              color: #C43333;

            }
          }
        }
      }

      input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        width: 0;
        height: 0;
        left: -100px;
        top: -100px;

        &:checked + label {
          background-color: #F2F0F0;
          box-shadow: inset 0 0 0 1px #040E26;

          .point {
            &:before {
              content: '';
              @include center-middle;
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background-color: #040E26;
            }
          }

          p {
            color: #434A5C;
          }
        }
      }

    }

    .button_holder {
      text-align: center;
      margin-bottom: 48px;

      .uncompleted_note {
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 19px;
        color: #C33333;

        a {
          color: #C33333;
          text-decoration: none;
          border-bottom: 1px solid #C33333;
        }
      }
    }

    .sp_discr {
      margin-bottom: 0;
    }
  }


}