@import "../../styles/mixins";

.lesson_content {
  .player_wrapper {

    .player_inner {
      width: 800px;
      height: 460px;
      margin: 0 auto;
      position: relative;



      iframe {
        position: relative;
        margin: 0 auto;
        display: block;
      }
    }

    @media screen and (max-width: $treshold_mid) {
      .player_inner {
        width: auto;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    @media screen and (max-width: $treshold) {
      width: 100vw;
      margin-left: -20px;
    }
  }
}