@import "../styles/mixins";

.auth_tabs {
  font-size: 0;
  white-space: nowrap;
  margin-bottom: 47px;

  a, span {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 24px;
    margin: 0 47px;
    text-decoration: none;
    color: #C0C2C8;
    cursor: pointer;

    &:hover {
      color: #434A5C;
    }

    &.active {
      color: #434A5C;
      border-bottom: 1px solid #434A5C;
      cursor: default;
    }
  }

  @media screen and (max-width: $treshold_mid) {
    background-color: #fff;
    border-top: 2px solid #F2F3F4;
    padding-top: 30px;

    a, span {
      width: 50%;
      text-align: center;
      margin: 0;
      padding-bottom: 7px;

      &.active {
        border-bottom: 2px solid #C0C2C8;
      }
    }

  }
}