.school_courses_reviews {
  padding-bottom: 80px;
  .reviews_list {
    margin: 0 36px;
    max-width: 700px;

    li {
      background-color: #fff;
      padding: 18px;
      border-radius: 4px;
      margin-bottom: 24px;

      dl {
        font-size: 14px;
        line-height: 19px;
        display: flex;
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }

        dt {
          width: 100px;
          flex-shrink: 0;
          text-align: right;
          margin-right: 16px;
          font-weight: bold;
        }
      }
    }
  }
}