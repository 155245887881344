@import "../styles/mixins";
$t_min: 415px;
$t_700: 700px;
$t_mid: 1050px;


.header_background_wrapper {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .hb_stroke {
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 440px;
    background-color: #0064FF;
  }

  .flake {
    z-index: 1;
    position: absolute;

    &.gradient_flake {
      width: 157px;
      height: 96px;
      top: 0;
      left: 3%;
      @include retina-background-image('./images/gradient_flake', 'png', 157px, 96px);
    }

    &.flake_3 {
      top: 40px;
      left: 22%;
    }
    &.flake_4 {
      top: 0;
      left: 46%;
    }
    &.flake_5 {
      top: 0;
      left: 60%;
    }
    &.flake_6 {
      top: 24px;
      right: 48px;
    }
    &.flake_7 {
      top: 100%;
      left: 0;
    }
    &.flake_8 {
      top: 100%;
      left: 40%;
    }
    &.flake_9 {
      top: 100%;
      left: 70%;
    }
  }
  @include adaptive($t_mid) {
    .flake {
      &.flake_3 {
        left: 40%;
      }
      &.flake_4 {
        left: 65%;
      }
      &.flake_5,
      &.flake_6 {
        display: none;
      }

      &.flake_8 {
        left: 50%;
      }

      &.flake_9 {
        left: 80%;
      }
    }

  }
  @include adaptive($t_700) {
    .hb_stroke {
      height: calc(100% - 72px);
    }
    .flake {
      &.gradient_flake,
      &.flake_3,
      &.flake_4,
      &.flake_7,
      &.flake_9
      {
        display: none;
      }

      &.flake_5 {
        display: block;
        width: 91px;
        height: auto;
        left: 8px;
      }

      &.flake_6 {
        display: block;
        width: 60px;
        height: auto;
        top: 8px;
        right: 8px;
      }
      &.flake_8 {
        width: 85px;
        height: auto;
        left: auto;
        right: 64px;
        margin-top: -1px;
      }
    }
  }
}