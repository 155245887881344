@import "../styles/mixins";

.profile_wrapper .public_profile {

  .profile_bg {
    height: 320px;
  }

  .profile_userpic {
    position: relative;
    z-index: 5;
    margin: -96px auto 0;
    width: 186px;
    height: 186px;
    border-radius: 50%;
    border: 3px solid #434A5C;
    background-color: #fff;
    overflow: hidden;

    span {
      @include center-middle;
      font-weight: bold;
      text-transform: uppercase;
      color: #040E26;
      font-size: 96px;
      line-height: 96px;
    }

    img {
      @include center-middle;
      width: 100%;
    }
  }

  .profile_name {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    color: #040E26;
    padding-top: 24px;
    margin-bottom: 24px;
  }

  .profile_role {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #040E26;
    margin-bottom: 24px;
  }

  .profile_about_wrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 976px;
    margin: 0 auto;

    .profile_about {
      width: 100%;
      margin: 16px 0;
      padding: 32px;
      background-color: #F2F0F0;
      border-radius: 16px;
    }

    .public_contacts {
      margin: 16px 0 16px 32px;
      width: 220px;
      flex-shrink: 0;
    }

    @media screen and (max-width: $treshold) {
      display: block;
      margin: 0 20px;
      width: auto;

      .profile_about {
        width: auto;
      }
    }
  }
}