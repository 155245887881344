@import "../../../../styles/mixins";

.ls_statistics_wrapper {
  padding: 94px 0 104px;
  @include gradient(#F8F8FA, #fff);

  h2 {
    margin-bottom: 80px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 30%;
      margin-right: 3%;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 25px;

      .count {
        position: relative;
        padding-left: 71px;
        margin-bottom: 26px;
        font-size: 45px;
        line-height: 45px;
        font-weight: 600;
        color: #D2835D;

        .icon {
          @include middle;
          left: 0;
          width: 55px;

          svg {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding: 32px 0 48px;

    h2 {
      margin-bottom: 28px;
    }

    ul {
      display: block;

      li {
        display: block;
        width: auto;
        margin-right: 0;
        margin-bottom: 32px;

        .count {
          margin-bottom: 16px;
        }
      }
    }
  }
}