@import "../../../styles/mixins";

.ls_easy_to_use_wrapper {
  padding-top: 100px;
  padding-bottom: 110px;

  &.no_header {
    padding-top: 20px;
    padding-bottom: 20px;

    .l_content {
      h2 {
        display: none;
      }

      .items {
        display: none;
      }
    }
  }

  .l_content {
    h2 {
      margin-bottom: 92px;
    }

    .storage {

      h3 {
        font-size: 24px;
        line-height: 35px;
        font-weight: 500;
        margin-bottom: 55px;
      }

      .plate_section {
        margin-bottom: 48px;

        h4 {
          font-size: 24px;
          line-height: 35px;
          font-weight: 600;
          margin-bottom: 16px;
        }
      }

      .plates {
        & + p {
          margin-top: 16px;
        }

        &.plates_video {
          li {
            &:nth-of-type(1) { background-color: #FFF3FE; }
            &:nth-of-type(2) { background-color: #F2F6FC; }
            &:nth-of-type(3) { background-color: #F5F1FC; }
            &:nth-of-type(4) { background-color: #F8FCF1; }
            &:nth-of-type(5) { background-color: #FDF9F3; }
            &:nth-of-type(6) { background-color: #F9F9F9; margin-right: 0; }
          }
        }

        &.plates_pdf {
          li {
            &:nth-of-type(1) { background-color: #F2F2F2; }
            &:nth-of-type(2) { background-color: #FDF9F3; }
            &:nth-of-type(3) { background-color: #E6EBFF; }
            &:nth-of-type(4) { background-color: #FFFCF2; }
            &:nth-of-type(5) { background-color: #CFFFE8; margin-right: 0; }
          }
        }

        &.plates_webinar {
          li {
            &:nth-of-type(1) { background-color: #FFF3FE; }
            &:nth-of-type(2) { background-color: #D6E9FF; }
            &:nth-of-type(3) { background-color: #FFE4EC; margin-right: 0; }
          }
        }


        li {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 170px;
          height: 220px;
          margin-right: 30px;
          margin-bottom: 30px;
          border-radius: 20px;
          text-align: center;



          .img_wrapper {
            height: 165px;
            position: relative;

            img {
              @include center-middle;

              &.img_g_service {
                height: 70px;
                width: auto;
              }

              &.img_zoom {
                width: 70px;
                height: auto;
              }

              &.img_webinarru {
                width: 90px;
                height: auto;
              }
            }
          }
        }
      }

      p {
        font-size: 14px;
        line-height: 15px;
        color: #444750;
      }
    }

    .items {
      white-space: nowrap;

      li {
        display: inline-block;
        vertical-align: top;
        margin-right: 140px;

        &:last-of-type {
          margin-right: 0;
        }

        h4 {
          font-size: 45px;
          line-height: 35px;
          font-weight: 600;
          margin-bottom: 26px;
        }

        p {
          white-space: normal;
          width: 300px;
          font-size: 18px;
          line-height: 25px;
          color: #444750;
        }
      }
    }
  }

  @media screen and (max-width: $treshold_max) {
    .l_content {
      .items {
        white-space: normal;

        li {
          margin-right: 0;
          display: block;
          margin-bottom: 60px;

          &:last-of-type {
            margin-bottom: 0;
          }

          p {
            width: auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    .l_content {

      .storage {
        .plates {
          white-space: nowrap;
          padding: 0 50px 20px;
          width: 100%;
          margin-left: -50px;
          overflow: auto;

        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding-top: 51px;
    padding-bottom: 47px;

    .l_content {
      h2 {
        margin-bottom: 30px;
      }

      .storage {
        margin-bottom: 44px;

        h3 {
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 37px;
        }

        .plates {
          padding: 0 25px 20px;
          margin-bottom: 10px;
          margin-left: -25px;
        }
      }

      .items {
        li {
          margin-bottom: 43px;

          h4 {
            font-size: 30px;
            line-height: 35px;
            margin-bottom: 13px;
          }

          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}