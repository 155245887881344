@import "../../styles/mixins";


.default_form {
  .sz_status_check {
    color: #C33333;

    &.checked {
      color: #46C790;
    }

    .checked_note {
      padding-top: 13px;
      font-size: 13px;
      line-height: 19px;
      color: #818692;
    }
  }

  .form_block {
    position: relative;
    width: 864px;
    margin: 0 auto;
    margin-bottom: 58px;

    .form_block_label {
      color: #040e26;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
  .input_block {
    font-size: 0;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-top: 18px;
    margin-bottom: 54px;

    &.ib_864 {
      width: 864px;
      margin-right: 0;
    }
    &.ib_832 {
      width: 832px;
      margin-right: 0;
    }
    &.ib_528 {
      width: 528px;
      margin-right: 32px;
    }
    &.ib_256 {
      width: 256px;
      margin-right: 32px;
    }
    &.ib_192 {
      width: 192px;
      margin-left: 32px;
      margin-right: 32px;
    }
    &.ib_304 {
      width: 304px;
    }

    input {
      width: 100%;
      position: relative;
      z-index: 2;
      background-color: transparent;
      border: 0 solid;
      border-bottom: 1px solid #C0C2C8;
      display: block;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 8px;

      &:disabled {
        border-bottom-color: transparent;
        color: #434A5C;
        opacity: 1;
      }
    }

    label {
      color: #C0C2C8;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 10px;
      line-height: 24px;

      &.up {
        top: 0;
        font-size: 10px;
      }
    }

    .form_error {
      display: block;
      font-size: 12px;
      line-height: 12px;
      color: #C33333;
      padding-top: 12px;
    }

    .dropdown {
      display: inline-block;

      &.disabled {
        opacity: 1;
        .dropdown_arrow {
          display: none;
        }
      }
    }

    @supports (not (-ms-ime-align:auto)) {
      label {
        color: #C0C2C8;
        top: 18px;
        transition: all 0.2s ease-out;
        font-size: 16px;

        &.up {
          top: 0;
          font-size: 10px;
        }
      }

      input:focus + label,
      input:disabled + label,
      input:not(:placeholder-shown) + label {
        top: 0;
        font-size: 10px;
      }
    }
  }

  .button_holder {
    text-align: center;
    padding-bottom: 64px;
  }
}
