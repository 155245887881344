@import "../../../../styles/mixins";

.cl_roadmap {
  padding: 68px 0 72px;

  .cl_centrer h2 {
    margin-bottom: 117px;
  }

  .roadmap_wrapper {
    border-top: 1px solid #F5F5F5;

    .cl_centrer {
      margin-top: -25px;
    }

    .roadmap_carousel {
      position: relative;

      .feed_holder {
        overflow: hidden;
        text-align: left;

        .feed {
          display: flex;
          flex-wrap: nowrap;

          @include transition(margin-left ease-out 300ms);

          .feed_item {
            width: 240px;
            flex-shrink: 0;
            margin-right: 60px;

            dt {
              font-size: 22px;
              line-height: 30px;
              margin-bottom: 22px;
              font-weight: 500;

              .idx {
                position: relative;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-bottom: 32px;
                background-color: #040E26;

                span {
                  @include center-middle;
                  color: #fff;
                }
              }
            }

            dd {
              font-size: 17px;
              line-height: 25px;

            }
          }
        }
      }

      .arr {
        @include middle;
        width: 10px;
        height: 20px;
        overflow: hidden;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &:before {
          content: '';
          position: absolute;
          top: -6px;
          width: 30px;
          height: 30px;
          border: 1px solid #040E26;
          @include rotate(45deg);
        }

        &.arr_left {
          right: 100%;
          margin-right: 30px;

          &:before {
            left: 7px;
          }
        }

        &.arr_right {
          left: 100%;
          margin-left: 30px;

          &:before {
            right: 7px;
          }
        }
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0 50px;

    .cl_centrer {
      margin: 0;

      h2 {
        margin: 0 20px 65px;
      }
    }

    .roadmap_wrapper {
      .roadmap_carousel {
        .feed_holder {
          .feed {
            .feed_item {
              width: 220px;
              margin-right: 50px;

              &:first-of-type {
                margin-left: 20px;
              }

              dt {
                font-size: 17px;
                line-height: 25px;
                margin-bottom: 25px;

                .idx {
                  margin-bottom: 36px;
                }
              }

              dd {
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }

        .arr {
          display: none;
        }
      }
    }
  }
}