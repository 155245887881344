@import "../styles/mixins";

.modal_auth_wrapper {
    max-height: 550px;
    overflow: auto;

  .payment_logos {
    font-size: 0;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    img {
      height: 20px;
    }
  }

  button {
    width: 100%;
  }

  .modal_auth_form_toggler {
    text-align: center;
    padding: 16px 0;

    span {
      display: inline-block;
      vertical-align: top;
      margin: 0 24px;
      cursor: pointer;
      color: #C0C2C8;

      &:hover {
        color: #434A5C;
      }

      &.active {
        color: #434A5C;
        border-bottom: 1px solid #434A5C;
        cursor: default;
      }
    }
  }

  .sign_up_form, .sign_in_form {
    padding: 16px;
    max-width: 300px;

    .text_input {
      margin-bottom: 24px;

      padding-left: 0;
      padding-right: 0;
    }

    .checkbox {
      margin-bottom: 24px;

      span {
        font-size: 13px;
        line-height: 19px;
      }

      ins {
        top: 3px;
      }
    }
  }
}

.sign_up_wrapper {
  position: relative;
  width: 336px;
  margin: 0 auto;
  padding-bottom: 100px;
  text-align: center;
  padding-top: 90px;

  @media screen and (max-width: $treshold_mid) {
    width: auto;
  }

  &.suw_confirm {
    //width: 500px;

    h1 {
      margin-bottom: 32px;
    }
  }

  .sign_up_landing {
    .radio_block {
      display: flex;
      justify-content: center;
      align-items: stretch;

      .radio_wrapper {
        flex-shrink: 0;
        width: 470px;
        margin: 0 16px 16px;
      }

      .radio_wrapper {
        position: relative;

        label {
          position: relative;
          display: block;
          padding: 48px 48px 40px 80px;
          text-align: left;
          cursor: pointer;
          height: calc(100% - 98px);

          &:hover {
            background-color: #F2F0F0;

            p, ul li {
              color: #434A5C;
            }

            ul li:before {
              background-color: #434A5C;
            }


            p.reg_join_free svg path {
              stroke: #434A5C
            }
          }

          .point {
            position: absolute;
            left: 48px;
            top: 130px;
            background-color: #fff;
            border: 1px solid #C0C2C8;
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }

          .radio_pic {
            margin-bottom: 24px;
          }

          h4 {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 24px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            color: #C0C2C8;

            &.reg_join_free {
              font-weight: bold;
              position: relative;
              padding-right: 20px;

              ins {
                position: relative;
                display: inline-block;
                vertical-align: top;

                svg {
                  position: absolute;
                  top: 0;
                  left: 0;
                  @include transform(rotate(180deg));
                  width: 24px;
                  height: 24px;

                  path {
                    stroke: #C0C2C8;
                  }
                }
              }
            }

            & + ul {
              margin-top: -24px;
              margin-bottom: 24px;
            }
          }

          ul {
            li {
              position: relative;
              font-size: 16px;
              line-height: 24px;
              padding-left: 16px;
              color: #C0C2C8;
              white-space: normal;

              &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 11px;
                width: 4px;
                height: 4px;
                background-color: #C0C2C8;
                border-radius: 50%;
              }
            }
          }
        }

        input {
          position: absolute;
          z-index: -1;
          opacity: 0;
          width: 0;
          height: 0;
          left: -100px;
          top: -100px;

          &:checked + label {
            background-color: #F2F0F0;

            .point {
              &:before {
                content: '';
                @include center-middle;
                border-radius: 50%;
                width: 6px;
                height: 6px;
                background-color: #040E26;
              }
            }

            p, ul li {
              color: #434A5C;
            }

            ul li:before {
              background-color: #434A5C;
            }

            p.reg_join_free svg path {
              stroke: #434A5C
            }
          }
        }

      }
    }
  }

  .suf_header {
    padding-top: 112px;
    text-align: center;
    margin-bottom: 32px;

    img {
      margin-bottom: 16px;
      display: inline-block;
    }

    h4 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .sign_up_form {
    margin-bottom: 24px;
  }

  .alter_link {
    font-size: 14px;
    line-height: 14px;
    color: #434A5C;
    text-decoration: none;
    border-bottom: 1px solid rgba(60,30,30,.5);
  }

  @media screen and (max-width: $treshold_mid) {
    padding-top: 112px;
    width: auto;
    margin: 0 24px;

    .sign_up_landing {
      .radio_block {
        display: block;

        .radio_wrapper {
          width: auto;
        }
      }
    }

    .auth_tabs {
      display: none;
    }

    .suf_header {
      padding-top: 0;
    }
  }
}