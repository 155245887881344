@import "../../../../styles/mixins";

.cl_params {
  background-color: #F5F5F5;

  .cl_centrer {
    padding: 50px 0;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
  }

  .params_section {
    width: calc(25% - 16px);
    margin-right: 16px;
    flex-shrink: 0;
    h3 {
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      letter-spacing: 0.6px;
      color: #040E26;
      text-transform: uppercase;
      opacity: 0.5;
      margin-bottom: 40px;
    }
  }

  .cl_param_school {
    position: relative;
    padding-left: 75px;

    .userpic {
      @include middle;
      left: 0;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #040E26;

      img, span {
        @include center-middle;
      }

      img {
        width: 100%;
      }

      span {
        font-size: 32px;
        line-height: 32px;
        color: #fff;
        font-weight: 500;
      }
    }

    .title {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: #040E26;
      margin-bottom: 3px;

      a {
        text-decoration: none;
        color: unset;
      }
    }

    .courses_count {
      font-size: 13px;
      line-height: 13px;

      a {
        color: #2450FF;
        text-decoration: none;
        border-bottom: 1px solid #2450FF50;
      }
    }
  }

  .cl_param_authors {
    font-size: 15px;
    line-height: 20px;
    color: #040E26;

    li {
      margin-bottom: 9px;
    }
  }

  .cl_param_duration {
    p {
      font-size: 15px;
      line-height: 20px;
      color: #040E26;
      margin-bottom: 15px;

      &.time {
        position: relative;
        padding-left: 25px;

        svg {
          position: absolute;
          left: 0;
          top: 1px;
        }
      }

      .sorted {
        &:after {
          content: ', '
        }

        &:last-of-type {
          &:after {
            content: '';
          }
        }
      }
    }
  }

  .cl_param_course_for {
    p {
      font-size: 15px;
      line-height: 20px;
      color: #040E26;
    }
  }

  @include adaptive($adaptive_course_mid) {
    .cl_centrer {
      padding-bottom: 0;
    }

    .params_section {
      width: 33%;
      margin-bottom: 55px;

      &:first-of-type {
        width: 100%;
      }

      h3 {
        margin-bottom: 26px;
      }
    }
  }

  @include adaptive(900px) {
    .params_section {
      width: 50%;

      &:first-of-type {
        width: 50%;
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    .cl_centrer {
      padding-bottom: 40px;
    }

    .params_section {
      width: 100%;
      margin-bottom: 37px;

      &:first-of-type {
        width: 100%;
        margin-bottom: 53px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}