@import "../../../styles/mixins";

.ls_header_wrapper {
  height: 60px;
  background-color: #FAFCFF;
  padding-top: 40px;

  .ls_logo {
    @include middle;
    left: 0;
    width: 58px;
    height: 40px;

    img {
      @include middle;
      left: 0;
      width: 100%;
    }

    a {
      color: unset;
      text-decoration: none;
    }

    span {
      @include middle;
      left: 100%;
      font-size: 15px;
      line-height: 22px;
      font-weight: 500;
      margin-left: 20px;
    }
  }

  .ls_auth_links {
    @include middle;
    right: 0;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;

    a {
      display: inline-block;
      margin-right: 15px;
      color: #040E26;
      text-decoration: none;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: $treshold) {
    background-color: #fff;
    height: 45px;

    .ls_logo {
      span {
        display: none;
      }
    }
  }
}