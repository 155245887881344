@import "../styles/mixins";

.course_related {
  margin: 0 64px 64px;
  position: relative;

  h5 {

    font-size: 16px;
    line-height: 24px;
    color: #434A5C;
    opacity: 0.5;
    margin-bottom: 24px;
  }

  @media screen and (max-width: $treshold) {
    margin: 0 24px 64px;
  }
}