@import "../../styles/mixins";

.ceb_general {

  .q_hint {
    display: inline-block;
    margin-left: 16px;
    width: 24px;
    line-height: 24px;
    height: 24px;
    background-color: #434A5C;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    border-radius: 50%;
  }

  .course_description_block {
    margin-bottom: 48px;

    .rdw-editor-toolbar {
      border: 0 solid;
      padding-left: 0;
      margin-left: -4px;
    }

    textarea,
    .demo-editor {
      display: block;
      width: calc(100% - 18px);
      height: 200px;
      padding: 4px 8px;
      font-size: 16px;
      line-height: 24px;
      border: 1px solid #C0C2C8;
      color: #434A5C;

      @include input-placeholder {
        color: #C0C2C8;
      }
    }
  }

  .course_type_selection {
    background-color: #F2F0F0;
    border-radius: 8px;
    padding: 24px 32px;
    margin: 0 auto 48px;
    width: 700px;

    .course_parametres_block {
      &:last-of-type {
        margin-bottom: 0;
      }

      &.cpb_description {
        font-size: 13px;
        opacity: .8;
        margin-top: -24px;
        margin-bottom: 32px;
      }

      &.cpb_date_start {
        margin-bottom: 64px;
      }
    }

    h5 {
      color: #040E26;
    }

    .text_input {
      background-color: #F2F0F0;
    }
  }

  .course_parametres_block {
    position: relative;
    z-index: 10;
    margin: 0 0 48px;
    display: flex;
    justify-content: space-between;

    &.cpb_first_line {
      z-index: 11;
    }

    &.hidden {
      display: none;
    }

    &.focused {
      z-index: 20;
    }

    .cpb_item {
      width: 304px;
      flex-shrink: 0;

      &.free_course_toggler {
        padding-top: 20px;
      }

      .dropdown {
        padding-bottom: 1px;
        border-bottom: 1px solid #C0C2C8;
      }
    }
  }

  .course_covers_block {

    .ce_upload_wrapper {
      margin-bottom: 64px;
    }

    .ce_upload {
      background-color: #F2F0F0;
      position: relative;
      overflow: hidden;

      &.loaded {
        background-color: #040E26;

        &:after {
          content: 'загрузить другую';
          opacity: 0;
          color: #fff;
          @include transition(opacity ease-out 200ms);
        }

        img {
          @include transition(opacity ease-out 200ms);
        }

        &:hover {
          &:after {
            opacity: 1;
          }
          img {
            opacity: .3;
          }
        }
      }

      &:after {
        content: 'загрузить';
        @include center-middle;
        z-index: 10;
        font-size: 16px;
        line-height: 24px;
        color: #040E26;
      }

      &.ce_upload_preview {
        height: 148px;
      }

      &.ce_upload_vertical {
        height: 365px;
      }

      &.ce_upload_cover {
        height: 365px;
      }

      img {
        @include center-middle;
        width: 100%;
        z-index: 5;
      }

      label {
        @include center-middle;
        width: 100%;
        height: 100%;
        z-index: 20;

        &.cc_preview {
          height: 148px;
        }

        &.cc_cover {
          height: 365px;
        }
      }
    }
  }
}