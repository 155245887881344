.footer-socials {
  white-space: nowrap;
  font-size: 0;
  padding-top: 8px;
  margin-bottom: 8px;

  a {
    display: inline-block;
    vertical-align: top !important;
    margin-right: 8px;
  }
}