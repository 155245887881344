@import "../../styles/mixins";

$chat_width: 360px;
$player_width: calc(100% - 360px);

.webinar_live_wrapper {
  padding-top: 120px;
  height: calc(100% - 120px);

  .wl_top {
    position: relative;
    overflow: hidden;
    z-index: 10;
    text-align: center;
    color: #434A5C;
    margin-bottom: 24px;

    .bg {
      height: 100%;
      position: absolute;
      z-index: 0;
      width: 100%;
      left: 0;
      top: 0;

      img {
        width: 100%;
        opacity: 0;
      }
    }

    h6 {
      position: relative;
      z-index: 1;
      font-size: 10px;
      line-height: 24px;
      opacity: .75;
      white-space: nowrap;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      display: inline-block;
      padding-right: 15px;

      a {
        color: #434A5C;
        text-decoration: none;
        border-bottom: 1px solid rgba(60,30,30,.5);
      }

      svg {
        @include middle;
        left: 100%;
        margin-top: -2px;
      }
    }

    h1 {
      position: relative;
      z-index: 1;
      font-size: 24px;
      line-height: 32px;
      margin: 0 200px;

      @media screen and (max-width: $treshold) {
        margin: 0;
      }
    }

  }

  .live_player_wrapper {
    display: flex;
    align-items: stretch;
    border-top: 1px solid #040E26;
    border-bottom: 1px solid #040E26;
    height: calc(100% - 32px - 24px);

    .live_chat_wrapper {
      width: $chat_width;
      background-color: #fff;
      flex-shrink: 0;
    }

    .player_inner {
      width: $player_width;
      flex-shrink: 0;
      position: relative;
    }
  }

  &.lecturer {
    .live_player_wrapper {
      .player_inner {
        .player_v2_wrapper {
          display: inline-block;
          vertical-align: top;
          width: 320px;
          height: 240px;

        }
      }
    }
  }
}