@import "../styles/mixins";

.course_featured_item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 334px;
  margin: 0 14px;
  white-space: normal;
  color: #040E26;
  text-align: left;
  margin-bottom: 32px;

  .course_preview_link {
    text-decoration: none;
    color: unset;
  }


  &:hover {
    .img_wrapper {
      .cp_params,
      .cp_fade {
        opacity: 1;
      }
    }
  }

  .img_wrapper {
    position: relative;
    height: 240px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 16px;

    img {
      height: 100%;
      @include center;
      z-index: 1;

      &.vertical,
      &.book_cover {
        height: auto;
        width: 100%;
        @include clear;
        position: absolute;
        top: 0;
      }

      &.bottom_orientated {
        height: auto;
        width: 100%;
        @include clear;
        position: absolute;
        bottom: 0;
      }
      &.middle_orientated {
        width: 100%;
        height: auto;
        @include center-middle;
      }
    }

    .img_plug {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 0;
    }

    .cp_pict {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 10;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    .cp_pict_text {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 10;
      font-size: 11px;
      line-height: 11px;
      letter-spacing: .55px;
      padding: 4px 10px;
      border-radius: 10px;
      text-transform: uppercase;
      color: #fff;

      &.cp_pict_audio {
        background-color: #7f35be;
      }

      &.cp_pict_video {
        background-color: #2450ff;
      }
    }

    .cp_label {
      position: absolute;
      z-index: 10;
      top: 20px;
      left: 20px;
      background-color: #BA319B;
      color: #fff;
      text-transform: uppercase;
      font-size: 11px;
      line-height: 11px;
      letter-spacing: .55px;
      padding: 4px 10px;
      border-radius: 10px;
    }

    .cp_params {
      position: absolute;
      z-index: 5;
      left: 0;
      bottom: 0;
      padding: 0 20px 20px;
      width: calc(100% - 40px);
      font-size: 12px;
      line-height: 12px;

      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: #fff;
      @include transition(opacity linear 200ms);
      opacity: 0;

      svg path {
        fill: #fff;
      }

      .icon_course_level {
        margin-bottom: -4px;
      }
    }
    .cp_fade {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      @include gradient(rgba(0,0,0,0), rgba(0,0,0,.5));
      @include transition(opacity linear 200ms);
      opacity: 0;
    }
  }

  h4 {
    font-size: 12px;
    line-height: 12px;
    opacity: .7;
    margin-bottom: 12px;
    display: none;
  }

  h3 {
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    margin-bottom: 16px;
    font-weight: 500;
    overflow: hidden;
  }

  .teaser {
    font-size: 14px;
    line-height: 14px;
    height: 15px;
    overflow: hidden;
    margin-bottom: 16px;
  }

  .button_holder {
    .discount_text {
      display: inline-block;
      margin-left: 20px;
      color: #040E26;
      opacity: 0.7;
      font-size: 12px;

      &.old_price {
        font-size: 16px;
        opacity: .5;
        text-decoration: line-through;
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    .img_wrapper {
      border-radius: 0;

      .cp_params,
      .cp_fade {
        opacity: 1;
      }
    }

    h3, h4, p, .button_holder {
      margin-left: 16px;
      margin-right: 16px;
      overflow: visible;
      line-height: 27px;
    }
  }

  @media screen and (max-width: $treshold) {


    h3 {
      height: auto;
    }
  }
}