@import "../../styles/mixins";

.web_general {

  .course_description_block {
    margin-bottom: 48px;

    textarea {
      display: block;
      width: calc(100% - 18px);
      height: 200px;
      padding: 4px 8px;
      font-size: 16px;
      line-height: 24px;
      border: 1px solid #C0C2C8;
      color: #434A5C;

      @include input-placeholder {
        color: #C0C2C8;
      }
    }
  }

  .course_parametres_block {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    .cpb_item {
      width: 304px;
      flex-shrink: 0;

      &.on_top {
        z-index: 111;
      }

      &.free_course_toggler {
        padding-top: 20px;
      }

      .dropdown {
        padding-bottom: 1px;
        border-bottom: 1px solid #C0C2C8;
      }

      .text_input {
        padding-bottom: 1px;
        border-bottom: 1px solid #C0C2C8;
        width: 100%;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }

  .course_covers_block {

    .ce_upload_wrapper {
      margin-bottom: 64px;
    }

    .ce_upload {
      background-color: #F2F0F0;
      position: relative;
      overflow: hidden;

      &.loaded {
        background-color: #040E26;

        &:after {
          content: 'загрузить другую';
          opacity: 0;
          color: #fff;
          @include transition(opacity ease-out 200ms);
        }

        img {
          @include transition(opacity ease-out 200ms);
        }

        &:hover {
          &:after {
            opacity: 1;
          }
          img {
            opacity: .3;
          }
        }
      }

      &:after {
        content: 'загрузить';
        @include center-middle;
        z-index: 10;
        font-size: 16px;
        line-height: 24px;
        color: #040E26;
      }

      &.ce_upload_preview {
        height: 148px;
      }

      &.ce_upload_vertical {
        height: 365px;
      }

      &.ce_upload_cover {
        height: 365px;
      }

      img {
        @include center-middle;
        width: 100%;
        z-index: 5;
      }

      label {
        @include center-middle;
        width: 100%;
        height: 100%;
        z-index: 20;

        &.cc_preview {
          height: 148px;
        }

        &.cc_cover {
          height: 365px;
        }
      }
    }
  }
}