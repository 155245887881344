@import "../../styles/mixins";

.tos_confirmation_screen {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  min-width: 330px;

  .tos_centrer {
    @include center-middle;
    min-width: 300px;
  }

  .tcs_logo_holder {
    text-align: center;
    margin-bottom: 24px;
  }

  p, ul {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;

    a {

      color: #040E26;
      text-decoration: none;
      border-bottom: 1px solid #040E26;
    }
  }

  ul {

    li {
      position: relative;
      padding-left: 16px;
      margin-bottom: 16px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #040E26;
      }
    }
  }

  .tcs_buttons_holder {
    text-align: center;
    padding-top: 16px;

    button {
      margin: 0 8px;
    }
  }
}