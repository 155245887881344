@import "../../../styles/mixins";

.ls_advantages_wrapper {
  margin-top: -156px;
  padding-top: 104px;
  position: relative;

  .wrapper_gradient {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 280px;
    @include gradient(#F8F8FA, #fff);
  }

  h2 {
    margin-bottom: 111px;
  }

  .plates {
    li {
      height: 555px;
      width: 300px;
      margin-right: 135px;
      display: inline-block;
      vertical-align: top;

      &:nth-of-type(3),
      &:nth-of-type(6) {
        margin-right: 0;
      }

      .pic_wrapper {
        width: 300px;
        height: 300px;
        position: relative;
        margin-bottom: 45px;
        border-radius: 20px;

        img {
          @include center-middle;
        }

        .crunch {
          width: 170px;
          height: 170px;
          @include center-middle;

          img {
            @include clear;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }

        &.p0 {
          background-color: #F2F6FC;
        }
        &.p1 {
          background-color: #F9F9F9;
        }
        &.p2 {
          background-color: #FDF9F3;
        }
      }

      p {
        font-size: 18px;
        line-height: 25px;

        span {
          font-weight: 600;
        }

        a {
          font-weight: 600;
          color: #2450FF;
          text-decoration: none;
          border-bottom: 1px solid #2450FF50;
        }
      }
    }
  }

  @media screen and (max-width: $treshold_max) {
    .plates {
      width: 960px;
      li {
        width: 288px;
        margin-right: 30px;

        &:nth-of-type(3),
        &:nth-of-type(6) {
          margin-right: 30px;
        }

        .pic_wrapper {
          width: 288px;
          height: 288px;
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    .plates {
      width: auto;

    }
  }

  @media screen and (max-width: $treshold) {
    overflow: hidden;
    h2 {
      margin-bottom: 35px;
    }

    .plates {
      width: auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: 0;

      li {
        margin-left: 0;
        margin-right: 0 !important;
        margin-bottom: 47px;
        width: 45%;
        height: auto;

        p {
          font-size: 14px;
          line-height: 25px;
        }

        .pic_wrapper {
          width: 145px;
          height: 145px;
          margin-bottom: 19px;

          img {
            width: 78px;
            height: 78px;
          }

          .crunch {
            width: 78px;
            height: 78px;

            img {
              width: 87px;
              height: auto;
            }
          }
        }
      }
    }
  }
}