@import "../../../../styles/mixins";

.lp_form_wrapper {
  background-color: #F2F6FC;
  padding: 150px 0;

  .form_outer {
    background-color: #fff;
    box-shadow: 20px 20px 40px #0016520D;
    border: 1px solid #F2F2F5;
    border-radius: 20px;
    overflow: hidden;
    display: flex;

    &.sent {
      width: 595px;
      height: 745px;
      margin: 0 auto;

      .form_inner {
        display: none;
      }

      .form_part_left {
        width: 100%;

        .form_description {
          display: none;
        }

        .sent_message {
          display: block;
        }
      }
    }

    .form_part_left {
      flex-shrink: 0;
      position: relative;
      width: 50%;
      background-color: #2450FF;
      color: #fff;

      .sent_message {
        display: none;

        @include center-middle;
        z-index: 1;
        width: 345px;
        text-align: center;

        h2 {
          padding-bottom: 35px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        @include gradient(rgba(36, 80, 255, 0), #4114BC);
      }

      .form_description {
        @include center-middle;
        z-index: 1;
        width: 345px;
      }

      h2 {
        font-size: 24px;
        line-height: 35px;
        font-weight: 500;
        padding-bottom: 64px;
        margin-bottom: 35px;
        border-bottom: 1px solid #2478FF;
      }

      h3 {
        font-size: 18px;
        line-height: 25px;
      }
    }

    .form_inner {
      width: 50%;
      padding: 55px 60px 60px 75px;

      form {
        .lp_input_wrapper {
          margin-bottom: 42px;

          &:last-of-type {
            margin-bottom: 47px;
          }

          .note {
            padding-top: 9px;
            font-size: 14px;
            line-height: 20px;
          }
        }

        label {
          display: block;
          background-color: #fff;
          border: 1px solid #E3E3E6;
          border-radius: 10px;
          padding: 10px 20px;
        }

        span {
          font-size: 12px;
          line-height: 25px;
          color: #050307;
        }

        input,
        textarea {
          display: block;
          width: 100%;
          padding: 0;
          background-color: transparent;
          border: 0 solid;
          font-size: 18px;
          line-height: 25px;
        }

        textarea {
          min-height: 80px;
        }

        .lp_checkbox_switcher {
          position: relative;
          padding-left: 50px;

          &.checked {
            .lp_checkbox {
              &:after {
                display: block;
              }
            }
          }

          .lp_checkbox {
            position: absolute;
            left: 0;
            top: 0;
            width: 28px;
            height: 28px;
            border: 1px solid #CACACC;
            border-radius: 10px;

            &:after {
              display: none;
              content: '';
              height: 8px;
              width: 12px;
              position: absolute;
              left: 7px;
              top: 7px;
              border-left: 1px solid #050307;
              border-bottom: 1px solid #050307;
              @include transform(rotate(-45deg));
            }
          }

          .lp_checkbox_label {
            font-size: 14px;
            line-height: 20px;
            color: #050307;

            a {
              text-decoration: none;
              color: #2450FF;
              border-bottom: 1px solid #2450FF50;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    padding: 75px 0;

    .form_outer {
      display: block;

      .form_part_left {
        width: auto;

        .form_description {
          width: auto;
          @include clear;
          position: relative;
          padding: 75px 50px;
        }
      }

      .form_inner {
        width: auto;
        padding: 50px;

        form {
          textarea {
            height: 110px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding: 0;

    .l_centrer {
      margin: 0;
    }

    .form_outer {
      padding: 0;
      width: 100%;
      border-radius: 0;

      .form_part_left {
        .form_description,
        .sent_message {
          padding: 45px 25px 45px;

          h2 {
            font-size: 18px;
            line-height: 25px;
            padding-bottom: 35px;
            margin-bottom: 25px;
          }

          h3 {
            font-size: 14px;
            line-height: 20px;
          }

        }
      }

      .form_inner {
        padding: 45px 25px;
      }
    }
  }

  .button_holder {
    text-align: center;
  }
}