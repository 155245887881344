@import "../../../../styles/mixins";

.course_edit_modal {
  .cem_inner {
    overflow: auto;
  }

  &.cem_lesson {
    width: 770px;

    dl {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 16px;
      position: relative;
      z-index: 1;

      &.z_up {
        z-index: 2;
      }

      &.z_up_3 {
        z-index: 3;
      }

      &.focused {
        z-index: 10;
      }

      dt {
        flex-shrink: 0;
        width: 160px;
        padding-right: 16px;
        text-align: right;
        margin-top: 2px;

        &.eli_dt_video_label {
          margin-top: 6px;
        }

        &.duration_label {
          margin-top: -9px;
        }
      }

      dd {
        width: 100%;
        .dropdown {
          display: inline-block;
        }
        .course_form_hint {
          font-size: 10px;
          line-height: 16px;
          color: #C0C2C8;
        }

        textarea {
          display: block;
          margin-top: -4px;
          width: calc(100% - 18px);
          height: 120px;
          padding: 4px 8px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #C0C2C8;
          color: #434A5C;

          @include input-placeholder {
            color: #C0C2C8;
          }
        }

        input {
          display: block;
          margin-top: -4px;
          width: calc(100% - 18px);
          padding: 4px 8px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #C0C2C8;
          color: #434A5C;

          @include input-placeholder {
            color: #C0C2C8;
          }
        }
      }
    }

    .buttons_holder {
      margin-top: 32px;
    }
  }
}