@import "../../styles/mixins";

.player_v2_wrapper {
  width: 100%;
  height: 100%;
  background-color: #000;
  text-align: center;

  .react-player__preview {
    max-width: 1000px;
    margin: 0 auto;
  }

  @media screen and (max-width: $treshold) {
    height: 240px;
  }
}