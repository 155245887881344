@import "../../../../styles/mixins";

.ls_who_can_use_wrapper {
  background-color: #fff;

  .l_content {
    padding-top: 114px;
    padding-bottom: 135px;

    h2 {
      margin-bottom: 100px;
    }

    .plates {
      margin-left: -15px;
      width: 1200px;

      li {
        display: inline-block;
        vertical-align: top;
        width: 270px;
        height: 270px;
        margin: 15px;
        border-radius: 20px;
        background-color: #F9FBFC;

        text-align: center;

        img {
          display: inline-block;
          width: 60px;
          height: 60px;
          margin-top: 80px;
          margin-bottom: 35px;
        }

        p {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }

    .button_holder {
      text-align: center;
      padding-top: 60px;

      button {
        background-color: #2450FF;
        border: 0 solid;
        padding: 23px 35px 22px;
        border-radius: 30px;

        @media screen and (max-width: $treshold_max) {
          padding: 13px 0;
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    .l_content {
      .plates {
        width: auto;

        li {
          width: 208px;
          height: 208px;

          img {
            margin-top: 50px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    .l_content {
      padding-top: 55px;
      padding-bottom: 50px;

      h2 {
        margin-bottom: 35px;
      }

      .plates {
        width: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: 0;

        li {
          width: 48%;
          height: 175px;
          display: block;
          margin-right: 0;
          margin-left: 0;

          img {
            margin-top: 24px;
          }

          p {
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }
  }
}