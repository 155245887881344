@import "../styles/mixins";

.feed_block_outer {
  position: relative;
  max-width: 1680px;
  margin: 0 auto;

  @media screen and (max-width: 1740px) {
    max-width: 1344px;
  }

  @media screen and (max-width: 1360px) {
    max-width: 1008px;
  }

  &.single_gift {
    .feed_block_wrapper {
      height: 330px;
    }
  }

  .arr {
    position: absolute;
    z-index: 5;
    top: 0;
    height: 100%;
    width: 64px;
    opacity: .8;
    cursor: pointer;

    i {
      @include center-middle;
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.hidden {
      display: none;
    }

    &.arr_prev {
      right: 100%;
    }

    &.arr_next {
      left: 100%;

      i {
        svg {
          @include transform(rotate(180deg));
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    width: 100vw;
    margin-left: -24px;

    .arr {
      width: 10vw;

      &.arr_prev {
        right: auto;
        left: 5vw;
      }

      &.arr_next {
        left: auto;
        right: 5vw;
      }
    }
  }
}

.feed_block_wrapper {
  overflow: hidden;
  position: relative;
  //height: 330px;
  height: 360px;
}

.feed_block {
  position: absolute;
  top: 0;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
  @include transition(left ease-out 300ms);

  .course_preview,
  .feed_first_element {
    vertical-align: top;
    display: inline-block;
    margin: 0 16px;
    white-space: normal;
    user-select: none;
  }

  @media screen and (max-width: $treshold) {
    padding-left: 10vw;

    .course_preview,
    .course_preview_v2,
    .feed_first_element {
      width: 70vw !important;
      margin: 0 5vw !important;
    }

    a {
      width: 100%;
      margin-bottom: 48px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}