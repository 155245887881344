.icon_course_level {
  circle {
    fill: none;
    stroke: #fff;
    opacity: .5;

    &.filled {
      fill: #fff;
      opacity: 1;
    }
  }
}

.course_rating_wrapper {
  white-space: nowrap;

  span, svg {
    display: inline-block;
    vertical-align: top;
  }

  span {
    margin-left: 4px;
  }
}

.icon_course_rating {
  vertical-align: top;
  margin-top: -1px;
  margin-right: 7px;
  path {
    fill: #fff;
    opacity: .5;

    &.filled {
      opacity: 1;
    }
  }
}