@import "../styles/mixins";

.course_preview_v2 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 304px;
  margin: 0 16px;
  white-space: normal;
  color: #040E26;
  text-align: left;
  margin-bottom: 32px;

  &.cp_create_new_course {
    height: 270px;
    overflow: hidden;

    .course_preview_link {
      height: 100%;
      display: block;
      text-align: center;
      padding-top: 64px;

      .cnc_plus {
        position: relative;
        width: 64px;
        height: 64px;
        margin: 0 auto 32px;
        border-radius: 50%;
        background-color: #2450FF;

        svg {
          @include center-middle;
          width: 32px;
          height: 32px;
        }
      }

      .cnc_text {
        display: inline-block;
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        border-bottom: 1px solid #040E2650;
      }
    }
  }

  .course_preview_link {
    text-decoration: none;
    color: unset;
  }

  &:hover {
    .img_wrapper {
      .cp_params,
      .cp_fade {
        opacity: 1;
      }
    }

    .cp_school_links {
      display: block;
    }
  }

  .cp_school_links {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 16px 0 0;
    background-color: rgba(255,255,255,.7);
    text-align: center;
    display: none;

    button {
      width: 200px;
      margin-bottom: 16px;
    }
  }

  .cp_links_dropdown {
    position: absolute;
    z-index: 20;
    right: 10px;
    top: 10px;
    padding: 0;

    .dropdown_opener {
      width: unset;
    }

    .ld_button {
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: #F5F5F5;

      ins {
        @include center-middle;

        i {
          display: block;
          width: 2px;
          height: 2px;
          border-radius: 50%;
          margin-bottom: 3px;
          background-color: #040E26;

          &:last-of-type {
            margin-bottom: 0;
          }

        }
      }
    }
  }

  .img_wrapper {
    position: relative;
    height: 180px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 16px;

    img {
      height: 100%;
      @include center;
      z-index: 1;

      &.vertical,
      &.book_cover {
        height: auto;
        width: 100%;
        @include clear;
        position: absolute;
        top: 0;
      }
    }

    .img_plug {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 0;
    }

    .cp_pict {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 10;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    .cp_pict_text {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 10;
      font-size: 11px;
      line-height: 11px;
      letter-spacing: .55px;
      padding: 4px 10px;
      border-radius: 10px;
      text-transform: uppercase;
      color: #fff;

      &.cp_pict_audio {
        background-color: #7f35be;
      }

      &.cp_pict_video {
        background-color: #2450ff;
      }
    }

    .cp_params {
      position: absolute;
      z-index: 5;
      left: 0;
      bottom: 0;
      padding: 0 10px 10px;
      width: calc(100% - 20px);
      font-size: 12px;
      line-height: 12px;

      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      color: #fff;
      @include transition(opacity linear 200ms);
      opacity: 0;

      svg path {
        fill: #fff;
      }

      .icon_course_level {
        margin-bottom: -4px;
      }
    }
    .cp_fade {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      @include gradient(rgba(0,0,0,0), rgba(0,0,0,.5));
      @include transition(opacity linear 200ms);
      opacity: 0;
    }
  }

  h4 {
    font-size: 12px;
    line-height: 12px;
    opacity: .7;
    margin-bottom: 12px;
  }

  h3 {
    font-size: 15px;
    line-height: 20px;
    height: 40px;
    overflow: hidden;
    margin-bottom: 15px;
  }

  .teaser {
    font-size: 13px;
    line-height: 16px;
    opacity: .7;
    height: 32px;
    overflow: hidden;
    margin-bottom: 16px;
  }

  .button_holder {
    &.gift_select {
      text-align: center;
    }
    .discount_text {
      display: inline-block;
      margin-left: 20px;
      color: #040E26;
      opacity: 0.7;
      font-size: 12px;

      &.old_price {
        font-size: 16px;
        opacity: .5;
        text-decoration: line-through;
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    &.cp_create_new_course {
      display: none;
    }

    .img_wrapper {
      .cp_params,
      .cp_fade {
        opacity: 1;
      }
    }
  }
}