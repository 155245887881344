@import "../../styles/mixins";

.custom_modal_content {
  min-width: 600px;

  @include adaptive($treshold) {
    min-width: 0;
  }
}

.commercial_greeting_player {
  position: relative;
  width: 100%;
  min-width: 600px;
  overflow: hidden;

  @include adaptive($treshold) {
    min-width: 0;
  }

  &.playing {
    .btn_play {
      opacity: 0;
    }

    .preloader {
      opacity: 1;
    }

    &.started {
      .btn_play,
      .poster,
      .preloader {
        display: none;
      }
    }
  }

  .poster {
    @include center-middle;
    z-index: 10;
    width: 100%;
    @include transition(linear 300ms);
  }

  .btn_play {
    @include center-middle;
    z-index: 20;
    @include transition(linear 300ms);
  }

  .preloader {
    @include center-middle;
    z-index: 15;
    opacity: 0;
    width: 59px;
    height: 64px;
    margin-left: -8px;

    svg {
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }
  }
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}