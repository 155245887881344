@import "../styles/mixins";

.course_rating_block {
  .rating_vote {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 24px;
    margin-top: -5px;

    svg {
      height: 48px;
      width: auto;

      path {
        fill: #434A5C;
        opacity: .5;

        &.filled {
          opacity: 1;
        }
      }
    }

    .rv_buttons {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      font-size: 0;

      i {
        width: 20%;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
      }
    }
  }

  &.rated {
    .rv_buttons, button {
      display: none;
    }
  }
}