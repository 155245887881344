@import "../styles/mixins";

.ReactModal__Body--open {

}

.Modal {
  @include center-middle;
  background-color: #fff;
  padding: 36px;
  text-align: inherit;

  max-height: 90vh;
  overflow: auto;

  @media screen and (max-width: $treshold) {
    width: 80%;
  }

  &.no_padding {
    padding: 0;
  }

  .modal_close {
    position: absolute;
    z-index: 300;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .video_modal {
    width: 640px;
    height: 360px;

    .player_v2_wrapper {
      width: 640px;
      height: 360px;
    }

    .cannot_play {
      padding: 30px;

      h3 {
        font-size: 21px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding: 16px;

    .video_modal {
      width: 100%;
      height: calc(80vh * 0.4);

      .player_v2_wrapper {
        width: 100%;
        height: calc(80vh * 0.4);
      }
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.4);
  z-index: 9909;
}

.modal_purchase_link {
  text-align: center;

  p {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
}

.crunch_reg_link {
  text-align: center;
  padding-top: 16px;

  a {
    color: #434A5C;
    border-bottom: 1px solid rgba(60, 30, 30, 0.5);
    text-decoration: none;
  }
}

