@import "../../../../../styles/mixins";

.edit_lesson_type_text {
  dt {
    padding-top: 12px;

  }
  .rdw-editor-toolbar {
    border: 0 solid;
    padding-left: 0;
    margin-left: -4px;
  }

  textarea,
  .demo-editor {
    display: block;
    width: 100%;
    height: 200px;
    padding: 4px 8px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #C0C2C8;
    color: #434A5C;

    @include input-placeholder {
      color: #C0C2C8;
    }

    h1 {
      font-size: 30px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    h2 {
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 17px;
      line-height: 25px;
      font-weight: 600;
      margin-bottom: 21px;
    }
  }
}