@import "mixins";
@import "fonts";

body {
  font-family: "Inter", sans-serif;
  color: #040E26;
  background-color: #fff;
  font-size: unset;
  font-weight: unset;
  line-height: unset;

  * {
    box-sizing: unset;

    &:before,
    &:after {
      box-sizing: unset;
    }
  }

  .title:not(:last-child) {
    margin-bottom: unset;
  }

  img {
    max-width: unset;
  }

  &.scroll_locked {
    overflow: hidden;

    @media screen and (max-width: $treshold) {
      position: fixed;
    }
  }

  .page_view {
    min-height: calc(100vh - 96px);
  }

  &.minimal {
    .page_view {
      min-height: calc(100vh - 96px - 156px);
    }
  }
}

input, textarea, button {
  font-family: "Inter", sans-serif;
}

.text_input {
  border: 0 solid;
  border-bottom: 1px solid #434A5C;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
  color: #434A5C;

  @include input-placeholder {
    color: #434A5C;
  }

  &:focus {
    @include input-placeholder {
      opacity: .5;
    }
  }

  &[type=password] {
    letter-spacing: 5px;

    @include input-placeholder {
      letter-spacing: 0;
    }
  }
}
