@import "../../../../styles/mixins";

.ls_free_services_wrapper {
  padding: 48px 0 30px;
  @include gradient(#FAFCFF, #DEE6F5);

  .school_services & {
    padding-top: 120px;
  }

  h2 {
    margin-bottom: 70px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 30%;
      margin-right: 3%;
      flex-shrink: 0;
      font-size: 24px;
      line-height: 35px;
      font-weight: 500;
      margin-bottom: 40px;

      .icon_plus {
        position: relative;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        @include gradient(#ff009d, #ff004c);
        margin-bottom: 34px;

        span {
          @include center-middle;
          margin-top: -4px;
          color: #fff;
          font-size: 42px;
          line-height: 42px;
          font-weight: 400;
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    h2 {
      margin-bottom: 30px;
    }

    ul {
      display: block;

      li {
        position: relative;
        width: auto;
        display: block;
        margin-right: 0;
        margin-bottom: 38px;
        padding-left: 48px;
        font-size: 18px;
        line-height: 28px;

        .icon_plus {
          position: absolute;
          width: 28px;
          height: 28px;
          left: 0;
          top: 0px;

          span {
            font-size: 32px;
            line-height: 32px;
            margin-top: -3px;
          }
        }
      }
    }
  }
}