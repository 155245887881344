@import "../../styles/mixins";

.course_landing_wrapper {
  .cl_centrer {
    position: relative;
    width: 1170px;
    height: 100%;
    margin:  0 auto;

    h2 {
      font-size: 40px;
      line-height: 40px;
      font-weight: 500;
      color: #040E26;
      margin-bottom: 72px;
    }
  }

  @include adaptive($adaptive_course_mid) {
    .cl_centrer {
      width: auto;
      margin: 0 50px;
    }
  }

  @include adaptive($adaptive_course_min) {
    .cl_centrer {
      margin: 0 20px;

      h2 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 45px;
      }
    }
  }
}