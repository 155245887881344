@import "../styles/mixins";

.main_feed_wrapper {
  position: relative;
  padding-top: 56px;
  text-align: center;


  h2 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 36px;
    text-transform: lowercase;
  }

  .switcher {
    font-size: 0;
    white-space: nowrap;
    margin-bottom: 64px;
    display: none;

    li {
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      line-height: 16px;
      margin: 0 12px;
      color: #C0C2C8;
      padding-bottom: 3px;
      cursor: pointer;

      &.active {
        color: #040E26;
        border-bottom: 1px solid #040E26;
      }
    }
  }

  .v_spacer {
    font-size: 0;
    height: 59px;
    display: none;
  }


  .welcome_schools {
    width: 304px;
    height: 330px;
    position: relative;
    border: 1px dashed #E6E7EA;
    box-sizing: border-box;
    border-radius: 16px;
    text-align: center;
    text-decoration: none;
    color: #040E26;

    .ws_inner {
      @include center-middle;
      width: 80%;

      img {
        margin-bottom: 12px;
        display: inline-block;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (min-width: $treshold) {
    .feed_block_wrapper {
      height: 280px;

      .course_preview_v2 {
        width: 234px;
        margin: 0 10px;

        .img_wrapper {
          height: 130px;
        }

        h3 {
          height: 60px;
        }

        .teaser {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding-top: 72px;

    .switcher {
      margin-bottom: 24px;
    }

    &.welcome_schools {
      display: none;
    }
  }
}