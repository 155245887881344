@import "../../styles/mixins";

.ceb_landing {
  .add_blocks_list {

  }

  .add_blocks_list {
    width: 600px;
    margin: 0 auto 32px;
    text-align: left;

    li {
      margin-bottom: 8px;

      span {
        cursor: pointer;
        font-size: 14px;
        line-height: 14px;
        border-bottom: 1px dashed #C0C2C8;
      }
    }
  }

  .blocks {
    width: 600px;
    margin: 0 auto;
    text-align: left;

    .block_item {
      margin-bottom: 8px;
      position: relative;

      .bi_inner {
        cursor: pointer;
        margin-right: 32px;
        border: 1px solid #C0C2C8;

        padding: 4px 8px;
      }

      .bi_delete {
        position: absolute;
        right: 8px;
        top: 4px;
        cursor: pointer;
      }

    }
  }

}