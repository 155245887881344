@import "../../../../styles/mixins";

.ls_pre_footer_wrapper {
  padding: 100px 0 147px;

  .items {
    white-space: nowrap;
    font-size: 0;

    li {
      display: inline-block;
      vertical-align: top;
      width: 250px;
      margin-right: 150px;
      white-space: normal;

      &:last-of-type {
        margin-right: 0;
      }

      img {
        margin-bottom: 44px;
      }

      h2 {
        font-size: 45px;
        line-height: 55px;
        font-weight: 500;
        margin-bottom: 26px;
      }

      p {
        font-size: 14px;
        line-height: 25px;
        color: #444750;

        a {
          text-decoration: none;
          color: #2450FF;
          border-bottom: 1px solid #2450FF50;
        }
      }
    }
  }

  @media screen and (max-width: $treshold_max) {
    .items {
      li {
        width: auto;
        max-width: 230px;
        margin-right: 132px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    padding-bottom: 80px;

    .items {
      white-space: normal;

      li {
        display: block;
        text-align: center;
        max-width: 1000px;
        margin-right: 0;
        margin-bottom: 57px;

        img {
          display: inline-block;
          margin-bottom: 26px;
        }

        h2 {
          margin-bottom: 13px;
          font-size: 30px;
          line-height: 35px;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}