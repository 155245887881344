@import "../../../../styles/mixins";

.cl_future_skills {
  padding: 60px 0 40px;

  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    li {
      width: 28%;
      margin-right: 5%;
      flex-shrink: 0;
      margin-bottom: 60px;
      font-size: 17px;
      line-height: 25px;
      color: #040E26;

      svg {
        display: block;
        margin-bottom: 26px;
      }
    }
  }

  @include adaptive(900px) {
    ul {
      li {
        width: 47%;
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0 20px;

    ul {
      li {
        width: 100%;
        margin-bottom: 47px;
        font-size: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}