@import "../../../styles/mixins";

.course_edit_modal {
  min-width: 600px;

  &.cem_course_for {
    min-width: 400px;

    .cem_content.cem_text {
      textarea {
        height: 60px;
      }
    }
  }

  &.cem_future_skills {
    .cem_content {
      min-height: 170px;

      textarea {
        height: 120px;
      }
    }
  }

  &.cem_faq {
    .cem_content {

      textarea {
        height: 120px;
      }
    }
  }

  .cem_inner {
    overflow: auto;
  }

  .cem_text {
    margin-bottom: 24px;

    h3 {
      font-weight: bold;
      text-align: center;
      margin-bottom: 16px;
    }

    .block_description {
      font-size: 13px;
      line-height: 19px;
    }
  }

  .cem_content {
    margin-bottom: 24px;

    h5 {
      font-size: 10px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #C0C2C8;
    }

    .cem_hint {
      font-size: 10px;
      line-height: 16px;
      color: #C0C2C8;

      a {
        text-decoration: none;
        color: #C0C2C8;
        border-bottom: 1px solid #C0C2C8;
      }
    }

    &.cem_text {
      textarea {
        display: block;
        width: calc(100% - 18px);
        height: 120px;
        padding: 4px 8px;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #C0C2C8;
        color: #434A5C;

        @include input-placeholder {
          color: #C0C2C8;
        }
      }
    }

    &.cem_dl {

    }

    &.cem_list {
      .list_header {
        width: calc(100% - 64px);
        margin-bottom: 32px;
        text-align: center;
        .dropdown {
          display: inline-block;
          margin:  0 10px 0 8px;
          border-bottom: 1px solid #C0C2C8;
        }
      }
    }
  }

  .cem_buttons {
    text-align: center;

    button {
      display: inline-block;
      margin: 0 8px;
    }

    a {
      display: inline-block;
      margin: 0 8px;

      button {
        margin: 0;
      }
    }
  }

  &.cem_lesson {
    width: 720px;

    dl {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 16px;

      dt {
        flex-shrink: 0;
        width: 104px;
        padding-right: 16px;
        text-align: right;
        margin-top: 2px;
      }

      dd {
        width: 100%;
        textarea {
          display: block;
          margin-top: -4px;
          width: calc(100% - 18px);
          height: 120px;
          padding: 4px 8px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #C0C2C8;
          border-bottom-width: 2px;
          color: #434A5C;

          @include input-placeholder {
            color: #C0C2C8;
          }
        }

        input {
          display: block;
          margin-top: -4px;
          width: calc(100% - 18px);
          padding: 4px 8px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #C0C2C8;
          color: #434A5C;

          @include input-placeholder {
            color: #C0C2C8;
          }
        }
      }
    }
  }
}