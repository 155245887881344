@import "../../../styles/mixins";

.course_edit_list_wrapper {
  li {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #00000016;

    .item_inner {
      width: calc(100% - 64px);

      textarea {
        display: block;
        width: calc(100% - 18px);
        height: 48px;
        padding: 4px 8px;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #C0C2C8;
        color: #434A5C;

        @include input-placeholder {
          color: #C0C2C8;
        }
      }
    }

    .item_delete {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }
}