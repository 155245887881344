@import "./mixins";

.default_input_wrapper {
  position: relative;
  padding-top: 18px;
  margin-bottom: 16px;

  input,
  textarea{
    width: 100%;
    position: relative;
    z-index: 2;
    background-color: transparent;
    border: 0 solid;
    border-bottom: 1px solid #C0C2C8;
    display: block;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;

    &:disabled {
      border-bottom-color: transparent;
      color: #434A5C;
      opacity: 1;
    }
  }

  input {
    padding-left: 0;
    padding-right: 0;
  }

  textarea {
    border: 1px solid #C0C2C8;
    margin-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    width: calc(100% - 10px);
  }

  label {
    color: #C0C2C8;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 10px;
    line-height: 24px;

    &.up {
      top: 0;
      font-size: 10px;
    }
  }

  .dropdown {
    display: inline-block;

    &.disabled {
      opacity: 1;
      .dropdown_arrow {
        display: none;
      }
    }
  }

  @supports (not (-ms-ime-align:auto)) {
    label {
      color: #C0C2C8;
      top: 18px;
      transition: all 0.2s ease-out;
      font-size: 16px;

      &.up {
        top: 0;
        font-size: 10px;
      }
    }

    input:focus + label,
    input:disabled + label,
    input:not(:placeholder-shown) + label,
    textarea:focus + label,
    textarea:disabled + label,
    textarea:not(:placeholder-shown) + label{
      top: 0;
      font-size: 10px;
    }
  }
}