.ls_statistics_wrapper {
  padding: 94px 0 104px;
  background: #F8F8FA;
  /* Old browsers */
  background: -moz-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F8F8FA), color-stop(100%, #fff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #F8F8FA 0%, #fff 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
  /* IE6-9 */ }
  .ls_statistics_wrapper h2 {
    margin-bottom: 80px; }
  .ls_statistics_wrapper ul {
    display: flex;
    flex-wrap: wrap; }
    .ls_statistics_wrapper ul li {
      width: 30%;
      margin-right: 3%;
      margin-bottom: 40px;
      font-size: 18px;
      line-height: 25px; }
      .ls_statistics_wrapper ul li .count {
        position: relative;
        padding-left: 71px;
        margin-bottom: 26px;
        font-size: 45px;
        line-height: 45px;
        font-weight: 600;
        color: #D2835D; }
        .ls_statistics_wrapper ul li .count .icon {
          position: absolute;
          top: 50%;
          -moz-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          left: 0;
          width: 55px; }
          .ls_statistics_wrapper ul li .count .icon svg {
            width: 100%;
            height: auto; }
  @media screen and (max-width: 600px) {
    .ls_statistics_wrapper {
      padding: 32px 0 48px; }
      .ls_statistics_wrapper h2 {
        margin-bottom: 28px; }
      .ls_statistics_wrapper ul {
        display: block; }
        .ls_statistics_wrapper ul li {
          display: block;
          width: auto;
          margin-right: 0;
          margin-bottom: 32px; }
          .ls_statistics_wrapper ul li .count {
            margin-bottom: 16px; } }

