@import "../../../../styles/mixins";

.cl_leave_review {
  background-color: #F5F5F5;
  padding: 80px 0;

  .cl_centrer {
    display: flex;

    .cl_left {
      width: calc(50% - 130px);
      padding-right: 130px;

      h2 {
        margin-top: -12px;
        margin-bottom: 39px;
      }

      p {
        font-size: 22px;
        line-height: 30px;
        opacity: .5;
      }
    }

    .cl_right {
      position: relative;
      width: 50%;
      background-color: #fff;
      border-radius: 10px;
    }
  }

  .leave_review_block {
    padding: 58px 60px;

    &.rated {
      .score, textarea, button {
        display: none;
      }

      .rated_message {
        display: block;
      }
    }

    .score {
      margin-bottom: 30px;

      label {
        display: block;
        font-size: 13px;
        line-height: 16px;
        opacity: .7;
        margin-bottom: 18px;
      }

      ul {
        display: flex;
        align-content: flex-start;

        li {
          width: 32px;
          height: 30px;
          padding-right: 5px;
          flex-shrink: 0;
          cursor: pointer;

          &.selected {
            svg {
              path {
                fill: #FFD500;
              }
            }
          }
        }
      }
    }

    textarea {
      width: calc(100% - 26px);
      height: 75px;
      padding: 12px;
      border-radius: 10px;
      border: 1px solid #040E2610;
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 48px;
    }

    button {
      width: 100%;
    }

    .rated_message {
      display: none;
      font-size: 17px;
      line-height: 25px;

      strong {
        font-weight: 500;
      }
    }
  }

  @include adaptive($adaptive_course_mid) {
    .cl_centrer {
      flex-wrap: wrap;
      justify-content: flex-start;

      .cl_left {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
        margin-bottom: 39px;
      }

      .cl_right {
        min-width: 600px;
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0 50px;

    .cl_centrer {

      .cl_left {
        h2 {
          margin-bottom: 31px;
        }

        p {
          font-size: 17px;
          line-height: 25px;
          margin-bottom: 37px;
        }
      }

      .cl_right {
        min-width: 0;
        width: 100%;

        .leave_review_block {
          padding: 30px 20px 48px;
        }
      }
    }
  }
}