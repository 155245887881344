@import "../../../../../styles/mixins";

.cem_lesson {

  .eli_video {
    padding-top: 2px;

    h5 {
      margin-bottom: 16px;
    }

    .course_form_hint {
      font-size: 10px;
      line-height: 16px;
      color: #C0C2C8;

      a {
        text-decoration: none;
        color: #C0C2C8;
        border-bottom: 1px solid #C0C2C8;
      }

      &.cfh_abs {
        position: absolute;
      }

      .pseudo {
        border-bottom: 1px dotted #C0C2C8;
        cursor: pointer;
      }
    }

    .eli_video_tabs {
      white-space: nowrap;
      font-size: 0;
      margin-bottom: 16px;

      h5 {
        cursor: pointer;
        width: 50%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        border-bottom: 1px solid #E6E7EA;

        &.active {
          cursor: default;
          border-bottom: 1px solid #434A5C;
          color: #434A5C;
        }
      }
    }

    .upload_bar {
      display: block;
      width: calc(100% - 18px);
      padding: 2px 8px;
      font-size: 16px;
      line-height: 24px;
      height: 30px;
      border: 1px solid #C0C2C8;
      color: #434A5C;

      &:disabled {
        opacity: .4;
      }

      @include input-placeholder {
        color: #C0C2C8;
      }
    }

    .external_source_field_wrapper {
      position: relative;

      .btn_check_source {
        position: absolute;
        top: 1px;
        right: 1px;
      }

      .player_v2_wrapper {
        margin-top: 16px;
        width: 400px;
        height: 240px;
      }
    }

    .eli_cloud_upload {
      position: relative;
      overflow: hidden;

      input {
        font-size: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        opacity: 0;
        visibility: hidden;

        &:disabled + label {
          opacity: .4;
        }
      }

      label {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .file_name {
          padding: 6px 8px;
          font-size: 16px;
          line-height: 24px;
        }

        .label_btn {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          font-size: 16px;
          line-height: 16px;
          padding: 8px 20px;
          background-color: #040E26;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
