@import "../styles/mixins";

.course_socials {
  display: flex;
  justify-content: space-between;
  padding-top: 48px;

  button {
    width: auto;
    height: auto;
  }
}