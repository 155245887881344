@import "mixins";

.pass_not_set {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 500;
  width: 100%;
  background-color: #C33333;

  p {
    padding: 8px 24px;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    color: #fff;
  }
}