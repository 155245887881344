.ls_how_it_works_wrapper {
  height: 855px;
  position: relative;
  background-color: #2450FF; }
  .ls_how_it_works_wrapper .l_content {
    color: #fff; }
    .ls_how_it_works_wrapper .l_content h2 {
      padding-top: 114px;
      margin-bottom: 130px; }
    .ls_how_it_works_wrapper .l_content .plates {
      margin-bottom: 122px;
      white-space: nowrap; }
      .ls_how_it_works_wrapper .l_content .plates li {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 248px; }
        .ls_how_it_works_wrapper .l_content .plates li:last-of-type {
          width: 180px; }
          .ls_how_it_works_wrapper .l_content .plates li:last-of-type .img_wrapper {
            background-color: #0DA750;
            box-shadow: 0 10px 10px #0000001A;
            border: 0 solid; }
            .ls_how_it_works_wrapper .l_content .plates li:last-of-type .img_wrapper .num span {
              color: #0DA750; }
        .ls_how_it_works_wrapper .l_content .plates li .img_wrapper {
          position: relative;
          background-color: #2450FF;
          width: 100px;
          height: 100px;
          box-shadow: 15px 15px 30px #00000010;
          border: 1px solid #2478FF;
          border-radius: 20px;
          margin-bottom: 35px; }
          .ls_how_it_works_wrapper .l_content .plates li .img_wrapper img {
            position: absolute;
            top: 50%;
            left: 50%;
            -moz-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
          .ls_how_it_works_wrapper .l_content .plates li .img_wrapper .num {
            position: absolute;
            width: 30px;
            height: 30px;
            left: -10px;
            top: -10px;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 10px 10px #0000000D; }
            .ls_how_it_works_wrapper .l_content .plates li .img_wrapper .num span {
              position: absolute;
              top: 50%;
              left: 50%;
              -moz-transform: translate(-50%, -50%);
              -webkit-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              color: #2450FF;
              font-size: 12px;
              line-height: 20px;
              font-weight: 600; }
          .ls_how_it_works_wrapper .l_content .plates li .img_wrapper .dots {
            position: absolute;
            white-space: nowrap;
            height: 4px;
            left: 100%;
            margin-left: 46px;
            top: 40px;
            font-size: 0; }
            .ls_how_it_works_wrapper .l_content .plates li .img_wrapper .dots i {
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              margin-right: 10px;
              background-color: #2478FF; }
        .ls_how_it_works_wrapper .l_content .plates li p {
          width: 180px;
          font-size: 18px;
          line-height: 25px;
          white-space: normal; }
    .ls_how_it_works_wrapper .l_content .download_manual a {
      display: inline-block;
      position: relative;
      padding-left: 80px;
      padding-top: 8px;
      text-decoration: none; }
      .ls_how_it_works_wrapper .l_content .download_manual a img {
        position: absolute;
        left: -30px;
        top: -30px; }
      .ls_how_it_works_wrapper .l_content .download_manual a p {
        font-size: 18px;
        line-height: 25px;
        color: #fff;
        margin-bottom: 6px; }
      .ls_how_it_works_wrapper .l_content .download_manual a span {
        font-size: 14px;
        line-height: 25px;
        color: #64D3FF;
        border-bottom: 1px solid #64D3FF50; }
  .ls_how_it_works_wrapper .ls_bg {
    position: absolute;
    z-index: 0;
    bottom: 0;
    height: 400px;
    width: 100%;
    background: rgba(36, 80, 255, 0);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(36, 80, 255, 0)), color-stop(100%, #4114BC));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
    /* IE6-9 */ }
  @media screen and (max-width: 1440px) {
    .ls_how_it_works_wrapper .l_content .plates li {
      width: 20%; }
      .ls_how_it_works_wrapper .l_content .plates li p {
        width: 150px; } }
  @media screen and (max-width: 1050px) {
    .ls_how_it_works_wrapper .l_content .plates {
      width: 100%;
      margin-left: -50px;
      padding: 10px 50px 20px;
      overflow: auto; }
      .ls_how_it_works_wrapper .l_content .plates li .img_wrapper .dots {
        display: none; } }
  @media screen and (max-width: 600px) {
    .ls_how_it_works_wrapper {
      height: auto;
      padding-bottom: 50px; }
      .ls_how_it_works_wrapper .l_content h2 {
        padding-top: 50px;
        margin-bottom: 55px; }
      .ls_how_it_works_wrapper .l_content .plates {
        margin-left: -25px;
        margin-bottom: 25px;
        padding: 10px 25px 20px; } }

