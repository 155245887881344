@import "../styles/mixins";
$animation: linear 250ms;

.header_v2 {
  .search_bar {
    @include middle;
    left: 205px;
    width: calc(50% - 250px);

    button {
      right: auto;
      left: 0;

      .search_icon {
        display: none;
      }

      .search_icon_2 {
        display: block;
      }
    }

    input {
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase;
      border-bottom: 0 solid;
      padding-left: 30px;
      width: calc(100% - 30px);
      text-align: left;
      color: #040e26;

      @include input-placeholder {
        color: #040e26;
      }

      &:focus {
        @include input-placeholder {
          color: transparent;
        }
      }
    }

    @media screen and (max-width: $treshold_mid) {
      z-index: 150;
      left: 0;
      width: 100%;
      background-color: #fff;
      height: 59px;

      button {
        left: 22px;
      }

      input {
        margin-top: 21px;
        padding-left: 52px;
        width: calc(100% - 52px);
      }

      .clear_search {
        svg {
          width: auto;
        }
      }

      .close_search {
        display: block;
        right: 22px;
        @include middle;
        margin-top: 2px;
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    .search_bar {
      margin-top: -60px;
      @include transition(linear 150ms);
    }

    &.show_search {
      .search_bar {
        margin-top: 0;
        display: block;
      }
    }
  }
}

.search_bar {
  @include center-middle;
  width: 416px;

  body.no_search & {
    display: none !important;
  }

  .close_search {
    z-index: 10;
    display: none;
  }

  .clear_search {
    @include middle;
    right: 22px;
    margin-top: 2px;
    opacity: 0;
    z-index: 20;
    background: #fff;

    svg {
      width: 10px;
    }
  }

  &.focused {
    .clear_search {
      opacity: 1;
    }
  }

  button {
    @include middle;
    right: 0;
  }

  input {
    font-size: 16px;
    line-height: 24px;
    color: #E6E7EA;
    padding: 0;
    width: 100%;
    text-align: center;
    border: 0 solid;
    border-bottom: 1px solid #E6E7EA;
    background-color: transparent;

    @include transition($animation);

    @include input-placeholder {
      color: #C0C2C8;
    }
  }

  button {
    .search_icon {
      circle, path {
        stroke: #E6E7EA;
      }
    }

    .search_icon_2 {
      display: none;
    }
  }

  @media screen and (max-width: $treshold_mid) {
    width: 50%;
    min-width: 160px;
  }
}
