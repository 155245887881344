@import "../styles/mixins";

.course_params {
  display: flex;
  flex-wrap: wrap;
  width: 368px;

  dl {
    width: 168px;
    margin: 0 16px 24px 0;
    font-size: 16px;
    line-height: 24px;
    flex-shrink: 0;

    &.source_link {
      width: 368px;
      margin: 0;
    }

    a {
      color: #fff;
      text-decoration: none;
      border-bottom: 1px solid rgba(255,255,255,.5);

      div.cs_info & {
        color: #040E26;
        border-bottom: 1px solid #040E26;
      }
    }

    dt {
      opacity: .75;
    }
  }

  @media screen and (max-width: $treshold) {
    width: 100%;

    dl {
      width: calc(50% - 12px);
      margin-right: 12px;
    }
  }
}