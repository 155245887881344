@import "../../styles/mixins";

.ceb_finish {
  .ce_button_holder {
    margin: 0 auto;
    width: 256px;
    button {
      margin-bottom: 16px;
    }
  }
}