@import "../styles/mixins";

.main_content {
  margin: 0 64px 64px;

  @media screen and (max-width: $treshold) {
    margin: 0 24px 24px;
  }
}


.mp_banner {
  display: none;
  height: 20vh;
  background-color: #F2F0F0;
}