@import "../../styles/mixins";

.landing_wrapper {
  .l_content {
    width: 1340px;
    margin: 0 auto;
    position: relative;
    z-index: 5;

    h2 {
      font-size: 45px;
      line-height: 70px;
      font-weight: 500;
    }

    @media screen and (max-width: $treshold_max) {
      width: auto;
      margin: 0 50px;
    }

    @media screen and (max-width: $treshold) {
      margin: 0 25px;

      h2 {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .l_centrer {
    max-width: 1340px;
    margin: 0 auto;
    position: relative;
    z-index: 5;

    @media screen and (max-width: $treshold_max) {
      width: auto;
      margin: 0 50px;
    }
    @media screen and (max-width: $treshold) {
      margin: 0 25px;
    }
  }



  button {
    background-color: #2450FF;
    border: 0 solid;
    padding: 21px 35px 24px;
    border-radius: 30px;

    &:disabled {
      opacity: .5;
    }

    @media screen and (max-width: $treshold) {
      font-size: 13px;
      line-height: 13px;
      text-align: center;
      padding: 13px 0;
      width: 145px;
    }
  }

}