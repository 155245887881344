@import "../../styles/mixins";

.edit_webinars_wrapper .webinars_edit_block.ceb_lectures {

  .lessons_content_source {
    width: 752px;
    margin: 0 auto 64px;

    font-size: 0;
    white-space: nowrap;

    input {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 18px - 120px);
      padding: 4px 8px;
      font-size: 16px;
      line-height: 24px;
      border: 1px solid #C0C2C8;
      color: #434A5C;

      @include input-placeholder {
        color: #C0C2C8;
      }
    }
  }

  .ceb_lectures_feed {
    width: 752px;
    margin: 0 auto;
  }

  .ce_button_holder {
    padding-top: 0;
  }

  .edit_lecture_item {
    position: relative;
    margin-bottom: 64px;


    .eli_position {
      position: absolute;
      white-space: nowrap;
      right: 100%;
      margin-right: 32px;
      top: 8px;
    }

    .eli_title_block {
      margin-bottom: 16px;

      .eli_title_input {
        width: 100%;
        padding: 0;
        border: 0 solid;
        border-bottom: 1px solid #C0C2C8;
        font-size: 24px;
        line-height: 32px;
        min-height: 32px;
        color: #434A5C;

        @include input-placeholder {
          color: #C0C2C8;
        }
      }
    }

    .eli_video {
      margin-bottom: 16px;

      .eli_video_tabs {
        white-space: nowrap;
        font-size: 0;
        margin-bottom: 16px;

        h5 {
          cursor: pointer;
          width: 50%;
          display: inline-block;
          vertical-align: top;
          text-align: center;
          border-bottom: 1px solid #E6E7EA;

          &.active {
            cursor: default;
            border-bottom: 1px solid #434A5C;
            color: #434A5C;
          }
        }
      }

      .upload_bar {
        display: block;
        width: calc(100% - 18px);
        padding: 2px 8px;
        font-size: 16px;
        line-height: 24px;
        height: 30px;
        border: 1px solid #C0C2C8;
        color: #434A5C;

        &:disabled {
          opacity: .4;
        }

        @include input-placeholder {
          color: #C0C2C8;
        }
      }

      .external_source_field_wrapper {
        position: relative;

        .btn_check_source {
          position: absolute;
          top: 1px;
          right: 1px;
        }

        .player_v2_wrapper {
          width: 400px;
          height: 240px;
        }
      }

      .eli_cloud_upload {
        position: relative;
        overflow: hidden;

        input {
          font-size: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          opacity: 0;
          visibility: hidden;

          &:disabled + label {
            opacity: .4;
          }
        }

        label {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          .file_name {
            padding: 6px 8px;
            font-size: 16px;
            line-height: 24px;
          }

          .label_btn {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            font-size: 16px;
            line-height: 16px;
            padding: 8px 20px;
            background-color: #040E26;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }

    .eli_params_block {
      margin-bottom: 32px;
      display: flex;


      .cpb_item {
        width: 240px;
        flex-shrink: 0;
        margin-right: 30px;

        &.free_course_toggler {
          padding-top: 20px;
        }

        .dropdown {
          padding-bottom: 1px;
          border-bottom: 1px solid #C0C2C8;
        }

        .text_input {
          padding-bottom: 1px;
          border-bottom: 1px solid #C0C2C8;
          width: 100%;
        }

        .react-datepicker-wrapper {
          width: 100%;
        }
      }
    }

    .eli_description {
      margin-bottom: 16px;

      textarea {
        display: block;
        width: calc(100% - 18px);
        height: 200px;
        padding: 4px 8px;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #C0C2C8;
        color: #434A5C;

        @include input-placeholder {
          color: #C0C2C8;
        }
      }
    }

    .ce_button_holder {
      padding-top: 0;
      text-align: right;

      button {
        margin-left: 8px;
      }
    }
  }
}