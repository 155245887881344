@import "../styles/mixins";

.courses_block {
  text-align: center;
  display: block;

  .course_preview {
    width: 304px;
    display: inline-block;
    vertical-align: top;
    margin: 0 8px 40px;
    text-align: left;
  }

  @media screen and (max-width: $treshold) {
    .course_preview {
      width: 100%;
      max-width: 304px;
      margin: 0 0 48px;
    }
  }
}