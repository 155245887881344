@import "../../../../../styles/mixins";

.edit_lesson_type_pdf {
  .pdf_url_field {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    margin-bottom: 16px;

    input {
      width: 100%;
    }

    button {
      flex-shrink: 0;
      margin-top: -4px;
    }
  }

  dd {
    position: relative;

    .pdf_viewer {
      canvas {
        max-width: 594px;
      }
    }
  }
}