@import "../../styles/mixins";

.preloader_wrapper {
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.7);

  &.search_preloader {
    z-index: 20;
    position: fixed;
  }

  &.bottom_loader {
    position: relative;
    height: 100px;
    left: auto;
    top: auto;
  }

  .logo_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
    animation: preloader_rotate 4s infinite;

    .logo {
      @include center-middle;
      height: 40px;
      width: 40px;
      margin: 0 auto;

      span {
        position: absolute;
        left: 0;
        text-transform: uppercase;
        font-family: 'Source Black', sans-serif;

        font-size: 40px;
        line-height: 40px;
        top: 0;

        &.w {
          color: #FF00C9;
          left: 0;//12
          animation: preloader_w 4s infinite;
        }

        &.hub {
          left: 13px;//-5
          mix-blend-mode: multiply;
          color: #02FF00;
          animation: preloader_h 4s infinite;
        }
      }

      &.white {
        a span {
          &.w {
            color: #FF0079;
          }

          &.hub {
            mix-blend-mode: screen;
            color: #00FFFF;
          }
        }
      }
    }
  }
}

@keyframes preloader_rotate {
  0% {
    @include transform(rotate(0deg));
  }
  50% {
    @include transform(rotate(1440deg));
  }
  100% {
    @include transform(rotate(1440deg));
  }
}



@keyframes preloader_w {
  0% {
    left: 0;
    opacity: 1;
  }
  20% {
    opacity: .5;
  }
  50% {
    left: 54px;
    opacity: 1;
  }
  75% {
    left: 0;
  }
  100% {
    left: 0;
  }
}

@keyframes preloader_h {
  0% {
    left: 13px;
    opacity: 1;
  }
  20% {
    opacity: .5;
  }
  50% {
    left: -46px;
    opacity: 1;
  }
  75% {
    left: 13px;
  }
  100% {
    left: 13px;
  }
}