@import "../../styles/mixins";

.bottom_disclaimer {
  bottom: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 8000;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
  text-align: center;

  .bd_inner {
    padding: 16px 32px 16px 32px;
    position: relative;
  }

  p {
    font-size: 13px;
    line-height: 19px;
    text-align: left;

    a {
      color: #040E26;
      text-decoration: none;
      border-bottom: 1px solid #040E26;
      white-space: nowrap;
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media screen and (max-width: $treshold) {
    .bd_inner {
      padding: 40px 16px 16px;
    }

    h3 {
      font-size: 19px;
      line-height: 19px;
      margin-bottom: 19px;
    }
    p {
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 19px;
    }
  }
}