@import "../../../../styles/mixins";

@import "../../../../styles/mixins";

.cl_adverts {
  padding: 60px 0 30px;

  .adverts_carousel {
    position: relative;

    .feed_holder {
      overflow: hidden;
      text-align: left;

      &.short {
        .feed {
          justify-content: center;
        }
      }

      .feed {
        display: flex;
        flex-wrap: nowrap;

        @include transition(margin-left ease-out 300ms);

        .feed_item {
          width: 370px;
          flex-shrink: 0;
          margin-right: 30px;
          cursor: pointer;

          &:hover {
            .video_bg {
              //background-color: #fff;

              .w_hub_logo {
                filter: blur(.5px);
              }

              .btn_play {
                svg {
                  @include scale(1.3);
                }
              }
            }
          }

          .video_bg {
            position: relative;
            height: 250px;
            border-radius: 5px;
            background-color: #F5F5F5;
            margin-bottom: 18px;
            overflow: hidden;

            .preview {
              position: relative;
              &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 100;
              }
              .player_v2_wrapper {
                width: 370px;
                height: 250px;

                .react-player__shadow {
                  display: none !important;
                }
              }
            }

            .btn_play {
              @include center-middle;
              z-index: 2;

              svg {
                @include transition(ease-out 150ms);
              }
            }
          }

          h5 {
            font-size: 15px;
            line-height: 20px;
            margin: 0 10px;
          }
        }
      }
    }

    .arr {
      @include middle;
      width: 10px;
      height: 20px;
      overflow: hidden;
      cursor: pointer;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &:before {
        content: '';
        position: absolute;
        top: -6px;
        width: 30px;
        height: 30px;
        border: 1px solid #040E26;
        @include rotate(45deg);
      }

      &.arr_left {
        right: 100%;
        margin-right: 30px;

        &:before {
          left: 7px;
        }
      }

      &.arr_right {
        left: 100%;
        margin-left: 30px;

        &:before {
          right: 7px;
        }
      }
    }

  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0;

    .cl_centrer {
      margin: 0;
    }

    .adverts_carousel {
      .feed_holder {
        &.short {
          .feed {
            justify-content: flex-start;
          }
        }
        .feed {
          .feed_item {
            width: 290px;
            margin-right: 20px;

            &:first-of-type {
              margin-left: 20px;
            }
          }
        }
      }
    }

    .arr {
      display: none;
    }
  }
}