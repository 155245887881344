@import "../../../../styles/mixins";


.cl_faq {
  padding: 68px 0 80px;
  border-bottom: 1px solid #F5F5F5;

  .cl_centrer {
    display: flex;

    h2 {
      width: calc(50% - 130px);
      padding-right: 130px;
    }

    .faq_block {
      width: 50%;

      dl {
        border-top: 1px solid #040E2610;

        &:first-of-type {
          border-top: 0 solid;
        }

        &.opened {
          dd {
            display: block;
          }
        }

        dt {
          position: relative;
          font-size: 22px;
          line-height: 30px;
          font-weight: 500;
          padding: 34px 50px 34px 0;
          cursor: pointer;

          .fold_icon {
            @include middle;
            right: 0;
            height: 20px;

            svg {
              @include middle;
              right: 0;
            }
          }
        }

        dd {
          display: none;
          font-size: 17px;
          line-height: 25px;
          padding-bottom: 40px;
        }
      }
    }
  }

  @include adaptive($adaptive_course_mid) {
    .cl_centrer {
      display: block;

      h2 {
        width: auto;
        padding-right: 0;
        margin-bottom: 40px;
      }

      .faq_block {
        width: auto;
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0 50px;

    .cl_centrer {
      margin: 0;

      h2 {
        margin: 0 20px 31px;
      }

      .faq_block {
        dl {
          padding: 0 20px;

          &:first-of-type {
            border-top: 1px solid #040E2610;
          }

          dt {
            font-size: 17px;
            line-height: 25px;
            padding: 22px 50px 22px 0;
          }
        }
      }
    }
  }
}