@import "../../../../../styles/mixins";

.cl_header {
  .header_rating {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 48px;
    margin-right: 40px;

    .icon_course_rating {
      margin-right: 18px;
      @include scale(1.1);

      path {
        opacity: 1;
        fill: #fff;

        &.filled {
          fill: #FFD500;
        }
      }
    }

    .rating_count {
      display: inline-block;
      vertical-align: top;
      font-size: 18px;
      line-height: 18px;
      padding-top: 2px;
      color: #fff;
    }

    .votes_count {
      display: inline-block;
      vertical-align: top;
      font-size: 13px;
      line-height: 18px;
      padding-top: 2px;
      margin-left: 15px;
      color: #fff;
      opacity: .7;
    }

    @include adaptive($adaptive_course_min) {
      margin-bottom: 35px;

      .icon_course_rating {
        @include scale(1);
      }
    }

    @include adaptive($treshold) {

      & + .header_date_start {
        margin-top: -28px;
      }
    }
  }
}