@import "../../../../styles/mixins";

.cl_statistics {
  position: relative;
  padding: 60px 0 30px;

  .cl_centrer {

    h2 {
      margin-bottom: 32px;
    }

    p {
      margin-bottom: 32px;
    }
  }

  .stat_tabs {
    span {
      display: inline-block;
      margin-right: 16px;
      cursor: pointer;

      &.active {
        font-weight: 500;
        cursor: default;
      }
    }
  }

  .apexcharts-toolbar {
    display: none;
  }
}