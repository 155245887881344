@import "../styles/mixins";

.sign_in_form {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;

  .text_input {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
  }

  .password_recovery_link {
    padding-top: 24px;

    a {
      text-decoration: none;
      color: #434A5C;
      border-bottom: 1px solid rgba(60,30,30,.5);
    }
  }
}