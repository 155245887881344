@import "../styles/mixins";

.course_wrapper {
  padding-top: 70px;

  @include adaptive($treshold_mid) {
    padding-top: 60px;
  }

  .course_default {
    .course_top {
      .bg {
        min-height: 430px;
      }
    }
  }

  .course_top {
    position: relative;
    max-height: 587px;
    overflow: hidden;
    z-index: 10;

    &.book_cover {
      overflow: unset;
      z-index: 30;
    }

    .bg {
      height: 100%;
      position: relative;

      .cover_wrapper {
        background-color: #000;

        img {
          opacity: .6;
        }
      }

      &.book_cover {
        text-align: right;
        height: auto;
        padding: 112px 0 0;
        z-index: 10;

        .cover_wrapper {
          position: relative;
          display: inline-block;
          margin-right: 64px;
          width: 416px;
          height: 416px;

          img {
            opacity: 1;
          }

          .book_cover_cutter {
            width: 100%;
            height: 100%;
            overflow: hidden;
          }

          .btn_play {
            @include center-middle;
            cursor: pointer;
            z-index: 30;
            width: 100%;
            height: 100%;

            svg {
              @include center-middle;
              width: 100px;
              height: 100px;
              path {
                fill: #46C790;
              }
            }
          }

          img {
            width: 100%;
          }
        }

        @media screen and (max-width: $treshold_mid) {
          text-align: center;
          min-height: 0;

          .cover_wrapper {
            @include clear;
            min-height: 0;
            position: relative;
            width: 50%;
            min-width: 300px;
            height: auto;
            margin: 0 auto -5px;

          }
        }

        @media screen and (max-width: $treshold_min) {
          text-align: center;
          min-height: 0;

          .cover_wrapper {
            @include clear;
            min-height: 0;
            position: relative;
            width: 100%;
            height: auto;
            margin: 0 auto -5px;

          }
        }
      }

      .cover_wrapper {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .ct_content {
      position: absolute;
      bottom: 46px;
      left: 64px;
      margin-left: 225px;
      width: 525px;
      color: #fff;
      z-index: 10;

      h6 {
        font-size: 10px;
        line-height: 24px;
        opacity: .75;
        white-space: nowrap;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        display: inline-block;

        a {
          display: inline-block;
          margin-right: 12px;
          color: unset;
          text-decoration: none;
          line-height: 10px;
        }

        span {
          a {
            margin-left: 10px;
          }
        }

        svg {
          @include middle;
          left: 100%;
          margin-top: -2px;
        }
      }

      h1 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }

      .teaser {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
      }
    }
  }

  .course_body {
    position: relative;
    z-index: 20;
    min-height: 780px;
  }

  .course_side_wrapper {
    position: absolute;
    right: 0;
    top: -70px;

    .course_side {
      position: absolute;
      right: 64px;
      top: 134px;
      width: 416px;

      &.fixed {
        @media screen and (min-width: $treshold) {

          .cs_info {
            opacity: 1;
          }
        }
      }

      .buttons_block {
        display: block;

        .discount_disclamer {
          margin-bottom: 16px;
          font-weight: bold;
          text-align: center;
        }

        a {
          text-decoration: none;
          color: unset;
        }

        button, a {
          display: block;
          width: 100%;
          margin-bottom: 16px;

          @media screen and (max-width: $treshold_mid) {
            width: 270px;

            margin: 0 auto 16px;
          }
        }

        a button {
          margin-bottom: 0;
        }

        &.school_lock {
          text-align: center;
          display: block;
        }

        .outofstock_overlay {
          position: absolute;
          z-index: 50;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;

          p {
            text-align: center;
          }
        }
      }
    }

    .cs_info {
      padding-top: 24px;
      opacity: 0;
      @include transition(linear 300ms);

      h6 {
        font-size: 10px;
        line-height: 24px;
        opacity: .75;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        display: inline-block;
        padding-right: 15px;

        a {
          display: inline-block;
          color: unset;
          text-decoration: none;
          margin-right: 12px;
        }

        span {
          a {
            margin-left: 10px;
          }
        }

        svg {
          @include middle;
          left: 100%;
          margin-top: -2px;

          circle {
            stroke: #000;

            &.filled {
              stroke: #000;
              fill: #000;
            }
          }
        }
      }

      h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }

      .course_params {

        dl {
          dd {
            svg path {
              fill: #434A5C;
            }
          }
        }
      }
    }
  }

  .course_text {
    margin: 0 544px 0 64px;
    padding: 64px 0 100px;

    .course_grid_item {
      display: flex;
      align-items: stretch;
      flex-wrap: nowrap;

      h5 {
        width: 182px;
        margin-right: 40px;
        flex-shrink: 0;
        font-size: 16px;
        line-height: 24px;
        color: #434A5C;
        opacity: 0.5;
      }

      .cgi_content {
        position: relative;
        padding-bottom: 48px;
        width: 100%;


        a {
          color: #434A5C;
          text-decoration: none;
          border-bottom: 1px solid #434A5C;
        }
      }

      &:last-of-type {
        .cgi_content {
          padding-bottom: 0;
        }
      }
    }

    .course_description {
      padding-right: 100px;
      color: #434A5C;
      white-space: pre-line;
    }

    .course_content {

      &.cc_generated_lessons {
        ol {
          .ol_item {
            &:nth-of-type(odd) {
              //background-color: #F2F0F0;
            }

            a {
              color: #040E26;
              text-decoration: none;
              //border-bottom: 1px solid #040E26;
            }
          }
        }
      }

      ol {
        list-style-type: decimal;
        margin-left: -32px;
        margin-top: -24px;
        color: #040E26;

        .ol_item {
          position: relative;
          font-size: 24px;
          line-height: 32px;
          width: calc(100% - 100px);
          padding: 18px 100px 14px 32px;

          &.with_subcontent {
            background-color: #F2F0F0;
          }

          &:before {
            content: attr(data-idx);
            display: inline-block;
            vertical-align: top;
            //width: 28px;
            width: 40px;
            margin-left: -40px;
            color: #434A5C;
            opacity: .5;
          }

          &.lecture {
            padding-right: 180px;
            width: calc(100% - 180px);

            .lecture_params {
              position: absolute;
              right: 16px;
              top: 14px;
              text-align: right;
              font-size: 14px;
              line-height: 17px;
            }
          }

          .subcontent {
            ul {
              margin-top: 14px;
              padding: 24px 0;

              li {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 5px;

                &:before {
                  content: '';
                  display: inline-block;
                  vertical-align: top;
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: #040E26;
                  margin-right: 8px;
                  margin-top: 7px;
                }

              }
            }

            p {
              font-size: 16px;
              line-height: 19px;
              margin-bottom: 5px;

            }
          }

        }
      }
    }


    .author_comment {
      padding-right: 100px;
      color: #434A5C;
    }

    .students_feedback {
      padding-right: 100px;

      .feedback_item {
        color: #434A5C;
        margin-bottom: 48px;

        &:last-of-type {
          margin-bottom: 0;
        }

        p {
          margin-bottom: 24px;

          &.fi_respondent {
            text-align: right;
            margin-bottom: 0;
            color: #C0C2C8;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    .course_top {
      height: auto;
      max-height: 2000px;
      padding-bottom: 50px;

      &.book_cover {
        padding-bottom: 0;
      }

      .bg {
        height: 445px;

        .cover_wrapper {
          @include center;
          height: 100%;
          width: auto;

          img {
            height: 100%;
            width: auto;
          }
        }
      }

      .ct_content {
        position: relative;
        left: auto;
        bottom: auto;
        width: auto;
        margin: 0 24px;
        z-index: 0;

        .ct_headers {
          margin-top: -200px;
          height: 200px;
          margin-bottom: 24px;
        }

        &.book_cover {
          .ct_headers {
            height: auto;
            margin-top: 0;
            padding-top: 20px;
            color: #434A5C;
          }
        }

        .course_params {

          dl {
            dd a {

              color: #434A5C;
              border-bottom-color: #434A5C;
            }
          }
        }

        .course_params {
          color: #434A5C;

          dl {

            dd {
              svg path {
                fill: #434A5C;
              }
            }
          }
        }
      }
    }

    .course_body {
      min-height: 0;
    }

    .course_side_wrapper {
      position: relative;
      right: auto;
      top: auto;
      margin: 0 24px 37px;

      .course_side {
        position: relative;
        right: auto;
        top: auto;
        width: auto;

        .buttons_block {
          button {
            width: 49%;
            min-width: 240px;
            white-space: nowrap;
          }

          a {
            width: 49%;
            min-width: 240px;
            position: relative;

            button {
              width: 100%;
            }
          }
        }

        .cs_info {
          display: none;
        }
      }
    }

    .course_text {
      margin: 0 24px;
      padding: 0 0 56px;
      border-top: 1px solid #E6E7EA;

      .course_grid_item {
        padding: 28px 0;
        display: block;

        h5 {
          width: auto;
          margin-bottom: 24px;
        }

        .cgi_content {
          padding-bottom: 0;
        }
      }

      .course_description,
      .author_comment,
      .students_feedback {
        padding-right: 0;
      }

      .course_content {

        ol {
          margin-top: 0;
          margin-left: -24px;

          .ol_item {
            padding: 16px 24px;
            width: calc(100% - 24px);
            font-size: 16px;
            line-height: 24px;
            border-top: 1px solid #E6E7EA;

            ul {
              margin-top: 0;

              li {
                font-size: 10px;
                line-height: 13px;
                margin-bottom: 3px;

                &:before {
                  width: 3px;
                  height: 3px;
                  margin-right: 6px;
                  margin-top: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}