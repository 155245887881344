@import "../styles/mixins";

.lesson_wrapper {
  .unauthed_banner {
    position: relative;
    background-color: #040E26;
    color: #fff;
    text-align: center;
    padding: 24px 80px;
    font-size: 13px;
    line-height: 19px;

    span {
      cursor: pointer;
      border-bottom: 1px solid rgba(255,255,255,.5);
    }

    ins {
      @include middle;
      right: 24px;
      cursor: pointer;

      svg {
        rect {
          fill: #fff;
        }
      }
    }
  }

  .lesson_dashboard {
    position: relative;
    padding-left: 360px;


    .lesson_overlay {
      position: fixed;
      z-index: 500;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #00000030;
      display: none;
    }
  }

  @media screen and (max-width: $adaptive_course_mid) {
    .lesson_sidebar {
      left: -360px;
      z-index: 600;
      @include transition(left ease-out 150ms);
    }

    .lesson_dashboard {
      padding-left: 0;
    }

    &.sidebar_opened {
      .lesson_sidebar {
        left: 0;
      }

      .lesson_dashboard {

      }
      .lesson_overlay {
        display: block;
      }
    }
  }

  @media screen and (max-width: $treshold) {
    .lesson_sidebar {
      left: -100%;
    }
  }
}