@import "../../styles/mixins";

.error_404_wrapper {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 100px;

  img {
    display: inline-block;
    margin-bottom: 24px;
  }

  h1 {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 48px;

    span {
      display: inline-block;
      position: relative;

      &:after {
        content: '';
        @include center;
        top: 100%;
        margin-top: 24px;
        width: 192px;
        height: 1px;
        background-color: #C0C2C8;
      }

      ins {
        position: absolute;
        left: 100%;
        top: -12px;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
        color: #C0C2C8;
        margin-left: 10px;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;

    a {
      color: #434A5C;
    }
  }
}