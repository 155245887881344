@import "../../styles/mixins";

.lesson_sidebar {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 100vh;
  width: 360px;
  border-right: 1px solid #E8E8E8;
  overflow: auto;
  background-color: #fff;

  .sidebar_tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 30px 30px;

    .go_back {
      display: inline-block;
      position: relative;
      padding-left: 20px;
      font-size: 15px;
      line-height: 15px;
      opacity: .7;

      a {
        color: #040E26;
      }

      svg {
        @include middle;
        left: 0;
      }
    }
  }

  .lessons_list {
    .go_back {
      display: none;
    }
    a {
      position: relative;
      display: block;
      padding: 22px 70px 17px 60px;
      font-size: 17px;
      line-height: 25px;
      font-weight: 400;
      color: unset;

      &.current {
        background-color: #040E2606;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 5px;
          background-color: #040E26;
        }
      }

      .date_start {
        display: block;
        padding-top: 10px;
        font-size: 15px;
        line-height: 20px;
        opacity: .7;
      }

      .type_icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 20px;
        left: 30px;

        svg {
          width: 100%;
        }
      }

      .status_icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 20px;
        right: 30px;

        svg {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    width: 100%;
  }
}