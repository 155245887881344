@import "../styles/mixins";

.featured_feed_holder {
  position: relative;
  //background-color: #F2F3F4;
  padding: 32px 0;

  @media screen and (max-width: $treshold) {
    padding-top: 0;
  }
}
.featured_feed_outer {
  position: relative;
  max-width: 1632px;
  margin: 0 auto;

  @media screen and (max-width: 1700px) {
    max-width: 1088px;
  }

  @media screen and (max-width: 1160px) {
    max-width: 544px;
  }

  .arr {
    position: absolute;
    z-index: 5;
    top: 0;
    height: 100%;
    width: 64px;
    opacity: .8;
    cursor: pointer;

    i {
      @include center-middle;
      width: 16px;
      height: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.hidden {
      display: none;
    }

    &.arr_prev {
      right: 100%;
    }

    &.arr_next {
      left: 100%;

      i {
        svg {
          @include transform(rotate(180deg));
        }
      }
    }
  }

  .featured_steps {
    text-align: center;
    padding-top: 32px;
    white-space: nowrap;

    i {
      display: inline-block;
      vertical-align: top;
      margin: 0 8px;
      width: 6px;
      height: 6px;
      border: 1px solid #040E26;
      border-radius: 50%;
      cursor: pointer;

      &.active {
        cursor: default;
        background-color: #040E26;
      }
    }
  }

  @media screen and (max-width: $treshold) {
    width: 100vw;

    .arr {
      width: 32px;
      height: 32px;
      background-color: #000;
      border-radius: 50%;
      top: 168px;

      svg path {
        fill: #fff;
      }

      &.arr_prev {
        right: auto;
        left: 16px;
      }

      &.arr_next {
        left: auto;
        right: 16px;
      }
    }
  }
}

.featured_feed_wrapper {
  overflow: hidden;
  position: relative;
  height: 366px;

  @media screen and (max-width: $treshold) {
    height: 400px;
  }
}

.featured_feed_block {
  position: absolute;
  top: 0;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
  @include transition(left ease-out 300ms);

  @media screen and (max-width: $treshold) {

    .course_featured_item {
      width: 100vw !important;
      margin: 0 !important;
    }

    a {
      width: 100%;
      margin-bottom: 48px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}