@import "../../styles/mixins";

.edit_course_price_widget {
  margin: 0 auto 32px;
  width: 600px;

  .free_course_toggler_wrapper {
    margin-bottom: 48px;
  }

  .min_price_error {
    color: #C33333;
  }

  .price_disclamer {
    font-size: 13px;
    line-height: 19px;
    padding-top: 32px;
    text-align: left;
    margin-left: 176px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  dl {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;

    &.z_up {
      z-index: 2;
    }

    &.focused {
      z-index: 10;
    }

    dt {
      flex-shrink: 0;
      width: 160px;
      padding-right: 16px;
      text-align: right;
      margin-top: 8px;

      &.eli_dt_video_label {
        margin-top: 6px;
      }
    }

    dd {
      width: 100%;
      display: flex;
      align-items: stretch;

      input {
        display: block;
        width: calc(100% - 18px);
        padding: 4px 8px;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #C0C2C8;
        color: #434A5C;

        @include input-placeholder {
          color: #C0C2C8;
        }

        &:disabled {
          background-color: transparent;
          opacity: .4;
        }
      }

      button {
        flex-shrink: 0;
      }
    }
  }
}
