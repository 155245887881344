@import "../../styles/mixins";

.lesson_content {
  .lesson_webinar_wrapper {

    .lecturer_and_date_section {
      display: flex;
      margin-bottom: 32px;
      font-size: 15px;
      line-height: 20px;

      .lecturer {
        position: relative;
        padding-left: 50px;
        margin-right: 32px;

        .userpic {
          @include middle;
          left: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #040E26;

          span {
            @include center-middle;
            text-transform: uppercase;
            font-weight: 500;
            color: #fff;
          }
        }
      }

      .date {
        position: relative;
        padding-left: 30px;

        svg {
          @include middle;
          left: 0;
        }

        span {
          opacity: .7;
        }
      }
    }

    .platform_section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #E8E8E8;
      padding: 30px 50px 30px 30px;

      .broadcast_icon {
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: #fff;
        position: relative;
        margin-right: 30px;

        .live_now {
          position: absolute;
          z-index: 100;
          left: 22px;
          top: 29px;
          border: 2px solid #FFFFFF;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #6AB261;
        }

        svg {
          @include center-middle;
        }
      }

      p {
        width: 100%;
        font-size: 16px;
        line-height: 25px;
      }

      .btn_holder {
        flex-shrink: 0;
      }
    }

    @media screen and (max-width: $treshold) {
      .lecturer_and_date_section {
        display: block;

        .lecturer {
          .userpic {
            margin-top: 10px;
          }
        }

        .date {
          padding-left: 50px;
          margin-top: 2px;
          svg {
            display: none;
          }
        }
      }

      .platform_section {
        width: calc(100vw - 40px);
        padding: 20px;
        margin-left: -20px;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;

        .broadcast_icon {
          margin-bottom: 24px;
        }

        p {
          margin-bottom: 26px;
        }

        .btn_holder {
          width: 100%;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}
