@import "../../styles/mixins";

.upload_progress_widget {
  position: fixed;
  bottom: 8px;
  left: 8px;
  padding: 16px;
  border-radius: 3px;
  background-color: #434A5C;
  color: #fff;
  width: 200px;
  font-size: 13px;

  h3 {
    font-weight: bold;
    margin-bottom: 8px;
  }

  ul {
    li {
      position: relative;
      padding-right: 32px;
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(255,255,255,.5);

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0 solid;
      }

      span {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: bold;
      }
    }
  }
}