@import "../../../styles/mixins";

.ls_payments_wrapper {
  padding-bottom: 135px;
  padding-top: 48px;
  @include gradient(#F8F8FA, #fff);

  p {
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    margin-bottom: 48px;

    span {
      color: #F90353;
    }
  }

  .plates {
    li {
      display: inline-block;
      vertical-align: top;
      width: 142px;
      height: 80px;
      margin-right: 30px;
      margin-bottom: 48px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      box-shadow: 0 10px 30px #EDEFF2;


      img {
        @include center-middle;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding-bottom: 80px;
  }
  @media screen and (max-width: $treshold) {
    height: auto;
    padding-bottom: 200px;

    p {
      padding-top: 30px;
      margin-bottom: 27px;
    }

    .plates {
      width: 100%;
      margin-left: -25px;
      padding: 10px 25px 0;
      margin-bottom: 0;
      overflow: auto;
      white-space: nowrap;
    }
  }
}