@import "../../../../styles/mixins";

.cl_content {
  position: relative;
  padding: 68px 0 92px;

  .content_header_section {
    display: flex;
    margin-bottom: 50px;

    .chs_side {
      width: 50%;
      flex-shrink: 0;
    }
  }

  .cl_anchor {
    position: absolute;
    top: -70px;
  }

  .certificate {
    display: none;
    position: relative;
    padding-left: 55px;
    font-size: 15px;
    line-height: 15px;

    svg {
      @include middle;
      left: 0;
      width: 40px;
      height: 40px;
    }
  }

  .chs_duration {
    ul {
      li {
        display: inline-block;
        margin-right: 30px;
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 20px;

        span {
          font-weight: 600;
          display: inline-block;
          padding: 8px 15px;
          margin-right: 10px;
          color: #fff;
          border-radius: 20px;
          background-color: #040E26;
        }
      }
    }
  }

  &.folded {
    .content_list {
      .lesson_item:nth-child(n+4) {
        display: none;
      }
    }
  }

  .content_list {
    .lesson_item {
      position: relative;
      border: 1px solid #040E2610;
      border-radius: 5px;
      margin-bottom: 20px;
      @include transition(linear 150ms);

      &.opened {
        border-color: #fff;

        .header {
          .title {

          }
        }

        .description_wrapper {
          display: block;
        }
      }

      .header {
        position: relative;
        padding: 34px 100px 34px 90px;

        .type_icon {
          position: absolute;
          top: 29px;
          left: 30px;
          font-size: 0;
        }

        .title {
          font-size: 22px;
          line-height: 22px;
          font-weight: 500;
        }

        .fold_icon {
          @include middle;
          right: 35px;
          font-size: 0;
          cursor: pointer;
        }
      }

      .description_wrapper {
        overflow: hidden;
        display: none;

        .description {
          padding: 3px 100px 36px 90px;
          font-size: 17px;
          line-height: 25px;

          p {
            font-size: 17px;
            line-height: 25px;
            margin-bottom: 25px;

            &.lesson_duration,
            &.lesson_lecturer,
            &.lesson_starts {
              font-size: 15px;
              line-height: 15px;
            }

            &.lesson_duration {
              position: relative;
              padding-left: 25px;

              svg {
                position: absolute;
                left: 0;
                top: -1px;
              }
            }
          }

          .description_inner {

          }

          .btn_holder {
            margin-top: 25px;
          }
        }
      }
    }
  }

  .switcher_centrer {
    text-align: center;

    .fold_switcher {
      position: relative;
      display: inline-block;
      margin-top: 55px;
      padding-right: 10px;
      font-size: 15px;
      line-height: 15px;
      font-weight: 500;
      cursor: pointer;

      svg {
        @include middle;
        left: 100%;
        margin-top: 1px;
      }
    }
  }

  @include adaptive($adaptive_course_mid) {
    .content_header_section {
      display: block;

      .chs_side {
        width: auto;
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0 47px;

    .content_header_section {
      margin: 0 20px 30px;
    }

    .chs_duration {
      ul {
        li {
          margin-right: 20px;
          margin-bottom: 10px;

          span {

          }
        }
      }
    }

    .cl_anchor {
      top: -60px;
    }

    .cl_centrer {
      margin: 0;

      h2 {
        margin: 0 0 28px;
      }

      .certificate {
        margin: 0 0 30px;
        font-size: 15px;
        line-height: 20px;
      }
    }

    .content_list {
      .lesson_item {
        border-radius: 0;
        border-left: 0 solid;
        border-right: 0 solid;

        &.opened {
          border-color: #040E2610;
        }

        .header {
          padding: 27px 70px;

          .type_icon {
            left: 20px;
          }

          .title {
            font-size: 17px;
            line-height: 25px;
          }

          .fold_icon {
            right: 20px;
          }
        }

        .description_wrapper {
          .description {
            padding: 2px 20px 27px;
          }
        }
      }
    }
  }
}