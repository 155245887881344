@import "../../styles/mixins";

.webinar_create_wrapper {
  max-width: 1088px;
  margin: 0 auto;
  padding-top: 45px;

  .cc_top {
    text-align: center;

    h1 {
      font-size: 24px;
      line-height: 32px;
      color: #434A5C;
      margin-bottom: 4px;
    }

    p {
      padding-top: 30px;

      a {
        color: #434A5C;
      }
    }
  }
}