@import "../../../../styles/mixins";

.ls_how_it_works_wrapper {
  height: 855px;
  position: relative;
  background-color: #2450FF;

  .l_content {
    color: #fff;

    h2 {
      padding-top: 114px;
      margin-bottom: 130px;
    }

    .plates {
      margin-bottom: 122px;
      white-space: nowrap;

      li {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 248px;

        &:last-of-type {
          width: 180px;

          .img_wrapper {
            background-color: #0DA750;
            box-shadow: 0 10px 10px #0000001A;
            border: 0 solid;

            .num {
              span {
                color: #0DA750;
              }
            }
          }
        }

        .img_wrapper {
          position: relative;
          background-color: #2450FF;
          width: 100px;
          height: 100px;
          box-shadow: 15px 15px 30px #00000010;
          border: 1px solid #2478FF;
          border-radius: 20px;
          margin-bottom: 35px;

          img {
            @include center-middle;
          }

          .num {
            position: absolute;
            width: 30px;
            height: 30px;
            left: -10px;
            top: -10px;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 10px 10px #0000000D;

            span {
              @include center-middle;
              color: #2450FF;
              font-size: 12px;
              line-height: 20px;
              font-weight: 600;
            }
          }

          .dots {
            position: absolute;
            white-space: nowrap;
            height: 4px;
            left: 100%;
            margin-left: 46px;
            top: 40px;
            font-size: 0;

            i {
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              margin-right: 10px;
              background-color: #2478FF;
            }
          }
        }

        p {
          width: 180px;
          font-size: 18px;
          line-height: 25px;
          white-space: normal;
        }
      }
    }

    .download_manual {
      a {
        display: inline-block;
        position: relative;
        padding-left: 80px;
        padding-top: 8px;
        text-decoration: none;

        img {
          position: absolute;
          left: -30px;
          top: -30px;
        }

        p {
          font-size: 18px;
          line-height: 25px;
          color: #fff;
          margin-bottom: 6px;
        }

        span {
          font-size: 14px;
          line-height: 25px;
          color: #64D3FF;
          border-bottom: 1px solid #64D3FF50;
        }
      }
    }
  }

  .ls_bg {
    position: absolute;
    z-index: 0;
    bottom: 0;
    height: 400px;
    width: 100%;

    @include gradient(rgba(36, 80, 255, 0), #4114BC);
  }

  @media screen and (max-width: $treshold_max) {
    .l_content {
      .plates {
        li {
          width: 20%;

          p {
            width: 150px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    .l_content {
      .plates {
        width: 100%;
        margin-left: -50px;
        padding: 10px 50px 20px;
        overflow: auto;

        li {
          .img_wrapper {
            .dots {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    height: auto;
    padding-bottom: 50px;

    .l_content {

      h2 {
        padding-top: 50px;
        margin-bottom: 55px;
      }

      .plates {
        margin-left: -25px;
        margin-bottom: 25px;
        padding: 10px 25px 20px;
      }
    }
  }
}