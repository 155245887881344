.school_experts {
  margin: 0 24px;
  padding-bottom: 80px;

  h1 {
    font-size: 21px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 18px;
  }

  p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 18px;
  }

  .experts_list {
    max-width: 700px;

    li {
      background-color: #fff;
      padding: 18px;
      border-radius: 4px;
      margin-bottom: 24px;

      dl {
        font-size: 14px;
        line-height: 19px;
        display: flex;
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }

        dt {
          width: 100px;
          flex-shrink: 0;
          text-align: right;
          margin-right: 16px;
          font-weight: bold;
        }
      }
    }
  }
}