@import "../../styles/mixins";

.breadcrumbs {
  position: absolute;
  z-index: 200;
  white-space: nowrap;
  color: #fff;
  left: 30px;
  top: 95px;
  font-size: 13px;
  line-height: 13px;

  .course_landing_wrapper & {
    left: auto;
    width: 1170px;
    @include center;

    @media screen and (max-width: $treshold_mid) {
      @include clear;
      top: 85px;
      left: 50px;
    }

    @include adaptive($adaptive_course_min) {
      left: 20px;
    }
  }

  li {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;

    span {
      display: inline-block;
      margin-right: 8px;
    }

    a, span {
      color: unset;
      opacity: .7;
      text-decoration: none;
    }

    a:hover {
      opacity: 1;
    }
  }

  &.lesson {
    color: #040E26;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 20px;
  }

  &.category {
    color: #040E26;
  }

  @media screen and (max-width: $treshold) {
    display: none;
  }

}