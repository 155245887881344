.lp_form_wrapper {
  background-color: #F2F6FC;
  padding: 150px 0; }
  .lp_form_wrapper .form_outer {
    background-color: #fff;
    box-shadow: 20px 20px 40px #0016520D;
    border: 1px solid #F2F2F5;
    border-radius: 20px;
    overflow: hidden;
    display: flex; }
    .lp_form_wrapper .form_outer.sent {
      width: 595px;
      height: 745px;
      margin: 0 auto; }
      .lp_form_wrapper .form_outer.sent .form_inner {
        display: none; }
      .lp_form_wrapper .form_outer.sent .form_part_left {
        width: 100%; }
        .lp_form_wrapper .form_outer.sent .form_part_left .form_description {
          display: none; }
        .lp_form_wrapper .form_outer.sent .form_part_left .sent_message {
          display: block; }
    .lp_form_wrapper .form_outer .form_part_left {
      flex-shrink: 0;
      position: relative;
      width: 50%;
      background-color: #2450FF;
      color: #fff; }
      .lp_form_wrapper .form_outer .form_part_left .sent_message {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 345px;
        text-align: center; }
        .lp_form_wrapper .form_outer .form_part_left .sent_message h2 {
          padding-bottom: 35px; }
      .lp_form_wrapper .form_outer .form_part_left:after {
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: rgba(36, 80, 255, 0);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(36, 80, 255, 0)), color-stop(100%, #4114BC));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* IE10+ */
        background: linear-gradient(to bottom, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
        /* IE6-9 */ }
      .lp_form_wrapper .form_outer .form_part_left .form_description {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 345px; }
      .lp_form_wrapper .form_outer .form_part_left h2 {
        font-size: 24px;
        line-height: 35px;
        font-weight: 500;
        padding-bottom: 64px;
        margin-bottom: 35px;
        border-bottom: 1px solid #2478FF; }
      .lp_form_wrapper .form_outer .form_part_left h3 {
        font-size: 18px;
        line-height: 25px; }
    .lp_form_wrapper .form_outer .form_inner {
      width: 50%;
      padding: 55px 60px 60px 75px; }
      .lp_form_wrapper .form_outer .form_inner form .lp_input_wrapper {
        margin-bottom: 42px; }
        .lp_form_wrapper .form_outer .form_inner form .lp_input_wrapper:last-of-type {
          margin-bottom: 47px; }
        .lp_form_wrapper .form_outer .form_inner form .lp_input_wrapper .note {
          padding-top: 9px;
          font-size: 14px;
          line-height: 20px; }
      .lp_form_wrapper .form_outer .form_inner form label {
        display: block;
        background-color: #fff;
        border: 1px solid #E3E3E6;
        border-radius: 10px;
        padding: 10px 20px; }
      .lp_form_wrapper .form_outer .form_inner form span {
        font-size: 12px;
        line-height: 25px;
        color: #050307; }
      .lp_form_wrapper .form_outer .form_inner form input,
      .lp_form_wrapper .form_outer .form_inner form textarea {
        display: block;
        width: 100%;
        padding: 0;
        background-color: transparent;
        border: 0 solid;
        font-size: 18px;
        line-height: 25px; }
      .lp_form_wrapper .form_outer .form_inner form textarea {
        min-height: 80px; }
      .lp_form_wrapper .form_outer .form_inner form .lp_checkbox_switcher {
        position: relative;
        padding-left: 50px; }
        .lp_form_wrapper .form_outer .form_inner form .lp_checkbox_switcher.checked .lp_checkbox:after {
          display: block; }
        .lp_form_wrapper .form_outer .form_inner form .lp_checkbox_switcher .lp_checkbox {
          position: absolute;
          left: 0;
          top: 0;
          width: 28px;
          height: 28px;
          border: 1px solid #CACACC;
          border-radius: 10px; }
          .lp_form_wrapper .form_outer .form_inner form .lp_checkbox_switcher .lp_checkbox:after {
            display: none;
            content: '';
            height: 8px;
            width: 12px;
            position: absolute;
            left: 7px;
            top: 7px;
            border-left: 1px solid #050307;
            border-bottom: 1px solid #050307;
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg); }
        .lp_form_wrapper .form_outer .form_inner form .lp_checkbox_switcher .lp_checkbox_label {
          font-size: 14px;
          line-height: 20px;
          color: #050307; }
          .lp_form_wrapper .form_outer .form_inner form .lp_checkbox_switcher .lp_checkbox_label a {
            text-decoration: none;
            color: #2450FF;
            border-bottom: 1px solid #2450FF50; }
  @media screen and (max-width: 1050px) {
    .lp_form_wrapper {
      padding: 75px 0; }
      .lp_form_wrapper .form_outer {
        display: block; }
        .lp_form_wrapper .form_outer .form_part_left {
          width: auto; }
          .lp_form_wrapper .form_outer .form_part_left .form_description {
            width: auto;
            position: absolute;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            -moz-transform: translate(0, 0);
            -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            position: relative;
            padding: 75px 50px; }
        .lp_form_wrapper .form_outer .form_inner {
          width: auto;
          padding: 50px; }
          .lp_form_wrapper .form_outer .form_inner form textarea {
            height: 110px; } }
  @media screen and (max-width: 600px) {
    .lp_form_wrapper {
      padding: 0; }
      .lp_form_wrapper .l_centrer {
        margin: 0; }
      .lp_form_wrapper .form_outer {
        padding: 0;
        width: 100%;
        border-radius: 0; }
        .lp_form_wrapper .form_outer .form_part_left .form_description,
        .lp_form_wrapper .form_outer .form_part_left .sent_message {
          padding: 45px 25px 45px; }
          .lp_form_wrapper .form_outer .form_part_left .form_description h2,
          .lp_form_wrapper .form_outer .form_part_left .sent_message h2 {
            font-size: 18px;
            line-height: 25px;
            padding-bottom: 35px;
            margin-bottom: 25px; }
          .lp_form_wrapper .form_outer .form_part_left .form_description h3,
          .lp_form_wrapper .form_outer .form_part_left .sent_message h3 {
            font-size: 14px;
            line-height: 20px; }
        .lp_form_wrapper .form_outer .form_inner {
          padding: 45px 25px; } }
  .lp_form_wrapper .button_holder {
    text-align: center; }

