@import "../../styles/mixins";

.pdf_viewer {
  .container {
    > div {
      > div {
        height: auto !important;

        > div {
          > div {
            > div {
              height: auto !important;

              canvas {
                max-width: 100%;

                @media screen and (max-width: $treshold) {
                  max-width: 100vw;
                }
              }

            }
          }
        }
      }
    }

    .columns.is-mobile > .column.is-2 {
      width: 25%;

      &.is-right {
        text-align: right;
      }
    }
    .columns.is-mobile > .column.is-3 {
      width: 40%;
      text-align: center;
      white-space: nowrap;
    }

    .has-margin-bottom-15 {
      margin-bottom: 0 !important;
    }

    .button {
      width: unset;
      height: unset;
    }

  }
}