@import "../../styles/mixins";

.company_type_select {
  margin-bottom: 48px;

  .cts_list {
    display: flex;
    justify-content: center;
    align-items: stretch;
    white-space: normal;
    flex-wrap: wrap;
    width: 870px;
    margin: 0 auto;

    @media screen and (max-width: $treshold_mid) {
      width: auto;
    }

    .radio_wrapper {
      flex-shrink: 0;
      width: 400px;
      margin: 0 16px;
      white-space: normal;

      &.simple {
        width: auto;

        label {
          padding-left: 60px;

          .point {
            left: 28px;
          }
        }

        input:checked + label {
          background-color: #fff;
        }

        &:hover {
          label {
            background-color: #fff;
          }
        }
      }
    }
  }

  .radio_wrapper {
    position: relative;

    label {
      position: relative;
      display: block;
      padding: 48px 48px 40px 80px;
      text-align: left;
      cursor: pointer;
      height: calc(100% - 98px);

      &:hover {
        background-color: #F2F0F0;

        p {
          color: #434A5C;
        }
      }

      .point {
        position: absolute;
        left: 48px;
        top: 58px;
        background-color: #fff;
        border: 1px solid #C0C2C8;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      h4 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        color: #C0C2C8;
      }
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 0;
      height: 0;
      left: -100px;
      top: -100px;

      &:checked + label {
        background-color: #F2F0F0;

        .point {
          &:before {
            content: '';
            @include center-middle;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background-color: #040E26;
          }
        }

        p {
          color: #434A5C;
        }
      }
    }

  }

  .button_holder {
    text-align: center;
  }
}