@import "../../styles/mixins";

.main_top_screen {
  position: relative;
  height: 500px;
  max-width: 1055px;
  margin: 0 auto;

  .mts_bg {
    width: 1157px;
    height: 426px;
    @include center;
    z-index: 0;
    bottom: -36px;
    margin-left: -70px;
    @include retina-background-image('./images/bg-wh-desktop', 'png', 1157px, 426px);
  }

  .mts_text {
    position: relative;
    z-index: 10;
    padding-top: 100px;

    h1 {
      font-size: 40px;
      line-height: 45px;
      text-align: center;
      margin-bottom: 30px;

      strong {
        font-weight: 400;

        &:after {
          content: ' — '
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      width: 512px;
      text-align: center;
      margin: 0 auto;
    }

    .w_mobile, .h_mobile {
      display: none;
    }
  }

  .mts_search {
    position: absolute;
    left: -2px;
    bottom: 0;
    width: 100%;
    z-index: 20;

    &.focused {
      .search_button {
        right: 0%;
        margin-right: 16px;
      }

      .search_input {
        background-color: #fff;
        box-shadow: inset 0 0 0 2px #434A5C;
      }
    }

    .search_input {
      border: 0 solid;
      background-color: #ECECEC80;
      border-radius: 8px;
      font-size: 24px;
      line-height: 48px;
      height: 48px;
      text-align: center;
      width: 100%;
      padding: 0;
      @include transition(ease-out 200ms);
    }

    .search_button {
      @include middle;
      right: 50%;
      margin-right: -155px;
      margin-top: 2px;
      cursor: pointer;
      @include transition(ease-out 200ms);
    }
  }

  @media screen and (max-width: 1000px) {
    height: auto;
    margin: 0 50px;

    .mts_text {
      padding-bottom: 350px;
      width: 560px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 620px) {
    margin: 0 20px 20px;

    .mts_bg {
      display: none;
    }

    .mts_text {
      width: auto;
      padding-top: 90px;
      padding-bottom: 48px;
      left: 0;

      h1 {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        width: auto;
      }

      .w_mobile {
        display: block;
        width: 100%;
        height: 211px;
        @include retina-background-image('./images/w_mobile', 'png', 375px, 211px);
        background-position: 50% 0;
      }

      .h_mobile {
        display: block;
        width: 100%;
        height: 199px;
        @include retina-background-image('./images/h_mobile', 'png', 375px, 199px);
        background-position: 50% 0;

      }
    }

    .mts_search {
      .search_input {
        font-size: 18px;
      }
      .search_button {
        right: 0%;
        margin-right: 16px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .mts_text {

      h1 {
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 24px;

        strong {
          font-weight: 500;
          display: block;

          &:after {
            content: '';
          }
        }
      }

      p {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 19px;
        width: auto;
      }
    }
  }
}