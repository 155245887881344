.ls_payments_wrapper {
  padding-bottom: 135px;
  padding-top: 48px;
  background: #F8F8FA;
  /* Old browsers */
  background: -moz-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F8F8FA), color-stop(100%, #fff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #F8F8FA 0%, #fff 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
  /* IE6-9 */ }
  .ls_payments_wrapper p {
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    margin-bottom: 48px; }
    .ls_payments_wrapper p span {
      color: #F90353; }
  .ls_payments_wrapper .plates li {
    display: inline-block;
    vertical-align: top;
    width: 142px;
    height: 80px;
    margin-right: 30px;
    margin-bottom: 48px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 10px 30px #EDEFF2; }
    .ls_payments_wrapper .plates li img {
      position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  @media screen and (max-width: 1100px) {
    .ls_payments_wrapper {
      padding-bottom: 80px; } }
  @media screen and (max-width: 600px) {
    .ls_payments_wrapper {
      height: auto;
      padding-bottom: 200px; }
      .ls_payments_wrapper p {
        padding-top: 30px;
        margin-bottom: 27px; }
      .ls_payments_wrapper .plates {
        width: 100%;
        margin-left: -25px;
        padding: 10px 25px 0;
        margin-bottom: 0;
        overflow: auto;
        white-space: nowrap; } }

