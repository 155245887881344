@import "../../../../styles/mixins";

.lp_special_wrapper {
  padding: 114px 0 147px;
  overflow: hidden;
  position: relative;

  .lp_bg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 635px;
    @include gradient(#F8F8FA, #fff);
  }

  .l_content {
    text-align: center;

    h2 {
      margin-bottom: 46px;
    }

    .special_description {
      margin: 0 auto 67px;
      font-size: 18px;
      line-height: 25px;
      max-width: 570px;
    }

    .plate {
      position: relative;
      margin: 0 auto 40px;
      padding: 60px 40px;
      width: 490px;
      border: 1px solid #F2F2F5;
      border-radius: 20px;
      @include gradient(#fff, #F8F8FA);

      .bubble_left {
        @include middle;
        right: 100%;
        margin-right: 1px;
      }

      .bubble_right {
        @include middle;
        left: 100%;
        margin-left: 1px;
        margin-top: -40px;
      }

      .plan_name {
        color: #6DCEF8;
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 19px;
      }

      .plan_old_price {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 12px;
        text-decoration: line-through;
        opacity: .5;
      }

      .plan_price {
        font-size: 45px;
        line-height: 45px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .plan_period {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 36px;
      }

      .plan_options {
        margin: 0 auto;
        border-top: 1px solid #E6E6E6;
        white-space: normal;
        text-align: left;
        padding-top: 45px;
        margin-bottom: 57px;

        li {
          position: relative;
          padding-left: 28px;
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 25px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .tick {
            position: absolute;
            left: 6px;
            top: 9px;
            @include transform(rotate(-45deg));

            &:before {
              content: '';
              height: 7px;
              width: 11px;
              border-left: 1px solid #0DA750;
              border-bottom: 1px solid #0DA750;
              @include center-middle;
            }
          }
        }
      }
    }

    .stars {
      max-width: 570px;
      margin: 0 auto;

      p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        color: #444750;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding: 50px 0 55px;

    .l_content {
      h2 {
        margin-bottom: 34px;
      }

      .special_description {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 47px;
      }

      .plate {
        width: auto;

        .plan_name {
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 26px;
        }

        .plan_old_price {
          font-size: 14px;
          line-height: 14px;
          margin-bottom: 8px;
        }

        .plan_price {
          font-size: 30px;
          line-height: 30px;
          margin-bottom: 8px;
        }

        .plan_period {
          font-size: 14px;
          line-height: 14px;
          margin-bottom: 36px;

        }

        .plan_options {
          padding-top: 29px;
          li {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;
            padding-left: 19px;

            .tick {
              top: 8px;

              &:before {
                height: 5px;
                width: 8px;
              }
            }
          }
        }
      }

      .stars {
        p {
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }
}