@import "../../../../styles/mixins";

.lp_welcome_wrapper {
  min-height: 710px;
  position: relative;
  background-color: #FAFCFF;
  overflow: hidden;

  .lp_bg {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .girl {
      position: absolute;
      z-index: 5;
      right: 135px;
      bottom: 0;
      height: 690px;
      width: 570px;

      .pic_ellipse {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
      }

      .pic_girl {
        @include center;
        z-index: 1;
        bottom: 0;
      }
    }

    .ellipse_1 {
      position: absolute;
      z-index: 1;
      width: 120px;
      height: 120px;
      left: -60px;
      bottom: 130px;
    }

    .ellipse_2 {
      position: absolute;
      z-index: 1;
      width: 249px;
      height: 126px;
      right: -124px;
      bottom: 0;

      img {
        width: 100%;
      }
    }

  }

  .lp_welcome_text {
    padding-top: 100px;
    width: 630px;

    h1 {
      font-size: 55px;
      line-height: 65px;
      font-weight: 600;
      margin-bottom: 40px;
    }

    p {
      font-size: 24px;
      line-height: 35px;
      width: 570px;
    }
  }


  @media screen and (max-width: $treshold_mid) {
    height: 750px;

    .lp_bg {
      .girl {
        width: 490px;
        height: 590px;
        right: 100px;

        .pic_girl {
          height: 530px;
        }
      }
    }

    .lp_welcome_text {
      padding-top: 30px;
      width: 70%;

      p {
        width: 400px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    height: auto;

    .lp_bg {
      position: relative;
      bottom: auto;
      left: auto;
      margin-bottom: -20px;

      .girl {
        position: relative;
        width: 100%;
        text-align: center;
        max-width: 400px;
        height: auto;
        margin: 0 auto;
        right: auto;

        .pic_girl {
          @include clear;
          position: relative;
          display: inline-block;
          margin-top: 32px;

        }
      }
    }

    .lp_welcome_text {
      width: 100%;

      p {
        width: auto;
      }
    }
  }

  @media screen and (max-width: $treshold) {
    .lp_welcome_text {
      margin-bottom: 30px;

      h1 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 19px;
      }

      p {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  .lp_bg {
    .girl {
      .pic_girl {
        height: auto;
        width: 80%;
      }
    }
  }
}