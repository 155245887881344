@import "../../styles/mixins";

.header_categories_wrapper {
  position: relative;
  width: 174px;
  height: 69px;
  border-right: 1px solid #E8E8E8;

  &.opened {
    background-color: #E8E8E8;

    .categories_list_wrapper {
      display: flex;
    }
  }

  .hc_button {
    position: relative;
    width: 174px;
    height: 69px;
    cursor: pointer;

    svg {
      @include middle;
      left: 30px;
    }

    span {
      @include middle;
      left: 60px;
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase;
    }
  }

  .categories_list_wrapper {
    display: none;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 1px;
    box-shadow: 5px 6px 10px #00000029;
    flex-wrap: nowrap;
  }

  ul {
    width: 240px;
    height: calc(100vh - 70px);
    overflow: auto;
    flex-shrink: 0;

    &.child_list {
      border-left: 1px solid #F5F5F5;
    }

    li {
      font-size: 15px;
      line-height: 15px;
      display: flex;
      flex-wrap: nowrap;

      &:hover, &.hovered {
        a {
          background-color: #E8E8E8;
        }
      }

      a {
        position: relative;
        color: unset;
        text-decoration: none;
        display: block;
        width: 150px;
        white-space: nowrap;
        padding: 15px 60px 15px 30px;
        flex-shrink: 0;
        flex-grow: 0;

        svg {
          @include middle;
          right: 30px;
          z-index: 0;

          .a {
            fill:none;
            stroke:#040e26;
            opacity:0.7;
          }
        }
      }

    }
  }

  @media screen and (max-width: $treshold_mid) {
    border-right: 0 solid;
    height: 59px;
    width: 60px;

    .hc_button {
      width: 60px;
      height: 59px;

      svg {
        left: auto;
        @include center-middle;
      }

      span {
        display: none;
      }
    }

    ul {
      max-height: calc(100vh - 60px);
    }
  }
}