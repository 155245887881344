@import "../../../../styles/mixins";

.cl_about_school {
  padding: 68px 0 120px;
  background-color: #F5F5F5;

  .description_wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;

    .description {
      flex-shrink: 0;
      width: calc(50% - 130px);
      margin-right: 130px;

      font-size: 17px;
      line-height: 25px;
    }

    .school_card {
      margin-top: -20px;
      width: 50%;
      flex-shrink: 0;
      border-radius: 10px;
      background-color: #fff;

      .card_inner {
        position: relative;
        padding: 72px 100px 72px 170px;

        .subscribe_to_school {
          position: absolute;
          top: 0;
          right: 30px;
          cursor: pointer;
        }

        .userpic {
          @include middle;
          left: 60px;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #040E26;

          img, span {
            @include center-middle;
          }

          img {
            width: 100%;
          }

          span {
            font-size: 32px;
            line-height: 32px;
            color: #fff;
            font-weight: 500;
          }
        }

        .title {
          font-size: 22px;
          line-height: 22px;
          font-weight: 600;
          margin-bottom: 14px;

          a {
            text-decoration: none;
            color: unset;
          }
        }

        .courses_count {
          font-size: 15px;
          line-height: 15px;

          a {
            color: #2450FF;
            text-decoration: none;
            border-bottom: 1px solid #2450FF50;
          }
        }
      }
    }
  }

  @include adaptive(900px) {
    .description_wrapper {
      flex-wrap: wrap-reverse;

      .description {
        width: 100%;
        margin-right: 0;
      }

      .school_card {
        width: 100%;
        margin-top: 0;
        margin-bottom: 62px;
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0 50px;

    .cl_centrer {
      h2 {
        margin-bottom: 36px;
      }
    }

    .description_wrapper {
      .school_card {
        margin-bottom: 36px;

        .card_inner {
          padding: 65px 50px 50px 100px;

          .userpic {
            width: 60px;
            height: 60px;
            left: 20px;
          }

          .title {
            font-size: 17px;
            line-height: 25px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}