@import "../../styles/mixins";

.edit_webinars_wrapper {
  position: relative;
  max-width: 1088px;
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 120px;
  text-align: center;

  .cc_top {
    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .courses_block {
    text-align: center;
    display: block;

    .course_preview {
      text-align: left;
      display: inline-block;
    }
  }

  .preloader_wrapper {
    z-index: 5;
  }

  h6 {
    height: 24px;
    line-height: 24px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .course_form_hint {
    font-size: 10px;
    line-height: 16px;
    color: #C0C2C8;

    a {
      text-decoration: none;
      color: #C0C2C8;
      border-bottom: 1px solid #C0C2C8;
    }

    &.cfh_abs {
      position: absolute;
    }
  }

  .course_name_block {
    margin-bottom: 42px;
    text-align: center;

    .course_name_input {
      width: 100%;
      padding: 0;
      border: 0 solid;
      border-bottom: 2px solid #040E26;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      min-height: 72px;
      color: #434A5C;

      @include input-placeholder {
        color: #C0C2C8;
      }

      &:disabled {
        border: 0 solid;
        background-color: transparent;
      }
    }

    .course_form_hint {
      display: inline-block;
      width: 640px;
      padding-top: 12px;
    }
  }

  .webinars_edit_block {
    text-align: left;

    h5 {
      font-size: 10px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #C0C2C8;

      &.pseudo_link {
        display: inline-block;
        border-bottom: 1px dashed rgba(146,129,129,.5);
        cursor: pointer;
      }
    }

    .ce_grid {
      display: flex;

      .ce_grid_block {
        width: 100%;

        &.ce_left_block {
          width: 303px;
          margin-right: 33px;
          flex-shrink: 0;
        }
      }
    }

    .course_form_hint {
      padding-top: 8px;
    }

    .ce_button_holder {
      text-align: center;
      padding-top: 64px;
    }
  }
}