@import "../../../../../../styles/mixins";

.video_hosting_hint {
  margin: 16px 0;
  padding: 16px 0;
  border-radius: 16px;
  background-color: #F2F0F0;
  display: flex;
  flex-wrap: nowrap;

  .list {
    width: calc(50% - 32px);
    flex-shrink: 0;
    padding: 0 16px;

    &:first-of-type {
      border-right: 1px solid #434A5C;
    }

    h4 {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 18px;
    }

    p {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 14px;
    }
  }
}