@import "../../../../styles/mixins";

.cl_header {
  position: relative;

  .cl_centrer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .header_content {
    position: relative;
    padding: 105px 0 150px;

    h1 {
      color: #fff;
      font-size: 46px;
      line-height: 60px;
      font-weight: 500;
    }
  }

  @include adaptive($adaptive_course_mid) {
    .cl_centrer {
      margin: 0;
      display: block;
    }

    .header_content {
      padding-top: 95px;
      margin: 0 50px;
    }
  }

  @include adaptive($adaptive_course_min) {
    .header_content {
      padding-top: 50px;
      padding-bottom: 210px;
      margin: 0 20px;

      h1 {
        font-size: 28px;
        line-height: 35px;
      }
    }
  }
}