@import "../styles/mixins";
$t_min: 415px;
$t_700: 700px;
$t_mid: 1050px;


.dmo_wrapper {
  position: relative;
  z-index: 1;
  padding: 70px 0 0;
  text-align: center;
  overflow: hidden;


  .text_wrapper {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: calc(100% - 48px);
    max-width: 1000px;
    margin: 36px 24px 64px;
    text-align: left;
    white-space: normal;

    h2 {
      margin-bottom: 24px;
    }

    .company_logo {
      width: 104px;
      height: 67px;
      margin-bottom: 30px;
      @include retina-background-image('./images/logo', 'png', 104px, 67px);
    }
  }

  .dmo_inner {
    position: relative;
    z-index: 1;
    height: 560px;
    text-align: left;

    .ellipse_1 {
      position: absolute;
      z-index: 0;
      top: 100px;
      left: -400px;
      width: 800px;
      height: 800px;
      filter: blur(50px);
      background-repeat: no-repeat;
      background-size: 800px 800px;

      @include retina-background-image('./images/ellipse_1', 'png', 800px, 800px);
    }

    .flake {
      position: absolute;
      z-index: 2;

      &.flake_1 {
        top: 152px;
        left: 75%;
      }

      &.flake_2 {
        top: 352px;
        left: 80%;
      }
    }

    .scroll_to_form_button {
      position: absolute;
      top: 32px;
      right: 0;
      z-index: 10;

      cursor: pointer;
      font: normal normal 600 14px/14px Manrope;
      padding: 18px 40px 19px;
      color: #0064FF;

      border: 2px solid #0064FF;
      border-radius: 28px;
      background-color: #fff;

    }

    .ded {
      position: absolute;
      z-index: 4;
      left: 0;
      bottom: 0;
      width: 508px;
      height: 480px;
      @include retina-background-image('./images/ded-moroz-transparent', 'png', 508px, 480px);
    }

    .header_content {
      position: absolute;
      z-index: 5;
      width: calc(50% - 48px);
      left: 50%;
      top: 203px;

      h6 {
        white-space: nowrap;
        margin-bottom: 11px;

        svg {
          display: inline-block;
          vertical-align: top;
        }

        span {
          display: inline-block;
          vertical-align: top;
          margin-top: 7px;
          margin-left: 8px;
          font: normal normal 800 12px/12px Manrope;
          text-transform: uppercase;
          letter-spacing: 0.6px;
          color: #FFD83E;
        }
      }

      h1 {
        font: normal normal 800 50px/64px Manrope;
        color: #fff;
        margin-bottom: 32px;
      }

      .date {
        white-space: nowrap;
        display: inline-block;
        margin-bottom: 32px;

        svg {
          display: inline-block;
          vertical-align: top;
        }

        span {
          display: inline-block;
          vertical-align: top;
          margin-top: 5px;
          font: normal normal 600 14px/14px Manrope;
          margin-left: 8px;
          color: #AFEBFF;
        }
      }

      .btn_holder {
        button {
          display: inline-block;
          vertical-align: top;
          margin: 0 24px 24px 0;
          cursor: pointer;
          font-size: 14px;
          line-height: 14px;
          font-weight: 500;
          padding: 18px 40px 19px;
          color: #0064FF;
          background-color: #ffffff;

          border: 2px solid #0064FF;
          border-radius: 28px;
        }

        .flake_loader {
          position: relative;
          margin: 0 auto;
          z-index: 15;
          width: 59px;
          height: 64px;
          margin-left: -8px;

          svg {
            -webkit-animation: rotating 4s linear infinite;
            -moz-animation: rotating 4s linear infinite;
            -ms-animation: rotating 4s linear infinite;
            -o-animation: rotating 4s linear infinite;
            animation: rotating 4s linear infinite;
          }
        }
      }
    }

    @include adaptive($t_mid) {
      .flake {
        &.flake_1 {
          left: 85%;
        }
        &.flake_2 {
          left: 94%;
        }
      }

      .ded {
        width: 50%;
        height: calc((100vw - 80px) / 2 / 508 * 480);
        background-size: cover;
      }

      .header_content {
        width: calc(50% - 60px);
        margin-left: 60px;
      }
    }
    @include adaptive($t_700) {
      height: auto;

      .ellipse_1 {
        display: none;
      }

      .flake {
        &.flake_1 {
          top: 304px;
          left: 251px;
          width: 59px;
          height: auto;
        }


        &.flake_2 {
          top: auto;
          bottom: 0;
          left: auto;
          right: -50px;
          width: 120px;
          height: auto;
        }
      }

      .scroll_to_form_button {
        right: auto;
        top: 16px;
        @include center;

        padding: 10px 24px 11px;
      }

      .ded {
        position: relative;
        bottom: auto;
        left: auto;
        margin-left: 16px;
        width: 254px;
        height: 240px;
        background-size: 254px 240px;
        @include retina-background-image('./images/ded-moroz-transparent', 'png', 254px, 240px);
      }

      .header_content {
        position: relative;
        margin: 0 24px;
        top: auto;
        left: auto;
        width: auto;
        padding-top: 104px;
        margin-bottom: 32px;

        h6 {
          margin-bottom: 18px;
        }

        h1 {
          font: normal normal 800 32px/40px Manrope;
          margin-bottom: 22px;
        }
      }
    }
  }

  .player_holder {
    position: relative;
  }
}



@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}