@import "../../../styles/mixins";

.edit_create_wrapper .course_edit_block.ceb_lessons {
  .ce_button_holder {
    padding-top: 0;
  }
}

.edit_lessons_list {
  width: 752px;
  margin: 0 auto;
}

.lesson_item.li_draggable {
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #C0C2C8;
  display: flex;

  &:hover {
    .li_delete {
      opacity: 1;
    }
  }

  .drag_handle {
    padding: 8px 0 8px 16px;
    flex-shrink: 0;
    cursor: move;
    display: none;
  }

  .li_inner {
    padding: 9px 16px 8px;
    display: flex;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;

    .type_icon {
      width: 16px;
      height: 16px;
      margin-right: 16px;
      margin-top: -1px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .li_delete {
    cursor: pointer;
    flex-shrink: 0;
    padding: 8px 16px 0;
    opacity: 0;

    @include transition(opacity ease-out 150ms);

    svg {
      height: 20px;
      width: auto;
    }
  }
}