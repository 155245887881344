@import "../styles/mixins";

.text_modal {
  .tm_text {
    margin-bottom: 24px;
  }

  .tm_buttons {
    text-align: center;

    button {
      display: inline-block;
      margin: 0 8px;
    }

    a {
      display: inline-block;
      margin: 0 8px;

      button {
        margin: 0;
      }
    }
  }
}