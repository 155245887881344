@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Thin.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Light.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/Inter/Inter-LightItalic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-LightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Regular.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Italic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-Italic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Medium.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Bold.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Black.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/Inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
  url("../fonts/Inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}
//@font-face {
//  font-family: 'Inter';
//  font-weight: 400;
//  src: url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Inter';
//  font-weight: 400;
//  font-style: italic;
//  src: url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Inter';
//  font-weight: 600;
//  src: url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Inter';
//  font-weight: 600;
//  font-style: italic;
//  src: url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Inter';
//  font-weight: 500;
//  src: url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Inter';
//  font-weight: 500;
//  font-style: italic;
//  src: url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Inter';
//  font-weight: 300;
//  src: url('../fonts/Inter/Inter-Light.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Inter';
//  font-weight: 200;
//  src: url('../fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Inter';
//  font-weight: 200;
//  font-style: italic;
//  src: url('../fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
//}
//
//
//@font-face {
//  font-family: 'Source Black';
//  font-style: italic;
//  src: url('../fonts/SourceSansPro-Black.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 400;
//  src: url('../fonts/Commissioner/Commissioner-Regular.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 400;
//  font-style: italic;
//  src: url('../fonts/Commissioner/Commissioner-Regular.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 600;
//  src: url('../fonts/Commissioner/Commissioner-Bold.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 600;
//  font-style: italic;
//  src: url('../fonts/Commissioner/Commissioner-Bold.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 500;
//  src: url('../fonts/Commissioner/Commissioner-SemiBold.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 500;
//  font-style: italic;
//  src: url('../fonts/Commissioner/Commissioner-SemiBold.ttf') format('truetype');
//}
//
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 300;
//  src: url('../fonts/Commissioner/Commissioner-Light.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 200;
//  src: url('../fonts/Commissioner/Commissioner-ExtraLight.ttf') format('truetype');
//}
//@font-face {
//  font-family: 'Commissioner';
//  font-weight: 200;
//  font-style: italic;
//  src: url('../fonts/Commissioner/Commissioner-ExtraLight.ttf') format('truetype');
//}

//
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 400;
//  src: url('../fonts/Cera Pro Regular.otf') format('opentype');
//}
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 400;
//  font-style: italic;
//  src: url('../fonts/Cera Pro Regular Italic.otf') format('opentype');
//}
//
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 600;
//  src: url('../fonts/Cera Pro Bold.otf') format('opentype');
//}
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 600;
//  font-style: italic;
//  src: url('../fonts/Cera Pro Bold Italic.otf') format('opentype');
//}
//

//
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 500;
//  src: url('../fonts/Cera Pro Medium.otf') format('opentype');
//}
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 500;
//  font-style: italic;
//  src: url('../fonts/Cera Pro Medium Italic.otf') format('opentype');
//}
//
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 300;
//  src: url('../fonts/Cera Pro Light.otf') format('opentype');
//}
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 300;
//  font-style: italic;
//  src: url('../fonts/Cera Pro Light Italic.otf') format('opentype');
//}
//
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 200;
//  src: url('../fonts/Cera Pro Thin.otf') format('opentype');
//}
//@font-face {
//  font-family: 'Cera Pro';
//  font-weight: 200;
//  font-style: italic;
//  src: url('../fonts/Cera Pro Thin Italic.otf') format('opentype');
//}


@font-face {
  font-family: 'Manrope';
  font-weight: 200;
  src: url('../fonts/Manrope-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  src: url('../fonts/Manrope-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  src: url('../fonts/Manrope-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  src: url('../fonts/Manrope-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 600;
  src: url('../fonts/Manrope-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  src: url('../fonts/Manrope-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 800;
  src: url('../fonts/Manrope-ExtraBold.ttf') format('truetype');
}