@import "../../../../styles/mixins";

.lp_opportunities_wrapper {
  padding-top: 114px;
  padding-bottom: 150px;

  .l_content {
    h2 {
      margin-bottom: 111px;
    }

    .plates {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      li {
        position: relative;
        width: calc(33% - 20px);
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 20px;
        height: 480px;

        p {
          padding: 40px 40px 0;
          font-size: 24px;
          line-height: 35px;
          margin-bottom: 35px;

          strong {
            display: block;
          }
        }

        .plate_round {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: relative;
          margin-left: 40px;

          img {
            @include center-middle;
          }
        }

        &.plate_1 {
          background-color: #FFF4F2;

          p strong {
            color: #F90353;
          }

          .plate_round {
            background-color: #fff;
            box-shadow: 10px 10px 30px #FFD5CC;
          }

          .plate_bg {
            @include center;
            bottom: 0;
            z-index: 0;
            width: calc(100% - 154px);
          }
        }
        &.plate_2 {
          background-color: #fff;
          box-shadow: 20px 40px 60px #0016521A;

          p strong {
            color: #604BC4;
          }

          .plate_bg {
            @include center;
            bottom: 0;
            z-index: 0;
            width: calc(100% - 60px);
          }
        }
        &.plate_3 {
          background-color: #604BC4;

          p {
            color: #fff;
          }

          .plate_round {
            @include gradientDeg(#FAFCFF, #DEE6F5, 129deg);
            box-shadow: 10px 10px 30px #4933B2;
          }

          .plate_bg {
            position: absolute;
            z-index: 0;
            right: 0;
            bottom: 0;
            width: calc(100% - 154px);
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    .l_content {
      .plates {
        li {
          height: 375px;

          p {
            padding: 35px 30px 0;
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 27px;
          }

          &.plate_1,
          &.plate_3 {
            .plate_bg {
              width: calc(100% - 100px);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    padding: 70px 0 75px;

    .l_content {
      h2 {
        margin-bottom: 75px;
      }

      .plates {
        display: block;
        text-align: center;

        li {
          width: auto;
          max-width: 300px;
          margin:  0 auto 35px;
          text-align: left;
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding: 50px 0 55px;

    .l_content {
      h2 {
        margin-bottom: 35px;
      }

      .plates {
        display: block;
        text-align: center;

        li {
          margin-bottom: 15px;
        }
      }
    }
  }
}