.school_students {
  margin: 0 24px;
  padding-bottom: 80px;

  h1 {
    font-size: 21px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 18px;
  }

  p {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 18px;
  }

  .students_list {
    font-size: 14px;
    line-height: 19px;

    li {
      margin-bottom: 8px;

      a {
        color: #2450FF;
        text-decoration: none;
        border-bottom: 1px solid #2450FF50;
      }
    }
  }
}