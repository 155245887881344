@import "../styles/mixins";

.sign_up_gift {

  .gift_block {
    background-color: #E6E7EA50;
    border-radius: 8px;
    padding: 16px 8px 0;
    width: 340px;
    margin-left: -10px;
    margin-bottom: 48px;

    @media screen and (max-width: $treshold_mid) {
      width: 100%;
      padding: 16px 24px 8px;
      margin-left: -24px;
      border-radius: 0;
    }

    h5 {
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 16px;
    }
  }

}