@import "../../../styles/mixins";

.userpic_upload_wrapper {
  position: relative;
  margin-bottom: 32px;

  .userpic_upload_inner {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #434A5C;
    text-align: center;

    label {
      position: absolute;
      z-index: 30;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      padding-top: 65px;
      opacity: 0;
      background-color: #00000090;
      @include transition(opacity ease-out 100ms);
      cursor: pointer;
      font-weight: 500;


      &:hover {
        opacity: 1;
      }
    }

    .userpic_letter {
      position: absolute;
      z-index: 0;
      @include center-middle;
      font-size: 80px;
      line-height: 80px;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;

      .ul_empty {
        font-size: 24px;
        line-height: 36px;
        display: block;
      }
    }

    .avatar {
      width: 100%;
      @include center-middle;
    }


    input {
      opacity: 0;
      visibility: hidden;
    }
  }


  .button_upload {
    @include middle;
    left: 180px;

    label {
      cursor: pointer;
    }
  }


}