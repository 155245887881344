@import "../../styles/mixins";

.demo_note {
  @include center-middle;
  position: fixed;
  width: 450px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
  border-radius: 8px;
  text-align: center;
  margin-top: -50px;
  z-index: 10000;

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: $treshold) {
    width: calc(100% - 48px);
    border-radius: 0;
  }
}