
* {	outline: none; }

html { -webkit-font-smoothing: antialiased; }
html, body { margin: 0; padding: 0; width: 100%; }

body { height: 100%; position:relative; min-height: 100%; }

table, td, img, form { border: none; margin: 0; padding: 0; }
table { font-size: 100%; width:100%; border-collapse:collapse; }
td { vertical-align:top; }
img { display:block; }

form { width: 100%; }
input, textarea, select, button { font-size:14px; font-weight:normal; margin: 0; -webkit-font-smoothing: antialiased; font-smoothing: antialiased; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; -webkit-appearance: none; -moz-appearance: none; }
input:focus, textarea:focus, select:focus, button:focus { outline: none; }

hr { color: #091e30; height: 1px; line-height: 1px; font-size: 1px; border: 0; background-color: #001526; margin: 0; }

object:focus { outline: none; }
textarea { resize: none; }

h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0; font-weight: normal}
dl, dt, dd, p, ol, ul, li { margin: 0; padding: 0; }
ul, li { list-style-type: none; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance:textfield;
}