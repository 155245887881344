@import "../styles/mixins";

.search_empty {
  text-align: center;
  padding-top: 24px;

  img {
    display: inline-block;
    margin-bottom: 24px;
  }

  .empty_text {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 64px;
  }
}