@import "../../styles/mixins";

.audio_player_wrapper {

  .rhap_container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .track_title {
    @include center;
    white-space: nowrap;
    bottom: 150px;
    margin-bottom: 10px;
    padding: 8px 16px;
    border-radius: 3px;
    background-color: #fff;
  }

  .dropdown_wrapper {
    @include center;
    white-space: nowrap;
    bottom: 100px;
    margin-bottom: 10px;
    padding: 4px 10px;
    border-radius: 3px;
    background-color: #fff;
    z-index: 50;
  }
}