@import "../styles/mixins";

.main_top_featured {
  position: relative;

  &.mb_test {
    background-color: #f1f0ee;

    .pano_new_test {
      width: 100%;
      z-index: 0;
      margin-top: -400px;
    }
  }

  .bg {
    height: 100%;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-color: #fff;

    img {
      @include center;
      height: auto;
      width: 100%;
      min-height: 100%;

      // TODO: установить дефолтную пропорцию для изображений
      //@media (min-aspect-ratio: 1552/825) and (min-width: $treshold) {
      //  height: auto;
      //  width: 100%;
      //
      //}
    }
  }

  .tf_content {
    position: absolute;
    z-index: 10;
    bottom: 36px;
    left: 64px;
    width: 352px;
    color: #434A5C;

    h6 {
      font-size: 10px;
      line-height: 24px;
      opacity: .75;
      white-space: nowrap;
      position: relative;
      text-transform: uppercase;
      letter-spacing: 0.05em;

      svg {
        @include middle;
        left: 100%;
      }
    }

    h1 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }

    .teaser {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }

  }

  .tf_link {
    @include center;
    bottom: 60px;
  }

  &.main_banner {
    height: auto;

    .bg {
      .wh_banner {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 60px;
        width: 100%;
        height: calc(100% - 60px);

        img {
          @include clear;
          position: absolute;
          bottom: 0;
          height: 100%;
          width: auto;

          &.img_banner_w {
            left: 0;
          }
          &.img_banner_h {
            right: 0;
          }
        }
      }
    }
    .tf_content {
      bottom: auto;
      left: auto;
      text-align: center;
      position: relative;
      //@include center-middle;
      width: 70%;
      margin: 0 auto;
      padding: 140px 0 50px;

      .teaser {
        a {
          font-weight: 500;
        }
      }

      .tf_link {
        @include clear;
        position: relative;
      }

      .categories_feed {
        font-size: 0;
        margin-bottom: 48px;

        li {
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          line-height: 19px;
          margin-right: 19px;
          padding-right: 19px;
          border-right: 1px solid #434A5C50;
          margin-bottom: 19px;

          &:last-of-type {
            margin-right: 0;
            padding-right: 0;
            border-right: 0 solid;
          }
        }
      }

      a {
        text-decoration: none;
        color: #434A5C;
        border-bottom: 1px solid #434A5C50;
      }

      h1 {
        font-size: 48px;
        line-height: 48px;
        padding-bottom: 36px;
        margin-bottom: 36px;
        position: relative;

        &:after {
          content: '';
          @include center;
          bottom: 0;
          width: 360px;
          height: 1px;
          background-color: #434A5C;
          opacity: .5;
        }
      }
    }
  }
  @media screen and (max-width: $treshold_mid) {
    overflow: hidden;
    //height: 34vh;
    //min-height: 100vh;
  }
  @media screen and (max-width: $treshold_mid) {
    .bg {
      img {
        height: 100%;
        width: auto;
      }
    }
    .tf_content {
      left: 24px;
      bottom: 24px;
      width: calc(100% - 48px);

      h6 {
        svg {
          left: auto;
          right: 0;
        }
      }
    }

    &.main_banner {
      .bg {
        .wh_banner {
          top: 80px;
          height: 160px;
        }
      }

      .tf_content {
        white-space: normal;
        width: 100%;
        margin-top: 48px;

        h1 {
          font-size: 32px;

          &:after {
            width: 50%;
          }
        }
      }
    }
    //.tf_link {
    //  opacity: 0;
    //  bottom: 0;
    //  width: 100%;
    //  height: 100%;
    //}
  }
  @media screen and (max-width: $treshold) {

    &.main_banner {
      //min-height: 0;

      .tf_content {
        width: auto;
        margin: 0 25px;

        h1 {
          font-size: 24px;
          line-height: 36px;
        }

        .teaser {
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 19px;
        }

        .categories_feed {
          li {
            margin-right: 0;
            padding-right: 8px;
            margin-bottom: 8px;
            border-right: 0 solid;

            &:after {
              content: ',';
            }

            &:last-of-type {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}