@import "../../styles/mixins";

.range {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	max-width: 100%;
	padding: 0 17px;

	&_label {
		display: inline-block;
		white-space: nowrap;
		width: 100px;
		padding: 0 27px;
		text-align: left;
		&:first-child {
			text-align: right;
		}
	}

	&_container {
		position: relative;
		display: flex;
		width: 640px;
		max-width: 100%;
		height: 36px;
		&:before,
		&:after {
			content: '';
			z-index: -1;
			position: absolute;
			top: 50%;
			width: 2px;
			height: 6px;
			background-color: #C0C2C8;
			transform: translateY(-50%);
		}
		&:before {
			left: 0;
		}
		&:after {
			right: 0;
		}
	}

	&_line {
		align-self: center;
		height: 2px;
		width: 100%;
		border-radius: 4px;
	}

	&_thumb {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 14px;
		width: 14px;
		border-radius: 100%;
		background-color: #040E26;
		border: 1px solid #F2F0F0;
	}

	&_clear {
		position: absolute;
		top: 50%;
		right: 0;
		cursor: pointer;
		transform: translateY(-50%);
		svg {
			width: 10px;
			height: 10px;
		}
	}

	@media screen and (max-width: $treshold) {
		flex-wrap: wrap;
		width: 100%;
		padding: 0;
		.range {
			&_container {
				width: 100%;
			}
			&_label {
				width: 50%;
				order: 1;
				box-sizing: border-box;
				padding: 0;
				text-align: right;
				&:first-child {
					text-align: left;
				}
			}
			&_clear {
				display: none;
			}
		}
	}

}
