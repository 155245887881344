@import "../styles/mixins";

.collections_page {
  padding-top: 112px;

  .professional_banner {
    background-color: #040E26;
    padding: 10px 25px;
    text-align: center;
    color: #fff;

    span {
      display: inline-block;
      vertical-align: top;
      margin-left: 24px;
      padding-top: 2px;
    }

    @media screen and (max-width: $treshold) {
      padding-bottom: 18px;
      background-color: #fff;
      color: #040E26;
    }
  }

  .cp_feeds {
    margin: 0 64px 64px;

    .bottom_loader {
      height: 100px;
      position: relative;
    }

    @media screen and (max-width: $treshold) {
      margin: 0 24px 24px;
    }
  }
}

.collection_featured_wrapper {
  position: relative;
  width: 33%;

  &:nth-child(2) {
    width: 34%;
  }

  .main_featured_item {
    width: 100%;
    height: 100%;
    display: block;

    .top {
      padding: 24px;

      .content_type {
        padding-top: 8px;
      }
    }
  }

  button {
    @include center;
    bottom: 16px;
  }

  @media screen and (max-width: $treshold) {
    display: block;
    width: auto !important;
    height: auto;

    .main_featured_item {
      display: block;
      width: auto !important;
      margin: 24px 24px 0;
      height: 400px;
    }

  }
}