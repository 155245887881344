@import "../styles/mixins";

.feedback_form_modal {
  width: 290px;

  .ffm_inner {
    position: relative;
  }

  textarea {
    height: 140px;
  }

  button {
    width: 290px;
  }

  .ffm_after_message {
    text-align: center;

    p {
      font-size: 19px;
      line-height: 32px;
      margin-bottom: 32px;
    }
  }
}