@import "../../styles/mixins";

.lesson_content {
  .lesson_pdf_wrapper {
    @media screen and (max-width: $treshold) {
      width: 100vw;
      margin-left: -20px;
    }

  }
}