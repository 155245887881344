@import "../styles/mixins";

.header_v2 {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;

  body.no_header & {
    display: none !important;
  }

  .centrer {
    position: relative;
    z-index: 100;
    background-color: #fff;
    height: 69px;
    border-bottom: 1px solid #E8E8E8;
  }

  .logo {
    @include center-middle;
    height: 39px;

    img {
      height: 100%;
    }

    .beta {
      font-size: 13px;
      line-height: 13px;
      position: absolute;
      left: 100%;
      margin-left: 5px;
      top: -5px;
      color: #46C790;
      font-weight: bold;
    }
  }

  .sidebar_switcher {
    @include middle;
    right: 10px;

    &.opened {
      svg .a {
        fill: #e8e8e8;
      }
    }
    display: none;
  }

  .search_toggler {
    display: none;
  }

  @media screen and (max-width: $treshold_mid) {

    .centrer {
      height: 59px;
    }

    .logo {
      @include middle;
      left: 60px;
    }

    .sidebar_switcher {
      display: block;
    }

    .search_toggler {
      @include middle;
      right: 60px;
      display: block;
    }
  }
}