@import "../styles/mixins";

.sidebar_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #E5E5E5;
  padding-top: 60px;
  @include transition(ease-out 400ms);
  overflow: auto;

  &.hidden {
    top: -100vh;
  }

  .auth_tabs {
    margin-bottom: 0;
  }

  .sign_up_wrapper {
    .auth_tabs {
      display: none;
    }
  }

  .sidebar_forms {
    padding: 48px 24px 24px;

    .sign_in_form,
    .sign_up_form {
      text-align: center;
      .text_input {
        background-color: transparent;
      }
    }

    .sign_up_form {
      padding-bottom: 96px;
    }

    .sign_up_wrapper {
      padding-top: 0;
      margin: 0;

      .sign_up_landing .radio_block .radio_wrapper {
        margin: 0 0 24px;
      }
    }
  }
}