@import "../styles/mixins";
@import url('https://fonts.googleapis.com/css2?family=Frijole&display=swap');

.greeting_wrapper {
  padding-top: 70px;

  &.loaded {
    .player_wrapper {
      .greeting_screen {
        .greeting_pattern {
          .countdown_wrapper {
            opacity: 1;
          }
          .flake_loader {
            opacity: 0;
            z-index: -1;
          }
        }
      }
    }
  }


  .player_wrapper {
    position: relative;
    background-color: #000;
    height: calc(100vh - 70px);

    &.playing {
      height: auto;

    }

    .greeting_screen {
      position: absolute;
      z-index: 20;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      @include transition(ease-out 1000ms);

      &.hidden {
        opacity: 0;

        .greeting_gradient {
          .gg_sizer {
            width: 800px;
            height: 800px;
          }
        }
      }

      &.removed {
        display: none;
      }

      .greeting_gradient {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .gg_sizer {
          @include center-middle;
          width: 600px;
          height: 600px;
          @include transition(ease-out 500ms);

          &.started {
            width: 400px;
            height: 400px;

            &.scaled {
              width: 650px;
              height: 650px;
            }
          }

          .g_color {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      .greeting_pattern {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url('./images/ice_pattern.png') 50% 50%;

        .greeting_play {
          @include center-middle;
          width: 180px;
          height: 180px;
          border-radius: 50%;
          background: #ffffff80;
          cursor: pointer;
          @include transition(ease-out 500ms);

          svg {
            @include center-middle;
            margin-left: 5px;
          }

          &:hover {
            width: 210px;
            height: 210px;
            background-color: #ffffff60;
          }

          &.playing {
            width: 260px;
            height: 260px;
            opacity: 0;
          }
        }

        .greeting_buttons_wrapper {
          @include center-middle;
          text-align: center;

          a {
            display: inline-block;
            vertical-align: top;
            margin: 0 12px 24px;
            background-color: transparent;

            button {
              margin: 0;
            }
          }

          button {
            display: inline-block;
            vertical-align: top;
            margin: 0 12px 24px;
            background-color: #ffffff80;
            border: 2px solid #0064FF;
            border-radius: 30px;
            color: #0064FF;
            font-weight: 600;
          }
        }

        .waiting_message_wrapper {
          @include center-middle;
          z-index: 10;
          padding: 24px;
          border-radius: 24px;
          text-align: center;
          width: calc(100% - 32px);
          max-width: 500px;
          background-color: #ffffff90;
          border: 1px solid #0064FF90;

          p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 32px;
            text-align: left;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        .payment_wrapper {
          @include center-middle;
          padding: 24px;
          border-radius: 24px;
          text-align: center;
          width: calc(100% - 32px);
          max-width: 500px;
          background-color: #ffffff90;
          border: 1px solid #0064FF90;

          p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 32px;
            text-align: left;
          }

          button {
            display: inline-block;
            vertical-align: top;
            background-color: #ffffff80;
            border: 2px solid #0064FF;
            border-radius: 30px;
            color: #0064FF;
            font-weight: 600;
          }
        }

        .countdown_wrapper {
          font-family: 'Frijole', cursive;
          font-size: 72px;
          line-height: 72px;
          @include center-middle;
          white-space: nowrap;
          color: #fff;
          cursor: default;
          @include transition(opacity linear 300ms);
          opacity: 0;

          @include adaptive($treshold) {
            font-size: 56px;
            line-height: 56px;
          }

          @include adaptive($treshold_min) {
            font-size: 40px;
            line-height: 40px;
          }
        }

        .flake_loader {
          @include center-middle;
          width: 90px;
          @include transition(opacity linear 300ms);

          svg {
            width: 100%;
            height: 100%;

            -webkit-animation: rotating 4s linear infinite;
            -moz-animation: rotating 4s linear infinite;
            -ms-animation: rotating 4s linear infinite;
            -o-animation: rotating 4s linear infinite;
            animation: rotating 4s linear infinite;
          }
        }
      }
    }

    .player_inner {
      margin: 0 auto;
      position: relative;
      height: 100%;

      .player_v2_wrapper {
        height: 100%;

        .react-player {
          background-color: #ffffff;
        }
      }
    }

    @media screen and (max-width: $treshold_mid) {
      .player_inner {
        width: auto;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}