@import "../../../../styles/mixins";

.social_share_widget {
  position: relative;
  width: 30px;
  height: 30px;

  &.visible {
    .ssw_switcher_wrapper svg path {
      opacity: 1;
    }

    .ssw_bubble {
      display: block;
    }
  }

  .ssw_switcher_wrapper {
    cursor: pointer;

    &:hover {
      svg {
        path {
          opacity: 1;
        }
      }
    }
  }

  .ssw_bubble {
    display: none;
    @include center;
    top: 100%;
    margin-top: 6px;
    width: 50px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #E4E5E8;
    padding: 15px 0;
    text-align: center;

    .bubble_arrow {
      @include center;
      bottom: 100%;
      width: 10px;
      height: 6px;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border: 1px solid #E4E5E8;

        @include rotate(45deg);
      }
    }

    button {
      width: 20px;
      height: 20px;
      margin: 0 auto 15px;
      @include transition(linear 130ms);

      &:hover {
        @include scale(1.3);
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      svg {
        circle {
          fill: #040E26;
        }
      }
    }
  }

  @include adaptive($adaptive_course_mid) {
    .ssw_switcher_wrapper {
      svg {
        .b {
          fill: #040E26;
        }
      }
    }
  }
}