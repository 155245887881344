@import "../../../../styles/mixins";

.lp_splash_wrapper {
  background-color: #FDF9F3;
  padding: 135px 0 147px;

  .l_content {
    display: flex;
    justify-content: space-between;

    p {
      width: calc(50% - 85px);
      font-size: 18px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: $treshold_mid) {
    padding: 100px 0 120px;
    .l_content {
      p {
        width: calc(50% - 30px);
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding: 55px 0 57px;
    .l_content {
      display: block;

      p {
        width: auto;
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}