.ls_advantages_wrapper {
  margin-top: -156px;
  padding-top: 104px;
  position: relative; }
  .ls_advantages_wrapper .wrapper_gradient {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 280px;
    background: #F8F8FA;
    /* Old browsers */
    background: -moz-linear-gradient(top, #F8F8FA 0%, #fff 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F8F8FA), color-stop(100%, #fff));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #F8F8FA 0%, #fff 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #F8F8FA 0%, #fff 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #F8F8FA 0%, #fff 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #F8F8FA 0%, #fff 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
    /* IE6-9 */ }
  .ls_advantages_wrapper h2 {
    margin-bottom: 111px; }
  .ls_advantages_wrapper .plates li {
    height: 555px;
    width: 300px;
    margin-right: 135px;
    display: inline-block;
    vertical-align: top; }
    .ls_advantages_wrapper .plates li:nth-of-type(3), .ls_advantages_wrapper .plates li:nth-of-type(6) {
      margin-right: 0; }
    .ls_advantages_wrapper .plates li .pic_wrapper {
      width: 300px;
      height: 300px;
      position: relative;
      margin-bottom: 45px;
      border-radius: 20px; }
      .ls_advantages_wrapper .plates li .pic_wrapper img {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
      .ls_advantages_wrapper .plates li .pic_wrapper .crunch {
        width: 170px;
        height: 170px;
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
        .ls_advantages_wrapper .plates li .pic_wrapper .crunch img {
          position: absolute;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          -moz-transform: translate(0, 0);
          -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
          transform: translate(0, 0);
          position: absolute;
          left: 0;
          bottom: 0; }
      .ls_advantages_wrapper .plates li .pic_wrapper.p0 {
        background-color: #F2F6FC; }
      .ls_advantages_wrapper .plates li .pic_wrapper.p1 {
        background-color: #F9F9F9; }
      .ls_advantages_wrapper .plates li .pic_wrapper.p2 {
        background-color: #FDF9F3; }
    .ls_advantages_wrapper .plates li p {
      font-size: 18px;
      line-height: 25px; }
      .ls_advantages_wrapper .plates li p span {
        font-weight: 600; }
      .ls_advantages_wrapper .plates li p a {
        font-weight: 600;
        color: #2450FF;
        text-decoration: none;
        border-bottom: 1px solid #2450FF50; }
  @media screen and (max-width: 1440px) {
    .ls_advantages_wrapper .plates {
      width: 960px; }
      .ls_advantages_wrapper .plates li {
        width: 288px;
        margin-right: 30px; }
        .ls_advantages_wrapper .plates li:nth-of-type(3), .ls_advantages_wrapper .plates li:nth-of-type(6) {
          margin-right: 30px; }
        .ls_advantages_wrapper .plates li .pic_wrapper {
          width: 288px;
          height: 288px; } }
  @media screen and (max-width: 1050px) {
    .ls_advantages_wrapper .plates {
      width: auto; } }
  @media screen and (max-width: 600px) {
    .ls_advantages_wrapper {
      overflow: hidden; }
      .ls_advantages_wrapper h2 {
        margin-bottom: 35px; }
      .ls_advantages_wrapper .plates {
        width: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: 0; }
        .ls_advantages_wrapper .plates li {
          margin-left: 0;
          margin-right: 0 !important;
          margin-bottom: 47px;
          width: 45%;
          height: auto; }
          .ls_advantages_wrapper .plates li p {
            font-size: 14px;
            line-height: 25px; }
          .ls_advantages_wrapper .plates li .pic_wrapper {
            width: 145px;
            height: 145px;
            margin-bottom: 19px; }
            .ls_advantages_wrapper .plates li .pic_wrapper img {
              width: 78px;
              height: 78px; }
            .ls_advantages_wrapper .plates li .pic_wrapper .crunch {
              width: 78px;
              height: 78px; }
              .ls_advantages_wrapper .plates li .pic_wrapper .crunch img {
                width: 87px;
                height: auto; } }

