@import "../../../../styles/mixins";

.cl_description {
  position: relative;
  padding: 40px 0 80px;

  .cl_centrer {
    padding: 68px 100px 72px;
    width: calc(1170px - 200px);

    border: 1px solid #E4E5E8;
    border-radius: 10px;
  }

  .cl_anchor {
    position: absolute;
    top: -70px;
  }

  &.folded {
    .course_description {
      max-height: 200px;
      overflow: hidden;

      &:after {
        display: block;
      }
    }
  }

  .fold_switcher {
    position: relative;
    display: inline-block;
    margin-top: 55px;
    padding-right: 10px;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    cursor: pointer;

    svg {
      @include middle;
      left: 100%;
      margin-top: 1px;
    }
  }

  .course_description {
    position: relative;
    color: #040E26;

    &:after {
      display: none;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 70px;
      @include gradient(#ffffff80, #ffffff)
    }

    p, ul, ol {
      margin-bottom: 43px;
      font-size: 17px;
      line-height: 25px;
    }

    p {
      & + p {
        margin-top: -18px;
      }
    }

    ul, ol {

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:before {
          position: absolute;
          left: 5px;
        }
      }
    }

    ul {
      li {
        &:before {
          content: '';
          top: 10px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #2450FF;
        }
      }
    }

    ol {
      counter-reset: line-number;
      li {
        counter-increment: line-number;

        &:before {
          content: counter(line-number)".";
        }
      }
    }
  }

  @include adaptive($adaptive_course_mid) {
    .cl_centrer {
      width: auto;
      padding: 68px 50px 72px;
    }
  }

  @include adaptive($adaptive_course_min) {
    padding-bottom: 50px;

    .cl_anchor {
      position: absolute;
      top: -60px;
    }

    .cl_centrer {
      padding: 41px 20px 37px;

      h2 {
        margin-bottom: 30px;
      }
    }
  }
}