@import "../styles/mixins";

.profile_wrapper {
  position: relative;
  background-color: #040e2602;
  padding: 86px 0 0 174px;

  body.no_dashboard & {
    padding-left: 0;
    padding-top: 70px;
  }

  .profile_bg {
    height: 120px;
  }

  .profile_inner {
    margin: 0;

    .profile_header {
      display: none;
    }
  }

  .profile_nav {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 70px;
    height: calc(100vh - 70px);
    width: 174px;
    background-color: #fff;
    border-right: 1px solid #E8E8E8;
    overflow: auto;

    .profile_nav_feed {
      white-space: nowrap;
      font-size: 0;
      padding-top: 16px;
    }

    a {
      position: relative;
      display: block;
      font-size: 14px;
      line-height: 19px;
      white-space: normal;
      padding: 6px 18px;
      margin-bottom: 12px;
      color: #C0C2C8;
      text-decoration: none;
      border-bottom: 1px solid transparent;

      sup {
        position: absolute;
        left: 100%;
        margin-left: 3px;
        top: -1px;
        font-size: 14px;
        line-height: 14px;
        color: #46C790;
      }

      &:hover {
        color: #434A5C;
      }

      &.active {
        color: #434A5C;
        border-bottom: 1px solid #434A5C;
      }

      &.red {
        color: #C33333;

        &.active {
          border-bottom-color: #C33333;
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    .profile_nav {
      display: none;
    }

    .profile_inner {
      margin: 0 24px;

      .profile_header {
        display: block;
        text-align: center;
        font-size: 24px;
        line-height: 24px;
        padding-top: 24px;
      }
    }
  }
}