@import "../styles/mixins";

.search_results {
  position: relative;
  text-align: center;
  padding-top: 112px;
  margin: 0 64px;

  .search_cats {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    position: relative;
    z-index: 20;

    .category_slash {
      margin: 0 24px;
    }
  }

  @media screen and (max-width: $treshold) {
    margin: 0 24px;

    .search_cats {
      margin-bottom: 32px;
      flex-direction: column;

      .category_slash {
        display: none;
      }
    }
  }

  .switcher {
    font-size: 0;
    white-space: nowrap;
    margin: 0 -12px -11px;

    li {
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
      line-height: 16px;
      padding: 0 12px;
      color: #C0C2C8;
      cursor: default;
      //cursor: pointer;

      h3 {
        padding-bottom: 10px;
        font-size: 24px;
        line-height: 1;
      }

      &.active {
        color: #040E26;
        h3 {
          border-bottom: 1px solid #040E26;
        }
      }
    }
  }

  .search-type {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 32px;
    z-index: 5;
  }

  .search-filters {
    position: relative;
    margin-bottom: 24px;
    padding: 20px 24px;
    background-color: #F2F0F0;
    &__top {
      display: flex;
      justify-content: space-around;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &__bottom {
      display: flex;
      justify-content: space-around;
      align-items: center;
      &._disabled {
        pointer-events: none;
        opacity: .25;
      }
    }

    .cats_section {
      display: flex;

      .dropdown {
        margin-right: 24px;
      }
    }

    .options_section {
      display: flex;

      .checkbox {
        margin-left: 50px;
      }

    }
  }

  @media screen and (max-width: $treshold) {
    .switcher {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      li {
        width: 100%;
        text-align: center;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
        h3 {
          display: inline-block;
          padding-bottom: 6px;
          font-size: 16px;
        }
      }
    }

    .search-type {
      display: block;
      margin-bottom: 0;
      &__sort {
        display: flex;
        justify-content: center;
        padding: 15px 24px 15px;
        margin-left: -24px;
        margin-right: -24px;
        background-color: #F2F0F0;
        position: relative;
        z-index: 10;
      }
    }

    .search-filters {
      width: calc(100vw - 48px);
      margin-left: -24px;
      padding-top: 15px;
      padding-bottom: 70px;
      border-radius: 0;

      &__top {
        flex-direction: column;
        .dropdown {
          margin-bottom: 32px;
        }
        .star-rating__container {
          margin-bottom: 5px;
        }
      }

      .cats_section {
        margin: 0 auto;
      }

      .ch_free {
        position: absolute;
        bottom: 25px;
      }
    }
  }
}