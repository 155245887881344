@import "../../../../styles/mixins";

.cl_related {
  padding: 68px 0 72px;

  .cl_centrer {
    h2 {
      margin-bottom: 36px;
    }

    .course_related {
      margin: 0;

      h5 {
        display: none;
      }

      .feed_block_outer {
        max-width: 1170px !important;

        .feed_block {
          margin-left: -15px;

          .course_preview_v2 {
            width: 270px;
            margin: 0 15px;

            .img_wrapper {
              height: 175px;
            }
          }
        }
      }
    }
  }

  @include adaptive($adaptive_course_mid) {
    overflow: hidden;
  }

  @include adaptive($adaptive_course_min) {
    padding-top: 40px;

    .feed_block_outer .arr {
      display: none;
    }
  }
}