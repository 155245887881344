.star-rating {
	display: flex;
	flex-direction: row-reverse;
	margin: 0 -8px;
	padding: 0;
	border: 0;
	&__container {
		display: flex;
		align-items: center;
	}
	&__label {
		margin-right: 12px;
	}
	input {
		display: none;
	}
	label {
		padding: 0 8px;
		cursor: pointer;
	}
	svg {
		fill: #E6E7EA;
	}
}

.star-rating > input:checked ~ label svg,
.star-rating:not(:checked) > label:hover svg,
.star-rating:not(:checked) > label:hover ~ label svg {
	fill: #040E26;
}

.star-rating > input:checked + label:hover svg,
.star-rating > input:checked ~ label:hover svg,
.star-rating > label:hover ~ input:checked ~ label svg,
.star-rating > input:checked ~ label:hover ~ label svg {
	fill: #040E26;
}