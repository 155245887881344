@import "../../styles/mixins";

.help_wrapper {
  display: flex;
  width: 1140px;

  .hw_left, .hw_right {
    width: 50%;
    flex-shrink: 0;
  }

  .hw_left {
    display: flex;
    .hw_photo {
      position: relative;
      flex-shrink: 0;
      width: 170px;
      height: 170px;
      margin-right: 30px;

      @include retina-background-image('./images/dariia', 'png', 170px, 170px);

      &.online {
        &:after {
          display: block;
        }
      };

      &:after {
        display: none;
        content: '';
        position: absolute;
        right: 5px;
        bottom: 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #6AB261;
        border: 5px solid #fff;
      }
    }

    .hw_text {
      h1 {
        font-size: 30px;
        line-height: 30px;
        font-weight: 500;
        text-align: left;
        margin-bottom: 17px;
      }

      p {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 24px;
      }

      dl {
        margin-bottom: 33px;

        dt {
          display: block;
          font-size: 13px;
          line-height: 13px;
          margin-bottom: 6px;
          opacity: .7;
        }

        dd {
          display: block;
          font-size: 16px;
          line-height: 16px;

          a {
            color: #040E26;
          }
        }
      }
    }
  }

  .hw_right {
    position: relative;
    background-color: #F5F5F5;
    border-radius: 16px;
    max-width: 600px;

    .hwr_inner {
      padding: 40px;
      display: flex;
      justify-content: space-between;

      .hw_contacts {
        p {
          position: relative;
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 30px;
          padding-left: 40px;

          svg {
            @include middle;
            left: 0;

            &.help_mail_icon {
              margin-top: 1px;
            }
          }

          a {
            text-decoration: none;
            color: #2450FF;
            border-bottom: 0 solid;
          }
        }
      }

      .hw_qr {
        width: 160px;
        height: 160px;
        flex-shrink: 0;
        text-align: center;

        .qr {
          width: 120px;
          height: 120px;
          border: 20px solid #fff;
          @include retina-background-image('./images/wa-qr', 'png', 120px, 120px);
          margin-bottom: 17px;
        }

        p {
          font-size: 13px;
          line-height: 20px;
          opacity: .7;
        }
      }
    }
  }

  @media screen and (max-width: 1345px) {
    width: auto;
    margin: 0 50px;
    display: block;


    .hw_left, .hw_right {
      width: auto;
      margin-bottom: 60px;
    }

    .hw_left {
      .hw_photo {
        margin-right: 50px;
      }
      .hw_text {
        dl {
          display: inline-block;
          vertical-align: top;
          margin-right: 80px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .hw_right {
      margin: 0 auto 60px;
    }
  }

  @media screen and (max-width: $treshold) {
    margin: 0 20px;
    padding-left: 0;
    padding-right: 0;

    .hw_left, .hw_right {
      margin-bottom: 46px;
    }

    .hw_left {
      flex-direction: row-reverse;
      .hw_photo {
        width: 120px;
        height: 120px;
        background-size: 120px 120px;
        margin-right: 0;
        margin-left: 21px;

        &:after {
          width: 15px;
          height: 15px;
          right: 5px;
          bottom: 5px;
        }
      }
      .hw_text {
        h1 {
          font-size: 22px;
          height: 22px;
          margin-bottom: 19px;
        }
        p {
          margin-bottom: 33px;
        }
      }
    }

    .hw_right {
      width: 100vw;
      margin-left: -20px;
      border-radius: 0;

      .hwr_inner {
        .hw_qr {
          display: none;
        }
      }
    }
  }
}