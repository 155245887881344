@import "../../../../styles/mixins";

.ls_welcome_wrapper {
  min-height: 710px;
  position: relative;
  background-color: #FAFCFF;

  .ls_bg {
    @include center;
    z-index: 0;
    top: 0;
    width: 100%;
    max-width: 1440px;
    height: 100%;

    .bg_text {
      @include center;
      top: 100%;
      margin-top: 22px;
      font-size: 14px;
      line-height: 25px;
      font-weight: 500;
      color: #040E26;
      text-transform: uppercase;
    }

    .circle img {
      width: 100%;
      height: auto;
    }

    .girl {
      position: absolute;
      z-index: 0;
      left: 525px;
      bottom: 0;
      width: 867px;
      height: 690px;
    }

    .create {
      position: absolute;
      top: 20px;
      left: 880px;
      width: 160px;
      height: 160px;
      box-shadow: 20px 20px 30px #939ECB26;
      border-radius: 50%;
    }

    .publish {
      position: absolute;
      top: 165px;
      left: 1085px;
      width: 280px;
      height: 280px;
    }

    .get_money {
      position: absolute;
      top: 495px;
      left: 1010px;
      width: 120px;
      height: 120px;
      box-shadow: 20px 20px 30px #41496726;
      border-radius: 50%;
    }

    .ellipse {
      position: absolute;
      border-radius: 50%;
      left: -60px;
      bottom: 130px;
      width: 120px;
      height: 120px;
      @include gradientDeg(#FAFCFF, #DEE6F5, 129deg);

    }


  }

  .ls_welcome_text {
    padding-top: 56px;
    width: 590px;

    a {
      text-decoration: none;
      color: #2450FF;
      border-bottom: 1px solid #2450FF50;
    }

    h1 {
      font-weight: 600;
      font-size: 40px;
      line-height: 55px;
      margin-bottom: 30px;
    }

    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 50px;
    }

    ul {
      width: 500px;

      &.large_list {
        li {
          font-weight: 500;
          font-size: 21px;
          line-height: 32px;
          margin-bottom: 35px;

          &:before {
            width: 8px;
            height: 8px;
            top: 12px;
          }
        }
      }

      li {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 32px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -22px;
          top: 11px;
          border-radius: 50%;
          background-color: #2450FF;
          width: 7px;
          height: 7px;
        }
      }
    }
  }
  //
  //@media screen and (max-width: $treshold_max) {
  //  .ls_bg {
  //    overflow: hidden;
  //    @include clear;
  //    position: relative;
  //    height: 690px;
  //
  //    .ellipse {
  //      display: none;
  //    }
  //    .girl {
  //      left: 35px;
  //    }
  //
  //    .create {
  //      left: 480px;
  //    }
  //
  //    .publish {
  //      left: 685px;
  //    }
  //
  //    .get_money {
  //      left: 610px;
  //    }
  //  }
  //
  //  .l_content {
  //    margin-bottom: 60px;
  //  }
  //
  //  .ls_welcome_text {
  //    width: auto;
  //
  //    ul {
  //      width: auto;
  //    }
  //  }
  //}

  @media screen and (max-width: $treshold_mid) {
    .ls_welcome_text {
      width: auto;

      ul {
        width: auto;
      }
    }

    .ls_bg {
      overflow: hidden;
      @include clear;
      position: relative;
      height: auto;

      .girl {
        left: 0;
        width: 100%;
        height: auto;
        position: relative;
        bottom: auto;
      }

      .create,
      .publish,
      .get_money {
        width: 120px;
        height: 120px;
        left: auto;
        right: 60px;
      }

      .create {
        top: 0;
      }

      .publish {
        top: 200px
      }

      .get_money {
        top: 400px;
      }
    }
  }

  @media screen and (max-width: 867px) {
    .ls_bg {

      .girl {
        width: 100%;

        img {
          width: 100%;
        }
      }

      .bg_text {
        margin-top: 12px;
      }

      .create,
      .publish,
      .get_money {
        width: 100px;
        height: 100px;
      }

      .publish {
        top: 160px
      }

      .get_money {
        top: 320px
      }
    }
  }

  @media screen and (max-width: $treshold) {
    .ls_bg {

      .bg_text {
        font-size: 10px;
        line-height: 10px;
      }

      .create,
      .publish,
      .get_money {
        width: 60px;
        height: 60px;
        right: 30px;
      }

      .publish {
        top: 100px
      }

      .get_money {
        top: 200px
      }
    }

    .ls_welcome_text {
      h1 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 19px;
      }

      p {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 26px;
      }

      ul {
        margin-left: 15px;

        li {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;

          &:before {
            width: 5px;
            height: 5px;
            left: -15px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold_min) {
    .ls_bg {

      .bg_text {
        font-size: 10px;
        line-height: 10px;
      }

      .create,
      .publish,
      .get_money {
        width: 40px;
        height: 40px;
        right: 30px;
      }

      .publish {
        top: 80px
      }

      .get_money {
        top: 160px
      }
    }
  }
}