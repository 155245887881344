.cem_content {
  .inline_text_input {
    border: 0 solid;
    border-bottom: 1px solid #C0C2C8;
    font-size: 16px;
    line-height: 22px;
    width: 32px;
    display: inline-block;
    margin: 0 8px;
    text-align: center;
  }
}