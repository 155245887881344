@import "../styles/mixins";

.sidebar_profile {
  a {
    text-decoration: none;
  }

  .sp_userdata {
    border-bottom: 2px solid rgba(0,0,0,.1);
    padding: 24px 0;
    text-align: center;
    margin-bottom: 64px;

    .sp_userpic {
      width: 96px;
      height: 96px;
      margin: 0 auto;
      border-radius: 50%;
      position: relative;
      background-color: #fff;
      margin-bottom: 24px;

      span {
        @include center-middle;
        font-size: 72px;
        line-height: 72px;
      }
    }

    .sp_username_text {
      font-size: 16px;
      line-height: 24px;
      color: #040E26;

      &.school {
        font-size: 24px;
        color: #434A5C;
      }
    }

    .sp_role {
      font-size: 16px;
      line-height: 24px;
      color: #818692;
    }
  }

  .sp_links {
    text-align: center;
    a {
      display: block;
      color: #040E26;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;

      span {
        color: #C0C2C8;
      }

      &.ua_logout,
      &.red {
        color: #C43333;
      }
    }
  }
}