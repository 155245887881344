@import "../../styles/mixins";

.radio_wrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 32px;
  white-space: nowrap;
  font-size: 0;
  cursor: pointer;

  .radio_box {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #F7F7F7;
    margin-right: 8px;
    overflow: hidden;
    @include transition(linear 100ms);
  }

  .radio_label {
    font: normal normal 500 16px/16px Manrope;
    display: inline-block;
    vertical-align: top;
    color: #88888F;
    margin-top: 4px;
    @include transition(linear 100ms);
  }

  &.checked {
    .radio_box {
      background-color: #0064FF;

      &:after {
        content: '';
        @include center-middle;
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 5px 5px 12px 0 #00000020;
      }
    }

    .radio_label {
      color: #000;
    }
  }

  &:hover {
    .radio_label {
      color: #000;
    }
  }
}