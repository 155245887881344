@import "../styles/mixins.css";

.profile_courses_list {
  position: relative;
  padding-top: 16px;
  text-align: center;

  .courses_block {
    text-align: left;
    margin-left: 20px;
    padding-bottom: 80px;

    body.no_dashboard & {
      text-align: center;
      margin-left: auto;
    }
  }

  .status_switcher {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 32px;

    a {
      display: inline-block;
      color: #040E26;
      text-decoration: none;
      margin: 0 8px;
      opacity: .5;
      border-bottom: 1px dotted #040E2670;

      &:hover {
        opacity: 1;
      }

      &.active {
        cursor: default;
        opacity: 1;
        border-bottom: 0 solid;
      }
    }
  }

  .pcl_top {
    height: 120px;

    h2 {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
  }

  .no_courses {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    a {
      color: #040E26;
      text-decoration: none;
      border-bottom: 1px solid rgba(0,0,0,.5);
    }
  }
}