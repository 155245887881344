@import "../styles/mixins";


.page_footer {
  padding-top: 8px;
  padding-bottom: 32px;
  position: relative;
  margin: 0 64px;
  border-top: 1px dashed #E6E7EA;

  body.no_footer & {
    display: none !important;
  }

  body.profile_dashboard & {
    padding-left: 174px;
  }

  .footer_links {
    display: flex;
    margin-bottom: 32px;
    flex-wrap: wrap;

    .fl_block,
    .footer_hidden_block {
      margin-right: 96px;
      h5 {
        line-height: 48px;
        font-size: 13px;
        color: #818692;
      }

      p {
        font-size: 16px;
        line-height: 48px;
        a, span {
          white-space: nowrap;
          color: #040E26;
          text-decoration: none;

          &.red {
            color: #C33333;
          }

          &.mail_link {
            border-bottom: 1px solid #040E26;
          }
        }

        span {
          color: #818692;

          &.pseudo_link {
            color: #040E26;
            cursor: pointer;

            &.pl_dashed {
              color: #818692;
              border-bottom: 1px dashed #818692;
            }
          }
        }

        &.tech_support_link {
          body.no_support & {
            display: none;
          }
        }
      }
    }

    .footer_hidden_block {
      display: none;
    }

    .beta_note {
      font-size: 16px;
      line-height: 24px;
      padding-top: 12px;
      margin-right: 96px;

      a {
        color: #040E26;
        text-decoration: none;
        border-bottom: 1px solid #040E26;
        white-space: nowrap;
      }
    }
  }

  .cpr {
    text-align: left;
    font-size: 16px;
    line-height: 16px;
    color: #E6E7EA;
    white-space: nowrap;
  }

  @media screen and (max-width: $treshold) {
    height: auto;
    margin: 0 24px;

    .footer_links {
      display: block;

      &.footer_shown {
        .footer_hidden_block {
          display: none;
        }

        .fl_block {
          display: block;
        }
      }

      .footer_hidden_block {
        display: block;
      }

      .beta_note {
        margin: 0;
      }

      .fl_block {
        display: none;
      }
    }
  }
}