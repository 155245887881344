@import "../../../../styles/mixins";

.cl_purchase {
  padding: 80px 0;
  background-color: #2450FF;

  .purchase_block {
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;

    .part {
      position: relative;
      width: calc(50% - 120px);
      padding: 68px 60px 190px;

      &.right {
        background-color: #F5F5F5;
      }
    }

    h2 {
      margin-bottom: 43px;
    }

    .price_block {
      margin-bottom: 37px;

      .discount_text {
        font-size: 17px;
        line-height: 17px;
        opacity: .7;
        margin-bottom: 8px;
      }

      .actual_price {
        font-size: 48px;
        line-height: 48px;
        font-weight: 600;
      }

      .regular_price_block {
        margin-top: 39px;

        p {
          font-size: 15px;
          line-height: 15px;
          opacity: .5;
          margin-bottom: 9px;
        }

        span {
          font-size: 22px;
          line-height: 22px;
          font-weight: 600;
          text-decoration: line-through;
          opacity: .5;
        }
      }
    }

    .labels_block {
      @include center;
      bottom: 60px;
      width: calc(100% - 120px);
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .u_kassa {
        display: block;
        flex-shrink: 0;
        margin-right: 60px;
        width: 173px;
        height: 60px;
        @include retina-background-image('./images/u-kassa', 'png', 173px, 60px);
      }

      .certificate {
        display: none;
        position: relative;
        padding-left: 55px;

        svg {
          @include middle;
          left: 0;
          width: 40px;
          height: 40px;
        }

        span {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }

    .additional_params {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .date_and_slots {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;

        p {
          font-size: 15px;
          line-height: 15px;
          margin-right: 30px;

          &:last-of-type {
            margin-right: 0;
          }

          span {
            font-weight: 600;
          }
        }
      }

      .age_restriction {
        display: inline-block;
        font-size: 13px;
        line-height: 13px;
        font-weight: 600;
        padding: 8px 15px 9px;
        border-radius: 20px;
        border: 1px solid #040E26;
      }
    }

    .buttons_holder {
      position: absolute;
      @include center;
      bottom: 60px;
      width: calc(100% - 120px);

      button {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 20px 0;

    .purchase_block {
      flex-wrap: wrap;

      .part {
        width: 100%;
        padding: 40px 20px 33px;
      }

      .labels_block {
        @include clear;
        position: relative;
        width: auto;
        flex-wrap: wrap;

        .u_kassa {
          margin-bottom: 30px;
        }

        .certificate {
          width: 140px;
        }
      }

      .additional_params {
        margin-bottom: 37px;
      }

      .buttons_holder {
        @include clear;
        position: relative;
        width: auto;
      }
    }
  }
}