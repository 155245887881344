@import "../../../../styles/mixins";

.cl_reviews {
  padding: 68px 0 72px;

  .reviews_list {
    .review_item {
      position: relative;
      margin-bottom: 30px;
      padding: 30px;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #040E2610;
      display: flex;
      justify-content: space-between;

      .user {
        position: relative;
        padding-left: 75px;
        margin-right: 45px;
        width: 150px;
        height: auto;
        flex-shrink: 0;

        .userpic {
          @include middle;
          left: 0;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #040E26;

          img, span {
            @include center-middle;
          }

          span {
            font-size: 32px;
            line-height: 32px;
            color: #fff;
            font-weight: 500;
          }
        }

        .username {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          margin-bottom: 4px;

          a {
            text-decoration: none;
            color: unset;
          }
        }

        .review_date {
          font-size: 13px;
          line-height: 13px;
          opacity: .7;
        }

        .stars {
          display: none;

          svg {
            path {
              opacity: 1;
              fill: #E4E4E4;

              &.filled {
                fill: #FFD500;
              }
            }
          }
        }
      }

      .text {
        width: 100%;
        font-size: 17px;
        line-height: 25px;
        margin-top: -4px;
      }

      .score {
        flex-shrink: 0;

        svg {
          path {
            opacity: 1;
            fill: #E4E4E4;

            &.filled {
              fill: #FFD500;
            }
          }
        }
      }
    }
  }

  @include adaptive($adaptive_course_mid) {
    .reviews_list {
      .review_item {
        padding-top: 131px;

        .user {
          position: absolute;
          left: 30px;
          top: 30px;
        }

        .score {
          position: absolute;
          right: 30px;
          top: 40px;
        }
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding-bottom: 30px;

    .reviews_list {
      .review_item {
        padding: 118px 20px 37px;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .user {
          left: 20px;

          .stars {
            display: block;
            margin-top: 8px;
          }
        }

        .text {
          font-size: 15px;
          line-height: 20px;
        }

        .score {
          display: none;
        }
      }
    }
  }
}