@import "../../../styles/mixins";

.lecturer_dashboard {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 320px - 60px);
  z-index: 50;
  background: rgba(255,255,255,.5);
  padding: 30px;

  h4 {
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
  }

  h5 {
    margin-bottom: 25px;
  }

  .broadcast_settings_form {

    .default_form {
      button {
        margin-top: 18px;
        margin-left: 5px;
      }
    }
    .input_block {
      width: calc(100% - 150px);

    }

    .copy_textfield {
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      height: 0;
      width: 0;
      padding: 0;
      opacity: 0;
    }

    .broadcast_manual {
      font-size: 14px;
      line-height: 17px;

      ol {
        margin-left: 16px;
        margin-bottom: 17px;

        li {
          list-style: decimal;
        }
      }

      p {
        margin-bottom: 17px;
      }
    }
  }
}