@import "../../../../styles/mixins";

.ls_profitable_wrapper {
  background-color: #F8F8FA;
  padding-top: 100px;
  padding-bottom: 30px;

  h2 {
    margin-bottom: 70px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-items: flex-start;
    align-items: flex-start;

    li {
      position: relative;
      width: 367px;
      height: 380px;
      box-shadow: 20px 20px 40px #0016520D;
      border-radius: 20px;
      margin-right: 35px;
      margin-bottom: 35px;

      &:nth-of-type(1) { background-color: #FFC8D8; }
      &:nth-of-type(2) { background-color: #D8D6FF; }
      &:nth-of-type(3) { background-color: #CFFFE8; }
      &:nth-of-type(4) { background-color: #fff; }
      &:nth-of-type(5) { background-color: #FFFCF2; margin-right: 0; }

      .item_bg {
        @include center;
        z-index: 0;
        bottom: 0;

        &.corner {
          width: calc(100% - 80px);
          text-align: right;
          bottom: 28px;
        }

        img {
          width: auto;

          &.pic_1 {
            height: 260px;
          }

          &.pic_2 {
            height: 282px;
          }

          &.pic_3 {
            height: 278px;
          }

          &.pic_4 {
            height: 258px;
          }

          &.pic_5 {
            display: inline-block;
            height: 96px;
          }
        }
      }

      .item_text {
        margin: 0 40px;
        padding-top: 32px;
        font-size: 24px;
        line-height: 35px;
        font-weight: 500;

        ul {
          width: auto;
          padding-top: 18px;
          font-size: 18px;
          line-height: 27px;
          font-weight: 400;
          display: block;

          li {
            position: relative;
            padding-left: 32px;
            width: auto;
            height: auto;
            margin: 0 0 18px;
            border-radius: 0;
            box-shadow: none;
            background-color: transparent !important;


            &:before {
              position: absolute;
              top: 0;
              left: 0;
              content: '—';
            }
          }
        }
      }

    }
  }

  @media screen and (max-width: $treshold_mid) {
    ul {
      li {
        width: 288px;
        height: 328px;
        margin-right: 24px;
        margin-bottom: 24px;

        .item_bg {

          &.corner {
            width: calc(100% - 17px);
            bottom: 8px;
          }

          img {
            &.pic_1 {
              height: 202px;
            }

            &.pic_2 {
              height: 187px;
            }

            &.pic_3 {
              height: 213px;
            }

            &.pic_4 {
              height: 186px;
            }

            &.pic_5 {
              display: inline-block;
              height: 66px;
            }
          }
        }

        .item_text {
          margin: 0 28px;
          padding-top: 28px;
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding: 46px 0 0;

    h2 {
      margin-bottom: 28px;
    }

    .l_content {
      //margin: 0;
    }

    ul {
      flex-wrap: nowrap;
      overflow: auto;
      width: 100vw;
      margin-left: -25px;
      padding-bottom: 28px;

      li {
        flex-shrink: 0;
        height: 328px;
        width: calc(100vw - 80px);

        &:first-of-type {
          margin-left: 25px;
        }

        &:last-of-type {
          margin-right: 25px;
        }

        .item_bg {

          &.corner {
            width: calc(100% - 17px);
            bottom: 8px;
          }

          img {
            &.pic_1 {
              height: 202px;
            }

            &.pic_2 {
              height: 187px;
            }

            &.pic_3 {
              height: 213px;
            }

            &.pic_4 {
              height: 186px;
            }

            &.pic_5 {
              display: inline-block;
              height: 66px;
            }
          }
        }

        .item_text {
          font-size: 18px;
          line-height: 25px;

          ul {
            font-size: 13px;
            line-height: 18px;
            margin-left: 0;
          }
        }
      }
    }
  }
}