@import "../../styles/mixins";

.school_landing_wrapper {
  .ls_advantages_wrapper {
    margin-top: 0;
  }

  .ls_payments_wrapper {
    @media screen and (max-width: $treshold) {
      padding-bottom: 40px;
      padding-top: 0;
    }
  }
}