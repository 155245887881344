@import "../../styles/mixins";

.crop_resize_wrapper {
  position: relative;
  font-size: 0;
  white-space: nowrap;

  .reactEasyCrop_CropArea {
    box-shadow: 0 0 0 150px #00000090;
  }

  h4 {
    font: normal normal 600 24px/24px Manrope;
    color: #000000;
    margin-bottom: 4px;
  }

  h6 {
    font: normal normal 500 12px/24px Manrope;
    color: #88888F;
    margin-bottom: 36px;
  }

  .crop_tools {
    margin-left: 24px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    width: 300px;
  }

  .orientation_wrapper {
    margin-bottom: 32px;

    .radio_wrapper {
      margin-bottom: 16px;
    }
  }

  .cropper_wrapper {
    position: relative;
    overflow: hidden;
    width: 400px;
    height: 400px;
    background-color: #F7F7F7;
    display: inline-block;
    vertical-align: top;

    .reactEasyCrop_CropAreaGrid,
    .reactEasyCrop_CropAreaGrid:before,
    .reactEasyCrop_CropAreaGrid:after {
      border-color: #F7F7F790;
    }
  }

  .crop_buttons {
    button {
      cursor: pointer;
      font: normal normal 600 14px/14px Manrope;
      padding: 18px 0 19px;
      width: 100%;
      color: #0064FF;

      border: 2px solid #0064FF;
      border-radius: 28px;
      background-color: #fff;
      margin-bottom: 16px;

      &.ok_button {
        background-color: #0064FF;
        color: #fff;
        margin-right: 24px;
      }
    }
  }

  .zoom_slider {
    margin: 0 12px 32px;

    .rc-slider-handle {
      background-color: #0064FF;

      &:after {
        content: '';
        @include center-middle;
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 5px 5px 12px 0 #00000020;
      }
    }

    .rc-slider-track,
    .rc-slider-rail {
      background-color: #F7F7F7;
    }
  }

  @include adaptive($treshold) {
    white-space: normal;
    padding: 24px 24px 40px;
    text-align: center;

    .orientation_wrapper {
      white-space: nowrap;
    }

    .crop_tools {
      width: auto;
      text-align: left;
    }

    .cropper_wrapper {
      margin-bottom: 24px;
    }

    .crop_buttons {
      white-space: nowrap;

      button {
        width: 151px;
        margin-right: 16px;
        margin-bottom: 16px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  @include adaptive($treshold_min) {

    .cropper_wrapper {
      height: calc(100vw - 48px);
    }

    .orientation_wrapper {
      .radio_wrapper {
        display: block;
        margin-bottom: 16px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}