.ls_comparison_wrapper {
  position: relative;
  padding: 100px 0 20px;
  background: #F8F8FA;
  /* Old browsers */
  background: -moz-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F8F8FA), color-stop(100%, #fff));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #F8F8FA 0%, #fff 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #F8F8FA 0%, #fff 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
  /* IE6-9 */ }
  .ls_comparison_wrapper .button_holder {
    padding-top: 140px;
    text-align: center; }
  .ls_comparison_wrapper .ls_comparison_block {
    display: none; }
  .ls_comparison_wrapper .switcher {
    width: 900px;
    margin: 0 auto 160px;
    white-space: nowrap;
    border: 1px solid #444750;
    overflow: hidden;
    border-radius: 30px; }
    .ls_comparison_wrapper .switcher .switch {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      font-size: 15px;
      line-height: 15px;
      padding: 23px 0 22px;
      text-align: center;
      color: #444750;
      cursor: pointer; }
      .ls_comparison_wrapper .switcher .switch.active {
        background-color: #040E26;
        color: #fff;
        cursor: default; }
  .ls_comparison_wrapper .plates {
    background: #fff;
    /* Old browsers */
    background: -moz-linear-gradient(top, #fff 0%, #F8F8FA 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #F8F8FA));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #fff 0%, #F8F8FA 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #fff 0%, #F8F8FA 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #fff 0%, #F8F8FA 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #fff 0%, #F8F8FA 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
    /* IE6-9 */
    border-radius: 20px;
    padding: 100px 0 140px;
    position: relative; }
    .ls_comparison_wrapper .plates .plate_bg {
      position: absolute;
      top: 50%;
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 1;
      right: 0;
      width: 50%;
      height: calc(100% + 120px);
      border-radius: 20px;
      background-color: #2450FF;
      overflow: hidden; }
      .ls_comparison_wrapper .plates .plate_bg:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 400px;
        background: rgba(36, 80, 255, 0);
        /* Old browsers */
        background: -moz-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(36, 80, 255, 0)), color-stop(100%, #4114BC));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* IE10+ */
        background: linear-gradient(to bottom, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
        /* IE6-9 */ }
    .ls_comparison_wrapper .plates .plates_vs {
      position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 2;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 20px 20px 30px rgba(58, 66, 95, 0.3); }
      .ls_comparison_wrapper .plates .plates_vs img {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .ls_comparison_wrapper .plates .plates_inner {
      position: relative;
      z-index: 10;
      width: 1050px;
      margin: 0 auto;
      white-space: nowrap;
      font-size: 0; }
      .ls_comparison_wrapper .plates .plates_inner h2, .ls_comparison_wrapper .plates .plates_inner dl {
        width: 405px;
        display: inline-block;
        vertical-align: top;
        white-space: normal; }
        .ls_comparison_wrapper .plates .plates_inner h2:first-of-type, .ls_comparison_wrapper .plates .plates_inner dl:first-of-type {
          margin-right: 240px; }
      .ls_comparison_wrapper .plates .plates_inner .plates_row {
        margin-bottom: 46px; }
        .ls_comparison_wrapper .plates .plates_inner .plates_row:first-of-type {
          margin-bottom: 44px; }
      .ls_comparison_wrapper .plates .plates_inner h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600; }
        .ls_comparison_wrapper .plates .plates_inner h2.whub {
          color: #fff; }
      .ls_comparison_wrapper .plates .plates_inner dl dt {
        display: block;
        font-size: 14px;
        line-height: 25px;
        color: #444750;
        padding-bottom: 11px;
        margin-bottom: 15px;
        border-bottom: 1px solid #E6E6E6; }
      .ls_comparison_wrapper .plates .plates_inner dl dd {
        display: block;
        font-size: 18px;
        line-height: 25px; }
      .ls_comparison_wrapper .plates .plates_inner dl.whub dt {
        color: #64D3FF; }
      .ls_comparison_wrapper .plates .plates_inner dl.whub dd {
        color: #fff; }
  @media screen and (max-width: 1440px) {
    .ls_comparison_wrapper .plates .plates_vs {
      width: 100px;
      height: 100px; }
    .ls_comparison_wrapper .plates .plates_inner {
      width: 834px; }
      .ls_comparison_wrapper .plates .plates_inner h2, .ls_comparison_wrapper .plates .plates_inner dl {
        width: 362px; }
        .ls_comparison_wrapper .plates .plates_inner h2:first-of-type, .ls_comparison_wrapper .plates .plates_inner dl:first-of-type {
          margin-right: 110px; } }
  @media screen and (max-width: 600px) {
    .ls_comparison_wrapper {
      padding-top: 0;
      padding-bottom: 0; }
      .ls_comparison_wrapper .l_centrer {
        display: none; }
      .ls_comparison_wrapper .ls_comparison_block {
        display: block;
        padding-top: 51px;
        padding-bottom: 35px;
        background: #F8F8FA;
        /* Old browsers */
        background: -moz-linear-gradient(top, #F8F8FA 0%, #fff 100%);
        /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #F8F8FA), color-stop(100%, #fff));
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top, #F8F8FA 0%, #fff 100%);
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, #F8F8FA 0%, #fff 100%);
        /* Opera 11.10+ */
        background: -ms-linear-gradient(top, #F8F8FA 0%, #fff 100%);
        /* IE10+ */
        background: linear-gradient(to bottom, #F8F8FA 0%, #fff 100%);
        /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
        /* IE6-9 */ }
        .ls_comparison_wrapper .ls_comparison_block h2 {
          margin-bottom: 35px; }
        .ls_comparison_wrapper .ls_comparison_block .plate {
          background: #fff;
          /* Old browsers */
          background: -moz-linear-gradient(top, #fff 0%, #F8F8FA 100%);
          /* FF3.6+ */
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #F8F8FA));
          /* Chrome,Safari4+ */
          background: -webkit-linear-gradient(top, #fff 0%, #F8F8FA 100%);
          /* Chrome10+,Safari5.1+ */
          background: -o-linear-gradient(top, #fff 0%, #F8F8FA 100%);
          /* Opera 11.10+ */
          background: -ms-linear-gradient(top, #fff 0%, #F8F8FA 100%);
          /* IE10+ */
          background: linear-gradient(to bottom, #fff 0%, #F8F8FA 100%);
          /* W3C */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
          /* IE6-9 */
          border-radius: 20px;
          overflow: hidden;
          position: relative;
          padding: 10px 10px 47px; }
          .ls_comparison_wrapper .ls_comparison_block .plate .plate_switcher {
            white-space: nowrap;
            border: 1px solid #2450FF;
            overflow: hidden;
            border-radius: 10px;
            font-size: 0;
            margin-bottom: 40px; }
            .ls_comparison_wrapper .ls_comparison_block .plate .plate_switcher .switch {
              display: inline-block;
              vertical-align: top;
              text-align: center;
              font-size: 12px;
              line-height: 12px;
              font-weight: 600;
              padding: 14px 0;
              width: 50%;
              color: #2450FF; }
              .ls_comparison_wrapper .ls_comparison_block .plate .plate_switcher .switch:first-of-type {
                background-color: #2450FF;
                color: #fff; }
          .ls_comparison_wrapper .ls_comparison_block .plate .plates_row {
            padding: 0 20px;
            margin-bottom: 27px; }
            .ls_comparison_wrapper .ls_comparison_block .plate .plates_row:last-of-type {
              margin-bottom: 0; }
          .ls_comparison_wrapper .ls_comparison_block .plate dl {
            display: none; }
            .ls_comparison_wrapper .ls_comparison_block .plate dl:last-of-type {
              margin-bottom: 0; }
            .ls_comparison_wrapper .ls_comparison_block .plate dl dt {
              display: block;
              font-size: 12px;
              line-height: 18px;
              color: #444750;
              padding-bottom: 6px;
              margin-bottom: 13px;
              border-bottom: 1px solid #E6E6E6; }
            .ls_comparison_wrapper .ls_comparison_block .plate dl dd {
              display: block;
              font-size: 14px;
              line-height: 20px; }
            .ls_comparison_wrapper .ls_comparison_block .plate dl.whub dt {
              color: #64D3FF; }
            .ls_comparison_wrapper .ls_comparison_block .plate dl.whub dd {
              color: #fff; }
          .ls_comparison_wrapper .ls_comparison_block .plate.other dl.other {
            display: block; }
          .ls_comparison_wrapper .ls_comparison_block .plate.whub {
            background: #2450FF;
            /* Old browsers */
            background: -moz-linear-gradient(top, #2450FF 0%, #2450FF 100%);
            /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2450FF), color-stop(100%, #2450FF));
            /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top, #2450FF 0%, #2450FF 100%);
            /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top, #2450FF 0%, #2450FF 100%);
            /* Opera 11.10+ */
            background: -ms-linear-gradient(top, #2450FF 0%, #2450FF 100%);
            /* IE10+ */
            background: linear-gradient(to bottom, #2450FF 0%, #2450FF 100%);
            /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
            /* IE6-9 */ }
            .ls_comparison_wrapper .ls_comparison_block .plate.whub .plate_switcher {
              border-color: #fff; }
              .ls_comparison_wrapper .ls_comparison_block .plate.whub .plate_switcher .switch:last-of-type {
                background-color: #fff; }
            .ls_comparison_wrapper .ls_comparison_block .plate.whub:after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 0;
              width: 100%;
              height: 200px;
              background: rgba(36, 80, 255, 0);
              /* Old browsers */
              background: -moz-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
              /* FF3.6+ */
              background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(36, 80, 255, 0)), color-stop(100%, #4114BC));
              /* Chrome,Safari4+ */
              background: -webkit-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
              /* Chrome10+,Safari5.1+ */
              background: -o-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
              /* Opera 11.10+ */
              background: -ms-linear-gradient(top, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
              /* IE10+ */
              background: linear-gradient(to bottom, rgba(36, 80, 255, 0) 0%, #4114BC 100%);
              /* W3C */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir );
              /* IE6-9 */ }
            .ls_comparison_wrapper .ls_comparison_block .plate.whub dl.whub {
              display: block; } }

