@import "../../../../../styles/mixins";

.cl_header {
  .header_date_start {
    display: inline-block;
    vertical-align: top;
    font-size: 13px;
    line-height: 18px;
    color: #fff;
    padding-top: 2px;
    margin-bottom: 48px;

    @include adaptive($treshold) {
      display: block;
      margin-bottom: 35px;
    }
  }
}