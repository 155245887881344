@import "../../../styles/mixins";

.course_dl_wrapper {
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #00000016;
  min-width: 600px;

  .dl_item_delete {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  dl {
    width: calc(100% - 64px);

    dt {
      display: block;
      margin-bottom: 8px;

      input {
        width: calc(100% - 18px);
        padding: 4px 8px;
        font-size: 16px;
        line-height: 16px;
        border: 1px solid #C0C2C8;
        color: #434A5C;

        @include input-placeholder {
          color: #C0C2C8;
        }
      }
    }

    dd {
      textarea {
        display: block;
        width: calc(100% - 18px);
        height: 48px;
        padding: 4px 8px;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #C0C2C8;
        color: #434A5C;

        @include input-placeholder {
          color: #C0C2C8;
        }
      }
    }
  }

  &.dl_video {

    dl {
      dt {
        .video_input_holder {
          display: flex;
          flex-wrap: nowrap;

          button {
            flex-shrink: 0;
          }
        }

        .hint {
          font-size: 10px;
          line-height: 16px;
          color: #C0C2C8;
        }

        .invalid {
          font-size: 10px;
          line-height: 16px;
          color: #C00000;
        }

        .player_v2_wrapper {
          height: 200px;
        }
      }
    }
  }
}