@import "mixins";

a button, a.button_link {
  text-decoration: none !important;
}

button {
  width: 256px;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding: 19px 0;
  color: #fff;
  background-color: #040E26;
  border: 1px solid #040E26;
  white-space: nowrap;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: pointer;

  &.v2 {
    width: auto;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
    color: #fff;
    padding: 22px 35px 21px;
    background-color: #2450FF;
    border: 1px solid #2450FF;
    border-radius: 30px;

    &:disabled {
      opacity: .5;
    }

    &.light {
      background-color: transparent;
      border-color: #ffffff50;
      color: #fff;
    }
    &.light_inverted {
      background-color: transparent;
      border-color: #040E2650;
      color: #040E26;
    }
  }

  &.no_style {
    width: unset;
    height: unset;
    padding: unset;
    background-color: unset;
    border: 0 solid;
  }

  &:disabled {
    opacity: .3;
    cursor: default;
  }

  &.light {
    color: #040E26;
    background-color: #fff;
    border-color: #fff;
  }
  &.light_border {
    color: #040E26;
    background-color: #fff;
    border-color: #040E26;
    padding: 19px 0;
  }

  &.small {
    padding: 8px 20px;
    width: auto;
  }

  &.red {
    background-color: #fff;
    color: #C33333;
    border-color: #C33333;
  }

  &.preview {
    font-size: 16px;
    line-height: 16px;
    width: unset;
    padding: 7px 20px 9px;
    border: 1px solid #2450FF50;
    color: #2450FF;
    border-radius: 20px;
    background-color: #fff;
    font-weight: 500;

    @include transition(border-color ease-out 150ms);

    &:hover {
      border-color: #2450FF;
    }

    &.discount {
      border-color: #F02D2D50;
      color: #F02D2D;

      &:hover {
        border-color: #F02D2D;
      }
    }
  }
}