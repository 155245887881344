@import "../styles/mixins";

.header_v2 {
  .search_suggests_list {
    border: 1px solid #E8E8E8;
    border-top: 0 solid;
    background-color: #fff;
    margin-top: 24px;

    li {
      border-bottom: 0 solid;
    }

    @media screen and (max-width: $treshold_mid) {
      margin-top: 0;
    }
  }
}

.search_suggests_list {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 30;
  width: 100%;
  background-color: #fff;

  li {
    padding: 4px 10px;
    border-bottom: 1px solid #C0C2C8;
    cursor: pointer;
    font-size: 13px;
    line-height: 19px;

    a, span {
      text-decoration: none;
      display: block;
      color: unset;
    }

    &:last-of-type {
      border-bottom: 0 solid;
    }

    &.selected {
      background-color: #E6E7EA;
    }
  }
}