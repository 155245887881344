@import "../../../styles/mixins";

.school_profile_form {
  text-align: left;
  margin: 0 30px;
  padding-bottom: 64px;

  dl {
    text-align: left;
    display: flex;
    margin-bottom: 32px;

    dt {
      width: 160px;
      margin-right: 20px;
      flex-shrink: 0;
    }

    dd {
      width: 100%;

      a {
        color: #2450FF;
        text-decoration: none;
        border-bottom: 1px solid #2450FF50;
      }

      .dropdown {
        margin-top: -2px;
      }

      input {
        width: calc(100% - 16px);
        padding: 4px 8px;
        border: 1px solid #434A5C;
      }

      textarea {
        width: calc(100% - 16px);
        padding: 8px;
        height: 100px;
      }
    }
  }

  .school_contacts {
    text-align: left;
    h2 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      margin-left: 180px;
    }

    h3 {
      font-size: 15px;
      line-height: 18px;
      opacity: .5;
      margin-left: 180px;
      margin-bottom: 18px;
    }
    dl {
      width: 70%;
    }
  }

  .button_holder {
    margin-left: 180px;
    text-align: left;
  }
}