@import "../../styles/mixins";

.lesson_content {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 70px - 340px);

  .lesson_inner {
    position: relative;
    width: 800px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .lesson_top {
    position: relative;
    overflow: hidden;
    z-index: 10;
    color: #434A5C;
    margin-bottom: 25px;

    .bg {
      height: 100%;
      position: absolute;
      z-index: 0;
      width: 100%;
      left: 0;
      top: 0;

      img {
        width: 100%;
        opacity: 0;
      }
    }

    h1 {
      position: relative;
      font-size: 23px;
      line-height: 30px;
      font-weight: 500;
    }

    .sidebar_toggler {
      width: 100%;
      margin-top: 45px;
      display: none;
    }
  }

  @media screen and (max-width: $adaptive_course_mid) {
    .lesson_top {
      margin-bottom: 50px;

      .sidebar_toggler {
        display: block;
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    width: auto;
    margin: 0 50px;

    .lesson_inner {
      width: 100%;
    }
  }

  @media screen and (max-width: $treshold) {
    width: auto;
    margin: 0 20px;

    .lesson_top {
      margin-bottom: 30px;

      .sidebar_toggler {
        margin-top: 25px;
      }
    }
  }
}