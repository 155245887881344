@import "../../../../styles/mixins";

.cl_requirements {
  background-color: #F5F5F5;
  padding: 68px 0 72px;

  .cl_centrer {
    display: flex;

    h2 {
      width: 50%;
    }

    .requirements_block {
      width: 50%;
      font-size: 17px;
      line-height: 25px;
    }
  }

  @include adaptive($adaptive_course_mid) {
    .cl_centrer {
      display: block;

      h2 {
        width: auto;
        margin-bottom: 40px;
      }

      .requirements_block {
        width: auto;
      }
    }
  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0 50px;

    .cl_centrer {

      h2 {
        margin-bottom: 31px;
      }
    }
  }
}