@import "../styles/mixins";

.form_with_description {
  width: 532px;
  display: flex;
  margin-left: -196px;


  .sign_up_form {
    padding-top: 20px;
  }

  .signup_form_description {
    flex-shrink: 0;
    width: 300px;
    margin: 0 16px 16px;
    background-color: #F2F0F0;
    padding: 24px;
    text-align: left;


    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;

      &.reg_join_free {
        font-weight: bold;
        position: relative;
        padding-right: 20px;

        ins {
          position: relative;
          display: inline-block;
          vertical-align: top;

          svg {
            position: absolute;
            top: 0;
            left: 0;
            @include transform(rotate(180deg));
            width: 24px;
            height: 24px;

            path {
              stroke: #C0C2C8;
            }
          }
        }
      }

      & + ul {
        margin-top: -24px;
        margin-bottom: 24px;
      }
    }

    ul {
      li {
        position: relative;
        font-size: 16px;
        line-height: 24px;
        padding-left: 16px;
        white-space: normal;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 11px;
          width: 4px;
          height: 4px;
          background-color: #C0C2C8;
          border-radius: 50%;
        }
      }
    }
  }

  @media screen and (max-width: $treshold_mid) {
    width: 690px;
    margin: 0 auto;
  }

  @media screen and (max-width: 780px) {
    width: auto;

    .sign_up_form {
      padding-top: 0;
    }

    .signup_form_description {
      display: none;
    }
  }
}

.sign_up_form {
  text-align: left;
  position: relative;
  max-width: 300px;
  margin:  0 auto;

  &.success {
    .suf_wrapper {
      display: none;
    }
    .success_message {
      display: block;
    }
  }

  .success_message {
    display: none;
    text-align: center;

    p {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 30px;

      span {
        font-weight: bold;
      }
    }
  }

  .text_input {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
  }

  .school_switcher {
    background-color: #F2F0F0;
    border-radius: 16px;
    padding: 8px 24px;
    margin-bottom: 24px;

    .checkbox {
      margin-bottom: 0;
      display: block;
    }
  }

  .checkbox {
    margin-bottom: 45px;
  }

  .beta_test_disclaimer {
    font-size: 13px;
    line-height: 19px;
    margin-top: -16px;
    margin-bottom: 32px;

    a {
      color: #040E26;
    }
  }

  button {
    display: block;
    margin: 0 auto;
  }
}