@import "../styles/mixins";

.school_services {
  .ls_payments_wrapper {
    padding-bottom: 45px;
  }

  .btn_holder {
    text-align: center;
  }
}

.about_wrapper {
  width: 670px;
  padding-top: 128px;
  padding-bottom: 64px;
  margin: 0 auto;

  h1 {
    text-align: center;
    margin-bottom: 64px;
  }

  h3 {
    margin-bottom: 24px;
  }

  p, ul {
    a {
      color: #040E26;
      text-decoration: none;
      border-bottom: 1px solid #040E26;

      &.img_link {
        border: 0 solid;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;

    img {
      display: inline;
    }

    &.social_link {
      display: flex;
      align-items: center;

      a {
        font-weight: 500;

        &.icon_link {
          border-bottom: 0 solid;
          margin-right: 16px;
          margin-top: 8px;
        }
      }
    }
  }

  p + ul {
    margin-top: -16px;
  }

  ul {
    font-size: 16px;
    line-height: 24px;

    li {
      position: relative;
      padding-left: 16px;
      margin-bottom: 16px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #040E26;
      }
    }
  }

  @media screen and (max-width: $treshold) {
    width: auto;
    padding: 128px 16px 64px;
  }
}

.payments_page {
  p {
  }
}

.faq_page {
  h2 {
    text-align: center;
    margin-bottom: 24px;
  }
  dl {
    margin-bottom: 64px;
    font-size: 16px;
    line-height: 24px;

    dt {
      font-weight: bold;
      margin-bottom: 24px;
    }

    dd {

    }
  }

  .faq_bottom {
    position: relative;
    text-align: center;
    padding-top: 64px;

    &:before {
      content: '';
      @include center;
      top: 0;
      width: 480px;
      height: 1px;
      background-color: #818692;
    }
  }
}