@import "../../../../styles/mixins";

.cl_authors {
  padding: 68px 0 72px;

  .authors_carousel {
    position: relative;

    .feed_holder {
      overflow: hidden;
      text-align: left;

      .feed {
        display: flex;
        flex-wrap: nowrap;

        @include transition(margin-left ease-out 300ms);

        .feed_item {
          width: calc(370px - 62px);
          flex-shrink: 0;
          margin-right: 30px;
          padding: 52px 30px 60px;
          background-color: #fff;
          border-radius: 10px;
          border: 1px solid #040E2610;

          h5 {
            position: relative;
            padding-left: 100px;
            font-size: 22px;
            line-height: 22px;
            font-weight: 600;
            margin-bottom: 72px;

            .userpic {
              @include middle;
              left: 0;
              width: 80px;
              height: 80px;
              border-radius: 50%;
              overflow: hidden;
              background-color: #040E26;

              img, span {
                @include center-middle;
              }

              span {
                font-size: 32px;
                line-height: 32px;
                color: #fff;
                font-weight: 500;
              }
            }
          }

          .description {
            font-size: 17px;
            line-height: 25px;

          }
        }
      }
    }

    .arr {
      @include middle;
      width: 10px;
      height: 20px;
      overflow: hidden;
      cursor: pointer;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &:before {
        content: '';
        position: absolute;
        top: -6px;
        width: 30px;
        height: 30px;
        border: 1px solid #040E26;
        @include rotate(45deg);
      }

      &.arr_left {
        right: 100%;
        margin-right: 30px;

        &:before {
          left: 7px;
        }
      }

      &.arr_right {
        left: 100%;
        margin-left: 30px;

        &:before {
          right: 7px;
        }
      }
    }

  }

  @include adaptive($adaptive_course_min) {
    padding: 41px 0;

    .cl_centrer {
      margin: 0;

      h2 {
        margin: 0 20px 35px;
      }
    }

    .authors_carousel {
      .feed_holder {
        .feed {
          .feed_item {
            width: calc(290px - 40px - 2px);
            padding: 36px 20px 30px;
            margin-right: 20px;

            &:first-of-type {
              margin-left: 20px;
            }

            h5 {
              padding-left: 80px;
              font-size: 17px;
              line-height: 25px;
              margin-bottom: 41px;

              .userpic {
                width: 60px;
                height: 60px;
              }
            }
          }
        }
      }
    }

    .arr {
      display: none;
    }
  }
}