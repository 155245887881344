@import "../../../../../styles/mixins";

.cl_header {
  .header_buttons {
    flex-shrink: 0;
    position: relative;
    width: calc(370px - 80px - 2px);
    margin: 80px 0 40px;
    padding: 40px;
    border: 1px solid #FFFFFF33;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: #fff;
    overflow: hidden;

    .favourite_link {
      display: inline-block;
      font-size: 15px;
      line-height: 15px;
      border-bottom: 1px dashed #ffffff50;
      margin-bottom: 50px;
      cursor: pointer;
      opacity: .7;

      &.favourited {
        border-bottom-color: transparent;
        cursor: default;
      }
    }

    .share_holder {
      position: absolute;
      z-index: 2;
      right: 40px;
      top: 35px;
      width: 30px;
      height: 30px;
    }

    .price_block {
      margin-bottom: 37px;

      .discount_text {
        font-size: 15px;
        line-height: 15px;
        opacity: .7;
        margin-bottom: 10px;
      }

      .actual_price {
        font-size: 40px;
        line-height: 40px;
        font-weight: 500;
      }

      .regular_price_block {
        margin-top: 20px;

        p {
          font-size: 13px;
          line-height: 13px;
          opacity: .5;
          margin-bottom: 10px;
        }

        span {
          font-size: 22px;
          line-height: 22px;
          font-weight: 500;
          text-decoration: line-through;
          opacity: .5;
        }
      }
    }

    button, a {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      text-decoration: none;

      //&:last-of-type {
      //  margin-bottom: 0;
      //}
    }

    .school_text {
      margin-bottom: 35px;
      font-weight: 500;
    }

    .report_link_wrapper {
      text-align: center;

      .report_link {
        display: inline-block;
        font-size: 15px;
        line-height: 15px;
        border-bottom: 1px dashed #ffffff50;
        cursor: pointer;
        opacity: .7;
      }
    }


    @include adaptive($adaptive_course_mid) {
      width: auto;
      height: auto;
      padding: 38px 50px 50px;
      margin: 0;
      border-radius: 20px 20px 0 0;
      background-color: #fff;
      color: #040E26;

      .favourite_link {
        border-bottom-color: #040E2650;
      }

      button {
        &.light {
          border-color: #040E2650;
          color: #040E26;
        }
      }
    }
    @include adaptive($adaptive_course_min) {
      padding: 32px 20px 32px;

      .favourite_link {
        margin-bottom: 35px;
      }

      .share_holder {
        top: 20px;
        right: 25px;
      }

      .btn_edit {
        display: none;
      }

    }
  }
}