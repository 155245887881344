@import "../../../../styles/mixins";

.ls_comparison_wrapper {
  position: relative;
  padding: 100px 0 20px;
  @include gradient(#F8F8FA, #fff);

  .button_holder {
    padding-top: 140px;
    text-align: center;
  }

  .ls_comparison_block {
    display: none;
  }

  .switcher {
    width: 900px;
    margin: 0 auto 160px;
    white-space: nowrap;
    border: 1px solid #444750;
    overflow: hidden;
    border-radius: 30px;

    .switch {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      font-size: 15px;
      line-height: 15px;
      padding: 23px 0 22px;
      text-align: center;
      color: #444750;
      cursor: pointer;

      &.active {
        background-color: #040E26;
        color: #fff;
        cursor: default;
      }
    }
  }

  .plates {
    @include gradient(#fff, #F8F8FA);
    border-radius: 20px;
    padding: 100px 0 140px;
    position: relative;

    .plate_bg {
      @include middle;
      z-index: 1;
      right: 0;
      width: 50%;
      height: calc(100% + 120px);
      border-radius: 20px;
      background-color: #2450FF;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 400px;
        @include gradient(rgba(36, 80, 255, 0), #4114BC);
      }
    }

    .plates_vs {
      @include center-middle;
      z-index: 2;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 20px 20px 30px rgba(58, 66, 95, 0.3);

      img {
        @include center-middle;
      }
    }

    .plates_inner {
      position: relative;
      z-index: 10;
      width: 1050px;
      margin: 0 auto;
      white-space: nowrap;
      font-size: 0;

      h2, dl {
        width: 405px;
        display: inline-block;
        vertical-align: top;
        white-space: normal;

        &:first-of-type {
          margin-right: 240px;
        }
      }

      .plates_row {
        margin-bottom: 46px;

        &:first-of-type {
          margin-bottom: 44px;
        }
      }

      h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;

        &.whub {
          color: #fff;
        }
      }

      dl {
        dt {
          display: block;
          font-size: 14px;
          line-height: 25px;
          color: #444750;
          padding-bottom: 11px;
          margin-bottom: 15px;
          border-bottom: 1px solid #E6E6E6;
        }

        dd {
          display: block;
          font-size: 18px;
          line-height: 25px;
        }

        &.whub {
          dt {
            color: #64D3FF;
          }
          dd {
            color: #fff;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold_max) {
    .plates {
      .plates_vs {
        width: 100px;
        height: 100px;
      }
      .plates_inner {
        width: 834px;

        h2, dl {
          width: 362px;

          &:first-of-type {
            margin-right: 110px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $treshold) {
    padding-top: 0;
    padding-bottom: 0;

    .l_centrer {
      display: none;
    }

    .ls_comparison_block {
      display: block;
      padding-top: 51px;
      padding-bottom: 35px;
      @include gradient(#F8F8FA, #fff);

      h2 {
        margin-bottom: 35px;
      }

      .plate {
        @include gradient(#fff, #F8F8FA);
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        padding: 10px 10px 47px;

        .plate_switcher {
          white-space: nowrap;
          border: 1px solid #2450FF;
          overflow: hidden;
          border-radius: 10px;
          font-size: 0;
          margin-bottom: 40px;

          .switch {
            display: inline-block;
            vertical-align: top;
            text-align: center;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            padding: 14px 0;
            width: 50%;
            color: #2450FF;

            &:first-of-type {
              background-color: #2450FF;
              color: #fff;
            }
          }
        }

        .plates_row {
          padding: 0 20px;
          margin-bottom: 27px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        dl {
          display: none;

          &:last-of-type {
            margin-bottom: 0;
          }

          dt {
            display: block;
            font-size: 12px;
            line-height: 18px;
            color: #444750;
            padding-bottom: 6px;
            margin-bottom: 13px;
            border-bottom: 1px solid #E6E6E6;
          }

          dd {
            display: block;
            font-size: 14px;
            line-height: 20px;
          }

          &.whub {
            dt {
              color: #64D3FF;
            }
            dd {
              color: #fff;
            }
          }
        }

        &.other {
          dl.other {
            display: block;
          }
        }

        &.whub {
          @include gradient(#2450FF, #2450FF);

          .plate_switcher {
            border-color: #fff;

            .switch {
              &:last-of-type {
                background-color: #fff;
              }
            }
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            width: 100%;
            height: 200px;
            @include gradient(rgba(36, 80, 255, 0), #4114BC);
          }

          dl.whub {
            display: block;
          }
        }
      }
    }
  }
}