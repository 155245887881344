@import "../../styles/mixins";

.blog_editor_wrapper {
  position: relative;
  max-width: 770px;
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 120px;
  text-align: center;

  .bc_top {
    margin-bottom: 64px;

    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .blog_editor {
    width: 770px;
    margin-left: -70px;

    .rdw-link-modal-btn {
      color: #434A5C !important;
      padding: 0;
    }

    .rdw-link-modal-target-option {
      input {
        padding: 0;
        width: 16px;
        height: 16px;
        margin: 0;
        display: inline-block;
        -webkit-appearance: checkbox;
      }
    }

    dl {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 16px;
      position: relative;
      z-index: 1;

      &.z_up {
        z-index: 2;
      }

      &.z_up_3 {
        z-index: 3;
      }

      &.focused {
        z-index: 10;
      }

      dt {
        flex-shrink: 0;
        width: 160px;
        padding-right: 16px;
        text-align: right;
        margin-top: 2px;

        &.eli_dt_video_label {
          margin-top: 6px;
        }

        &.duration_label {
          margin-top: -9px;
        }
      }

      dd {
        width: 100%;
        .course_form_hint {
          font-size: 10px;
          line-height: 16px;
          color: #C0C2C8;
        }

        textarea {
          display: block;
          margin-top: -4px;
          width: calc(100% - 18px);
          height: 120px;
          padding: 4px 8px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #C0C2C8;
          color: #434A5C;

          @include input-placeholder {
            color: #C0C2C8;
          }
        }

        input {
          display: block;
          margin-top: -4px;
          width: calc(100% - 18px);
          padding: 4px 8px;
          font-size: 16px;
          line-height: 24px;
          border: 1px solid #C0C2C8;
          color: #434A5C;

          @include input-placeholder {
            color: #C0C2C8;
          }
        }
      }
    }

    .buttons_holder {
      margin-top: 32px;

      button {
        margin: 0 4px;
      }

      a {
        margin: 0 4px;

        button {
          margin: 0;
        }
      }
    }
  }
}