.img_upload_block {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    z-index: -10;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}