@import "../../styles/mixins.css";

.stats_wrapper {
  margin: 0 24px;
  h2 {
    margin-bottom: 16px;
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
  }

  .school_stats {
    margin-bottom: 48px;

    dl {
      white-space: nowrap;

      &:nth-of-type(odd) {
        background-color: #F2F0F0;
      }

      dt, dd {
        display: inline-block;
        vertical-align: top;
        padding: 3px 15px;
      }

      dt {
        width: 200px;
      }

      dd {
        font-weight: bold;

        span {
          font-size: 13px;
          font-weight: normal;
          opacity: .5;
        }
      }
    }
  }

  .interaction_stats {
    margin-bottom: 64px;

    h5 {
      margin-bottom: 16px;

      .pseudo_link {
        display: inline-block;
        cursor: pointer;
        margin: 0 24px;
        opacity: .5;
        border-bottom: 1px dotted #040E2650;

        &:first-of-type {
          margin-left: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.active {
          cursor: default;
          opacity: 1;
          border: 0 solid;
        }
      }
    }

    .interaction_list {
      li {
        padding: 3px 15px;

        &:nth-of-type(odd) {
          background-color: #F2F0F0;
        }

        .sl_title {
          display: inline-block;
          vertical-align: top;
          width: 480px;
        }

        a {
          color: unset;
          text-decoration: none;
          border-bottom: 1px solid #040E2650;
        }
      }
    }
  }
}