@import "../../styles/mixins";

.header_plus_wrapper {
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 40px;
  margin-left: 30px;

  .header_plus_button {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #2450FF;

    .plus_icon {
      @include center-middle;
    }

    &.opened .ua_dropdown {
      display: block;
    }

    .ua_dropdown {
      position: absolute;
      right: 0;
      z-index: 2;
      top: 100%;
      margin-top: 10px;
      background-color: #fff;
      box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
      border-radius: 8px;
      min-width: 150px;
      display: none;

      .tool_tip {
        right: 13px;
        position: absolute;
        bottom: 100%;
      }

      a {
        display: block;
        text-decoration: none;
        white-space: nowrap;
        font-size: 16px;
        line-height: 20px;
        text-transform: none;
        padding: 18px 16px;
        border-bottom: 1px solid #E6E7EA;
        color: #040E26;

        &.ua_logout {
          text-align: center;
          color: #C43333;
        }

        &:last-of-type {
          border-bottom: 0 solid;
        }
      }
    }
  }
}