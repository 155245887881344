@import "../../../../../styles/mixins";

.cl_header {
  .header_basic_params {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 42px;
    display: flex;
    justify-content: flex-start;

    .bp_item {
      flex-shrink: 0;
      font-size: 15px;
      line-height: 15px;
      margin-right: 40px;

      .cl_icon, svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        max-width: 20px;
        max-height: 20px;

        &.difficulty_icon {
          circle {
            fill: none;
            stroke: #fff;
            opacity: .5;

            &.filled {
              fill: #fff;
              opacity: 1;
            }
          }
        }
      }

      &.bp_item_certificate {
        display: none;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
      }
    }

    @include adaptive($adaptive_course_min) {
      width: 100%;
      bottom: 37px;
      flex-wrap: wrap;
      justify-content: space-between;

      .bp_item {
        width: 33%;
        margin-right: 0;
        margin-bottom: 32px;

        .cl_icon, svg {
          display: block;
          margin-bottom: 8px;
        }

        &.bp_item_certificate {
          width: 100%;
          margin-bottom: 0;

          .cl_icon {
            display: inline-block;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}